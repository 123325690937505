export enum VineStateIdEnum {
  Normal = 1,
  Young = 2,
  Missing = 3,
  Dead = 4,
  Unknown = 5,
}

export enum PostStateIdEnum {
  Exists = 1,
  Missing = 2,
  Unknown = 3,
}

export enum PostMaterialEnum {
  Wood = 1,
  Metal = 2,
  Plastic = 3,
  Unknown = 100, //FE use only, enum not decided yet, but if post state is unknown, then post type is also unknown
}

export enum VineTypeEnum {
  VINE = 0,
  YOUNG_VINE = 1,
  ARTIFICIAL_VINE = 2,
}

export enum ReportTypeEnum {
  Land = 1,
  Pruning = 2,
  Disease = 3,
  Yield = 4,
}

export enum ReportLevelsEnum {
  Block = 1,
  Rows = 2,
  Vines = 3, // work for Map data
  Posts = 4,
  GroundTruth = 5,
}

export enum CaneCountEnum {
  Cane0 = 0,
  Cane1 = 1,
  Cane2 = 2,
  Cane3 = 3,
  Cane4 = 4,
}

export enum CordonCountEnum {
  Cordon0 = 0,
  Cordon1 = 1,
  Cordon2 = 2,
}

export type PruningFilterEnums = VineStateIdEnum | CaneCountEnum | CordonCountEnum

export enum TrunkDiseaseConfidence {
  Dead = 1,
  Strong = 2,
  Weak = 3,
}
