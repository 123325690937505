type SliderLegendChipProps = {
  clr: string
  val: number
}

const SliderLegendChip = ({ clr, val }: SliderLegendChipProps) => {
  return (
    <div
      style={{
        height: 22,
        backgroundColor: clr,
        fontSize: 14,
        fontWeight: 500,
        borderRadius: 12,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 8px',
      }}
    >
      {val || 0}
    </div>
  )
}

export default SliderLegendChip
