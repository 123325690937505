import useAuthStore from '@stores/auth'
import { useShallow } from 'zustand/react/shallow'

const BillingPageWrapper = ({ children }: { children: React.ReactNode }) => {
  const [user] = useAuthStore(useShallow((s) => [s.user]))

  if (!user?.enableBilling)
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 8, color: '#78716C', height: '80vh' }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
          <path
            fill="#A8A29E"
            d="M6 22q-.825 0-1.412-.587T4 20V10q0-.825.588-1.412T6 8h1V6q0-2.075 1.463-3.537T12 1t3.538 1.463T17 6v2h1q.825 0 1.413.588T20 10v10q0 .825-.587 1.413T18 22zm6-5q.825 0 1.413-.587T14 15t-.587-1.412T12 13t-1.412.588T10 15t.588 1.413T12 17M9 8h6V6q0-1.25-.875-2.125T12 3t-2.125.875T9 6z"
          />
        </svg>
        <span style={{ marginTop: 10 }}>Oops, It looks like you have no permission to access this page.</span>
      </div>
    )

  return children
}

export default BillingPageWrapper
