import Iconify from '@components/data-display/Iconify'
import { YieldReportsByBlock } from '@hooks/useGetYieldBlockDataApi'
import { useTranslate } from '@hooks/useLocales'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import Typography from '@mui/material/Typography'
import { GroundTruthData } from '@typings/dtos/block-report/models'
import { fmtNumToThousandSepSingleDecOrDash, formatISO_, formatToSingleDecimalIfNecessaryOrDash } from '@utils/utility-fns'
import { Dispatch, SetStateAction } from 'react'

type YieldTableDialogProps = {
  open: boolean
  handleClose: () => void
  block: YieldReportsByBlock | undefined
  scanIndex: number | undefined
  setSelectedScan: Dispatch<SetStateAction<{ block: YieldReportsByBlock; scanIndex: number } | undefined>>
}

const YieldTableDialog = ({ open, handleClose, block, scanIndex, setSelectedScan }: YieldTableDialogProps) => {
  const { t } = useTranslate()

  const groundTruthData: GroundTruthData | undefined =
    (scanIndex !== undefined && block?.reports[scanIndex]?.groundTruthData) || undefined

  return (
    <Dialog open={open} onClose={handleClose}>
      {block !== undefined && scanIndex !== undefined && (
        <Box width={600} display="grid" gap={3}>
          <Box p={2.2} display="flex" justifyContent="space-between" bgcolor="#525252">
            <div>
              <Typography fontSize={15} fontWeight={500} color="white">
                {block.blockName}
              </Typography>
              <div style={{ color: 'white', lineHeight: 1, display: 'flex', gap: 6 }}>
                <div>{block.reports[scanIndex]?.rowsScanned.length}</div>
                <div style={{ color: 'lightslategrey', textWrap: 'nowrap', textTransform: 'lowercase' }}>{t('rows_scanned')}</div>
              </div>
            </div>

            <Box display="flex" sx={{ userSelect: 'none' }}>
              <Box width={16}>
                <Iconify
                  icon="teenyicons:right-solid"
                  color="white"
                  width={16}
                  sx={{
                    rotate: '180deg',
                    mt: 0.4,
                    cursor: 'pointer',
                    ':active': { transform: 'scale(1.1)', color: '#BEF264' },
                    display: scanIndex === 0 ? 'none' : 'block',
                  }}
                  onClick={() =>
                    setSelectedScan((prev) =>
                      prev && prev.scanIndex > 0 ? { block: prev.block, scanIndex: prev.scanIndex - 1 } : prev
                    )
                  }
                />
              </Box>
              <Typography fontSize={15} fontWeight={500} textAlign="center" color="white" mb={1} width={140}>
                {formatISO_(block.reports[scanIndex]?.startDate || '')} &#8212;{' '}
                {formatISO_(block.reports[scanIndex]?.endDate || '')}
              </Typography>
              <Box width={16}>
                <Iconify
                  icon="teenyicons:right-solid"
                  color="white"
                  width={16}
                  sx={{
                    mt: 0.4,
                    cursor: 'pointer',
                    ':active': { transform: 'scale(1.1)', color: '#BEF264' },
                    display: scanIndex === block.reports.length - 1 ? 'none' : 'block',
                  }}
                  onClick={() =>
                    setSelectedScan((prev) =>
                      prev && prev.scanIndex < block.reports.length - 1
                        ? { block: prev.block, scanIndex: prev.scanIndex + 1 }
                        : prev
                    )
                  }
                />
              </Box>
            </Box>
          </Box>
          <div style={{ display: 'flex', padding: '0 1rem' }}>
            <div style={{ flexBasis: '100%' }}>
              <div style={{ color: '#78716C', textWrap: 'nowrap' }}>Total Bunches - Visible</div>
              <div style={{ fontFamily: 'monospace', color: '#525252', fontSize: 15 }}>
                {fmtNumToThousandSepSingleDecOrDash(block.reports[scanIndex]?.visBunchCountTotal)}
              </div>
            </div>
            <div style={{ flexBasis: '100%' }}>
              <div style={{ color: '#78716C', textWrap: 'nowrap' }}>Bunches/Vine (Mean) - Visible</div>
              <div style={{ fontFamily: 'monospace', color: '#525252', fontSize: 15 }}>
                {formatToSingleDecimalIfNecessaryOrDash(block.reports[scanIndex]?.visBunchesPerVineMean)}
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', padding: '0 1rem' }}>
            <div style={{ flexBasis: '100%' }}>
              <div style={{ color: '#78716C', textWrap: 'nowrap' }}>Total Bunches - Corrected</div>
              <div style={{ fontFamily: 'monospace', color: '#525252', fontSize: 15 }}>
                {fmtNumToThousandSepSingleDecOrDash(block.reports[scanIndex]?.correctedBunchCountTotal)}
              </div>
            </div>
            <div style={{ flexBasis: '100%' }}>
              <div style={{ color: '#78716C', textWrap: 'nowrap' }}>Bunches/Vine (Mean) - Corrected</div>
              <div style={{ fontFamily: 'monospace', color: '#525252', fontSize: 15 }}>
                {formatToSingleDecimalIfNecessaryOrDash(block.reports[scanIndex]?.correctedBunchesPerVineMean)}
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', padding: '0 1rem' }}>
            <div style={{ flexBasis: '100%' }}>
              <div style={{ color: '#78716C', textWrap: 'nowrap' }}>Total Bunches - Extrapolated</div>
              <div style={{ fontFamily: 'monospace', color: '#525252', fontSize: 15 }}>
                {fmtNumToThousandSepSingleDecOrDash(block.reports[scanIndex]?.extrapolatedBunchCountTotal)}
              </div>
            </div>
            <div style={{ flexBasis: '100%' }}>
              <div style={{ color: '#78716C', textWrap: 'nowrap' }}>Bunches/Vine (Mean) - Extrapolated</div>
              <div style={{ fontFamily: 'monospace', color: '#525252', fontSize: 15 }}>
                {formatToSingleDecimalIfNecessaryOrDash(block.reports[scanIndex]?.extrapolatedBunchesPerVineMean)}
              </div>
            </div>
          </div>
          <div>
            <div style={{ display: 'flex', gap: 8, fontSize: 13, fontWeight: 500, textWrap: 'nowrap', alignItems: 'center' }}>
              <div style={{ height: 1, width: '100%', backgroundColor: 'lightgray' }} />
              <span style={{ color: '#737373' }}>GROUNDTRUTHING DETAILS</span>
              <div style={{ height: 1, width: '100%', backgroundColor: 'lightgray' }} />
            </div>
            <table style={{ padding: '0 1rem 1rem 1rem', color: '#737373' }}>
              <tbody style={{ fontSize: 13 }}>
                <tr>
                  <td style={{ color: '#A3A3A3', verticalAlign: 'top' }}>Blocks:</td>
                  <td
                    style={{
                      overflow: 'hidden',
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 2,
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {groundTruthData?.blockNames && groundTruthData?.blockNames.map((name) => name).join(', ')}
                  </td>
                </tr>
                <tr>
                  <td style={{ color: '#A3A3A3' }}>Date start:</td>
                  <td>{formatISO_(groundTruthData?.dateStart, 'dd MMM yyyy')}</td>
                </tr>
                <tr>
                  <td style={{ color: '#A3A3A3' }}>Date end:</td>
                  <td>{formatISO_(groundTruthData?.dateStart, 'dd MMM yyyy')}</td>
                </tr>
                <tr>
                  <td style={{ color: '#A3A3A3' }}>Error in Bunches:</td>
                  <td>
                    {groundTruthData?.errorInBunches && formatToSingleDecimalIfNecessaryOrDash(groundTruthData?.errorInBunches)}
                  </td>
                </tr>
                <tr>
                  <td style={{ color: '#A3A3A3', textWrap: 'nowrap' }}>Average Occlusion (%):</td>
                  <td>
                    {groundTruthData?.averageOcclusionPct &&
                      formatToSingleDecimalIfNecessaryOrDash(groundTruthData?.averageOcclusionPct)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* <Divider />
          <Box display="grid" gap={3} px={3} pb={4}>
            <Typography fontSize={15} fontWeight={600} color="#65A30D" textAlign="center">
              YIELD ESTIMATIONS
            </Typography>
            <Box display="flex" gap={4}>
              <TextField
                id="standard-basic"
                label="BUNCH WEIGHT"
                variant="outlined"
                inputProps={{ style: { paddingTop: 0, paddingBottom: 0, height: 46 } }}
              />
              <TextField
                id="standard-basic"
                label="BERRY COUNT"
                variant="outlined"
                inputProps={{ style: { paddingTop: 0, paddingBottom: 0, height: 46 } }}
              />
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Box>
                <Typography sx={{ color: '#78716C' }}>Tonnage</Typography>
                <Typography fontSize={20} fontWeight={500} color="#65A30D">
                  {((block.clusters[scanIndex]?.visBunchesPerVineMean || 0) / 2.6).toFixed(1)}
                </Typography>
              </Box>
              <Box>
                <Typography sx={{ color: '#78716C' }}>T / hA</Typography>
                <Typography fontSize={20} fontWeight={500} color="#65A30D">
                  {((block.clusters[scanIndex]?.visBunchesPerVineMean || 0) * 11.7).toFixed(1)}
                </Typography>
              </Box>
              <Box>
                <Typography sx={{ color: '#78716C' }}>Kg / Vine</Typography>
                <Typography fontSize={20} fontWeight={500} color="#65A30D">
                  {((block.clusters[scanIndex]?.correctedBunchesPerVineMean || 0) * 11.7).toFixed(1)}
                </Typography>
              </Box>
            </Box>
          </Box> */}
        </Box>
      )}
    </Dialog>
  )
}

export default YieldTableDialog
