import BlockNameCustomChip from '@components/data-display/BlockNameCustomChip'
import HighlightInfo from '@components/data-display/HighlightInfo'
import { useTranslate } from '@hooks/useLocales'
import useVineyardStore from '@stores/vineyard'
import { useShallow } from 'zustand/react/shallow'

type ConditionalStatsTileContentProps = {
  data: { blockName: string }[]
}

const ConditionalStatsTileContent = ({ data }: ConditionalStatsTileContentProps) => {
  const [selectedBlocks] = useVineyardStore(useShallow((s) => [s.selectedBlocks]))
  const { t } = useTranslate()

  const conditions = {
    SINGLE_SELECTED_BLOCK: selectedBlocks.length === 1,
    SAME_COUNT: selectedBlocks.length === data.length,
  }

  return conditions.SINGLE_SELECTED_BLOCK ? (
    <BlockNameCustomChip value={data[0]?.blockName || ''} />
  ) : conditions.SAME_COUNT ? (
    <HighlightInfo value={data.length} label={t('selected_blocks')} valueFontSize={26} color="info.dark" />
  ) : (
    <HighlightInfo
      value={data.length}
      label={t('out_of') + ' ' + selectedBlocks.length + ' ' + t('selected_blocks')}
      valueFontSize={26}
      color="info.dark"
    />
  )
}

export default ConditionalStatsTileContent
