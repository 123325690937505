import { StyleSheet } from '@react-pdf/renderer'

export const s = StyleSheet.create({
  page: {
    padding: 12,
    fontSize: 10,
    color: '#475569',
  },
  logo: {
    height: 30,
    width: 140,
    objectFit: 'contain',
    marginTop: 10,
  },
  centerH: {
    alignItems: 'center',
    gap: 4,
    paddingBottom: 4,
  },
  divider: {
    borderBottomWidth: 1,
    borderBottomColor: '#D4D4D4',
    marginVertical: 10,
  },
  row: {
    flex: 1,
    paddingLeft: 4,
    justifyContent: 'center',
  },
})
