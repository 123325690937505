import { Text, View } from '@react-pdf/renderer'
import { BlockData } from '@typings/dtos/block-report/models'
import { colorByAssumedCaneTarget, fmtNumToThousandSepSingleDecOrZero } from '@utils/utility-fns'
import PdfPieChart from './PdfPieChart'

type CaneTargetSectionProps = {
  blockData: BlockData | undefined
}

const CaneTargetSection = ({ blockData }: CaneTargetSectionProps) => {
  if (!blockData) return null

  const caneTarget = blockData.caneTarget || 1

  const createSlices = () => {
    let slices = []

    for (let i = caneTarget; i >= 1; i--) {
      const pct = `cane${i}Pct` as keyof BlockData
      const count = `cane${i}` as keyof BlockData
      slices.push({
        label: `${i} Cane`,
        value: blockData[count] as number,
        percentage: blockData[pct] as number,
        color: colorByAssumedCaneTarget(caneTarget, `cane${i}` as keyof BlockData),
      })
    }

    return slices
  }

  return (
    <View style={{ backgroundColor: '#F5F5F5', height: '100%', width: 150, padding: 8, borderRadius: 2 }}>
      <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 4, justifyContent: 'space-between' }}>
        <Text>CANE TARGET</Text>
        <View
          style={{
            width: 16,
            height: 16,
            backgroundColor: 'white',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 12,
          }}
        >
          <Text>{caneTarget}</Text>
        </View>
      </View>
      <View style={{ borderBottomWidth: 1, borderBottomColor: '#FFF', marginBottom: 16 }} />

      <View style={{ marginHorizontal: 'auto', gap: 14 }}>
        <PdfPieChart slices={createSlices()} />

        {createSlices().map((slice, index) => (
          <View key={index} style={{ border: `1px solid ${slice.color}`, borderRadius: 2 }}>
            <View style={{ backgroundColor: slice.color, padding: '2px 6px 3px 6px', color: '#000' }}>
              <Text>{slice.label}</Text>
            </View>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                gap: 4,
                padding: '6px 2px 6px 6px',
                justifyContent: 'space-between',
                fontWeight: 900,
                letterSpacing: 0.4,
                borderTopLeftRadius: 2,
                borderTopRightRadius: 2,
              }}
            >
              <Text style={{ fontSize: 11, fontWeight: 900 }}>{fmtNumToThousandSepSingleDecOrZero(slice.value)}</Text>
              <Text style={{ fontSize: 10, fontWeight: 900 }}>{fmtNumToThousandSepSingleDecOrZero(slice.percentage)}%</Text>
            </View>
          </View>
        ))}
      </View>
    </View>
  )
}

export default CaneTargetSection
