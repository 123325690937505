import { BlockReportApi } from '@services/api'
import useVineyardStore from '@stores/vineyard'
import { useQuery } from '@tanstack/react-query'
import { ReportLevelsEnum, ReportTypeEnum } from '@typings/dtos/block-report/enums'
import { BlockReport } from '@typings/dtos/block-report/models'
import { pctOfRowsScanned } from '@utils/utility-fns'
import { useEffect, useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { useHandleAxiosError } from '../useHandleAxiosError'

export type DiseaseReportsByBlock = {
  blockId: number
  blockName: string
  vineyardName: string
  rowStart: number
  rowEnd: number
  rowIndex: [number, number][]
  reports: BlockReport[]
}

export function useGetDiseaseBlockReports() {
  const [diseaseReports, setDiseaseReports] = useState<DiseaseReportsByBlock[]>([])

  const [selectedBlocks, startDate, endDate, blocksAsMap, vineyardsAsMap] = useVineyardStore(
    useShallow((s) => [s.selectedBlocks, s.startDate, s.endDate, s.blocksAsMap, s.vineyardsAsMap])
  )

  const handleAxiosError = useHandleAxiosError()

  const {
    data: res,
    refetch,
    isFetching,
    error,
  } = useQuery({
    queryKey: ['DiseaseBlockDataReport'],
    initialData: [],
    queryFn: () =>
      BlockReportApi.get({
        reportType: ReportTypeEnum.Disease,
        reportLevels: [ReportLevelsEnum.Block, ReportLevelsEnum.Rows],
        blockIds: selectedBlocks.map((block) => block.id),
        startDate,
        endDate,
      }),
    enabled: false,
  })

  useEffect(() => {
    if (error) handleAxiosError(error)
  }, [error, handleAxiosError])

  useEffect(() => {
    if (!selectedBlocks.length) return
    refetch()
  }, [selectedBlocks, startDate, endDate, refetch])

  useEffect(() => {
    const groupBlockReportsByBlockClusters = () => {
      const dataMap = new Map<number, DiseaseReportsByBlock>()

      res.forEach((r) => {
        const block = blocksAsMap.get(r.blockId)
        if (!block) return

        const data = dataMap.get(r.blockId) || {
          blockId: r.blockId,
          blockName: block.name,
          vineyardName: vineyardsAsMap.get(r.vineyardId)?.name || 'Unknown Vineyard',
          rowStart: block.rowStart,
          rowEnd: block.rowEnd,
          rowIndex: block.rowIndex,
          reports: [],
        }

        data.reports.push({
          ...r,
          blockName: block.name,
          rowsScannedPct: pctOfRowsScanned(r.blockData.rowsScanned, block.rowIndex),
        })
        dataMap.set(r.blockId, data)
      })

      dataMap.forEach((item) => {
        item.reports.sort((a, b) => a.dateEnd.localeCompare(b.dateEnd))
      })

      return Array.from(dataMap.values())
    }

    setDiseaseReports(groupBlockReportsByBlockClusters())
  }, [res, blocksAsMap])

  return { diseaseReports, isFetching }
}
