import HighlightColumnWrapper from '@components/containers/HighlightColumnWrapper'
import HighlightsInfoWrapper from '@components/containers/HighlightsInfoWrapper'
import SummaryTileWrapper from '@components/containers/SummaryTileWrapper'
import HighlightInfo from '@components/data-display/HighlightInfo'
import SortBlocksMenu from '@components/echarts/SortBlocksMenu'
import StackedHorizontalBar from '@components/echarts/charts/StackedHorizontalBar'
import { useSetStateLandVineDataset } from '@hooks/land/useSetStateLandVineDataset'
import { LandDataset, useSortLandDataset } from '@hooks/land/useSortLandDataset'
import { useBlockDataHighlights } from '@hooks/useBlockDataHighlights'
import { useTranslate } from '@hooks/useLocales'
import Box from '@mui/material/Box'
import { LandBow_TooltipProperties, labelFormatter_Land, tooltipFormatter } from '@utils/echarts'
import { LandBow_VineStatsSortOptions } from '@utils/options'
import { numberFmtThousandSeparator, uniqueBlockNames } from '@utils/utility-fns'
import { useState } from 'react'
import ConditionalStatsTileContent from '../ConditionalStatsTileContent'
import { LandSummarySectionProps } from './LandCaneSection'

const LandBowSection = ({ data }: LandSummarySectionProps) => {
  const [activeSort, setActiveSort] = useState('')
  const [vineStatsDataset, setVineStatsDataset] = useState<LandDataset>({
    id: 'land-bow-vine-stats-dataset',
    dimensions: ['blockName', 'missingVines', 'youngVines'],
    source: [],
  })

  const { t } = useTranslate()

  useSortLandDataset(setVineStatsDataset, activeSort)

  useSetStateLandVineDataset(setVineStatsDataset, data)

  const { totalYoungVines, totalMissingVines, totalVines } = useBlockDataHighlights({
    data,
  })

  return (
    <>
      <Box display="flex" gap={2} flexWrap="wrap">
        <HighlightsInfoWrapper
          label="showing_stats_for"
          mainHighlightComponent={<ConditionalStatsTileContent data={uniqueBlockNames(data)} />}
          tooltipInfo="pruning_page.summary.tooltip_showing_stats_for"
        />
        <HighlightsInfoWrapper
          label="vines_scanned"
          mainHighlightComponent={
            <HighlightInfo value={numberFmtThousandSeparator(totalVines)} label="total" color="info.dark" />
          }
        >
          <HighlightColumnWrapper>
            <HighlightInfo value={numberFmtThousandSeparator(totalYoungVines)} label="young_vines" />
            <HighlightInfo value={numberFmtThousandSeparator(totalMissingVines)} label="missing_vines" color="error.main" />
          </HighlightColumnWrapper>
        </HighlightsInfoWrapper>
      </Box>
      <SummaryTileWrapper
        label="vine_stats"
        width="100%"
        height="100%"
        tooltipMsg="land_page.summary.bow.tooltip_vine_stats"
        headerComponent={
          vineStatsDataset.source.length > 1 && (
            <SortBlocksMenu
              id="sort-vine-stats"
              menuItems={LandBow_VineStatsSortOptions}
              value={activeSort}
              onClick={(val) => setActiveSort(val)}
              menuArrowPositionRight={110}
            />
          )
        }
      >
        <Box py={4}>
          <StackedHorizontalBar
            dataset={vineStatsDataset}
            legend={{
              show: true,
              formatter: (name) => {
                // lowercase and remove 'vines' from name in the end
                const key = name.toLowerCase().slice(0, -5)
                return t(key)
              },
            }}
            color={['#F87171', '#60A5FA']}
            tooltipFormatter={(params) => tooltipFormatter(params, t, LandBow_TooltipProperties)}
            hideLabelValueBelow={0.4}
            yAxisCategoryLabelFormatter={labelFormatter_Land}
            formatLabelToPct={false}
          />
        </Box>
      </SummaryTileWrapper>
    </>
  )
}

export default LandBowSection
