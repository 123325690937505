import DatagridNoRowsOverlay from '@components/data-display/DatagridNoRowsOverlay'
import CustomGridToolbar from '@components/datagrid/CustomGridToolbar'
import HealthDatagridColumnsToggle from '@components/sections/health-reporting/HealthDatagridColumnsToggle'
import useDataGridLocaleText from '@hooks/useDataGridLocaleText'
import { useGetRowBlockReportsApi } from '@hooks/useGetRowBlockReportsApi'
import Paper from '@mui/material/Paper'

import { DataGridPro } from '@mui/x-data-grid-pro'
import { DiseaseFilterOption } from '@typings/component.enums'
import { ReportTypeEnum } from '@typings/dtos/block-report/enums'
import { pinnedColumns } from '@utils/constants'
import { healthCols, healthColsGrouping } from '@utils/datagrid-cols/health-cols'
import { format } from 'date-fns'
import { useState } from 'react'

const HealthReportingPage = () => {
  const [activeOption, setActiveOption] = useState<DiseaseFilterOption>(DiseaseFilterOption.PowderMildew)

  const { data, isFetching } = useGetRowBlockReportsApi(ReportTypeEnum.Disease)
  const { localeText } = useDataGridLocaleText()

  return (
    <>
      <HealthDatagridColumnsToggle selection={activeOption} onClick={(option) => setActiveOption(option)} />
      <Paper sx={{ borderRadius: 1, height: 'calc(100vh - 120px)', overflow: 'hidden' }} variant="outlined">
        <DataGridPro
          rows={data}
          columns={healthCols(activeOption)}
          columnGroupingModel={healthColsGrouping()}
          loading={isFetching}
          initialState={pinnedColumns}
          getRowId={(r) => r.id + r.blockId.toString() + r.rowId.toString()}
          disableColumnMenu
          hideFooter
          slots={{
            noRowsOverlay: () => <DatagridNoRowsOverlay data={data} />,
            toolbar: () => (
              <CustomGridToolbar fileName={`Health Report - ${format(new Date(), 'dd MMM yyyy')}`} rowCount={data?.length} />
            ),
          }}
          // slotProps={{ toolbar: { fileName: `Health Report - ${format(new Date(), 'dd MMM yyyy')}`, rowCount: data?.length } }}
          localeText={localeText}
        />
      </Paper>
    </>
  )
}

export default HealthReportingPage
