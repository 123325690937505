import { Text, View } from '@react-pdf/renderer'
import { BlockData } from '@typings/dtos/block-report/models'
import { fmtNumToThousandSepSingleDecOrZero } from '@utils/utility-fns'

type HighlightsSectionProps = {
  blockData: BlockData | undefined
}

const HighlightsSection = ({ blockData }: HighlightsSectionProps) => {
  if (!blockData) return null

  const Tile = ({ title, value }: { title: string; value: number }) => (
    <View style={{ border: '1px solid #F5F5F5', width: 110, textAlign: 'center', borderRadius: 2, overflow: 'hidden' }}>
      <View style={{ backgroundColor: '#F5F5F5', paddingVertical: '2px 4px', borderTopLeftRadius: 2, borderTopRightRadius: 2 }}>
        <Text>{title}</Text>
      </View>
      <View style={{ fontSize: 13, paddingVertical: '4px 6px' }}>
        <Text style={{ letterSpacing: 0.4 }}>{fmtNumToThousandSepSingleDecOrZero(value)}</Text>
      </View>
    </View>
  )

  return (
    <View>
      <Text style={{ marginVertical: 6 }}>VINES SCANNED</Text>
      <View style={{ flexDirection: 'row', gap: 4 }}>
        <Tile title="Normal" value={blockData.vNormal} />
        <Tile title="Young" value={blockData.vYoung} />
        <Tile title="Missing" value={blockData.vMissing} />
        <Tile title="Dead" value={blockData.vDead} />
        <Tile title="Buds/Vine (Mean)" value={blockData.budsPerVineMean} />
        <Tile title="Buds/Cane (Mean)" value={blockData.budsPerCaneMean} />
      </View>
    </View>
  )
}

export default HighlightsSection
