import { Document, Link, Page, Text, View } from '@react-pdf/renderer'
import { BlockReport } from '@typings/dtos/block-report/models'
import { formatISO_ } from '@utils/utility-fns'
import BestWorstPrunedRows from './BestWorstPrunedRows'
import CaneTargetSection from './CaneTargetSection'
import HighlightsSection from './HighlightsSection'
import ScannedRows from './ScannedRows'
import { s } from './styles'
import TitlePage from './TitlePage'

type PruningReportProps = {
  reports: BlockReport[]
}

const PruningReport = ({ reports }: PruningReportProps) => {
  if (!reports.length) return null
  //TODO: Find and replace with actual date range
  const dateRange = '5 Jan 2024 \u00A0\u2014\u00A0 12 Sep 2024'

  const groupedData = reports.reduce(
    (acc, report) => {
      const { blockName } = report
      // Check if blockName exists in the accumulator
      if (!acc[blockName]) {
        acc[blockName] = []
      }
      // Push the current report to the array of its corresponding blockName
      acc[blockName].push(report)

      // Sort the reports by dateStart after adding each report
      acc[blockName].sort((a, b) => new Date(a.dateStart).getTime() - new Date(b.dateStart).getTime())

      return acc
    },
    {} as { [key: string]: BlockReport[] }
  )

  return (
    <Document>
      <TitlePage data={groupedData} dateRange={dateRange} />
      {Object.entries(groupedData).map(([blockName, reports]) =>
        reports.map((report) => (
          <Page key={report.id} id={report.id} size="A4" style={s.page}>
            <Text style={{ fontSize: 12, marginBottom: 8, fontFamily: 'Helvetica-Bold' }}>{blockName}</Text>
            <View style={{ flexDirection: 'row', gap: 8, flexWrap: 'wrap' }}>
              {groupedData[blockName]?.map((r) => (
                <Link
                  key={r.id}
                  src={`#${r.id}`}
                  style={{
                    border: '1px solid #3F3F46',
                    textDecoration: 'none',
                    color: r.id === report.id ? '#FFF' : '#0284C7',
                    backgroundColor: r.id === report.id ? '#3F3F46' : '#FFF',
                    borderRadius: 2,
                  }}
                >
                  <Text style={{ padding: '2px 6px 3px 6px' }}>
                    {formatISO_(r.dateStart, 'd MMM yyyy')}
                    {'\u00A0\u2014\u00A0'}
                    {formatISO_(r.dateEnd, 'd MMM yyyy')}
                  </Text>
                </Link>
              ))}
            </View>
            <View style={s.divider} />
            <HighlightsSection blockData={report.blockData} />
            <View style={{ flexDirection: 'row', gap: 8, marginTop: 36 }}>
              <CaneTargetSection blockData={report.blockData} />
              <BestWorstPrunedRows report={report} />
            </View>
            <ScannedRows blockData={report.blockData} />
          </Page>
        ))
      )}
    </Document>
  )
}

export default PruningReport
