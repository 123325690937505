import { BlockReportsByBlock } from '@typings/component'
import { BlockReport } from '@typings/dtos/block-report/models'
import { useMemo } from 'react'

const useReportsClosestToTargetDate = (reports: BlockReportsByBlock[] | undefined, targetDate: number) => {
  return useMemo(() => {
    if (!reports) return

    const result = reports
      .map((block) => {
        if (block.reports.length === 0) return null

        if (block.reports.length === 1) return { ...block, reports: [block.reports[0]] }

        let closestCluster = block.reports[0]
        let closestTime = closestCluster ? new Date(closestCluster.dateEnd).getTime() : Infinity

        for (let i = 1; i < block.reports.length; i++) {
          const curr = block.reports[i]
          if (!curr) continue
          const currTime = new Date(curr.dateEnd).getTime()
          if (Math.abs(currTime - targetDate) < Math.abs(closestTime - targetDate)) {
            closestCluster = curr
            closestTime = currTime
          }
        }

        return { ...block, reports: [closestCluster] }
      })
      .filter(Boolean) // Remove any null values resulting from blocks with no Reports
      .map((block) => {
        return { blockName: block?.blockName, ...block!.reports[0] } as BlockReport
      })

    return result
  }, [reports, targetDate])
}

export default useReportsClosestToTargetDate
