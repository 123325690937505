import HighlightColumnWrapper from '@components/containers/HighlightColumnWrapper'
import HighlightsInfoWrapper from '@components/containers/HighlightsInfoWrapper'
import HighlightInfo from '@components/data-display/HighlightInfo'
import { useBlockDataHighlights } from '@hooks/useBlockDataHighlights'
import Box from '@mui/material/Box'
import { BlockReport } from '@typings/dtos/block-report/models'
import { numberFmtThousandSeparator } from '@utils/utility-fns'
import ConditionalStatsTileContent from '../ConditionalStatsTileContent'
import SpurPruningVineStatsBoxplotChart from './charts/SpurPruningVineStatsBoxplotChart'

type SpurPrunedSectionProps = {
  data: BlockReport[]
}

const SpurPrunedSection = ({ data }: SpurPrunedSectionProps) => {
  const { totalYoungVines, totalMissingVines, totalVines, totalMissingCordon1, totalMissingCordon2 } = useBlockDataHighlights({
    data,
  })

  return (
    <>
      <Box display="flex" gap={2} flexWrap="wrap">
        <HighlightsInfoWrapper
          label="showing_stats_for"
          mainHighlightComponent={<ConditionalStatsTileContent data={data} />}
          tooltipInfo={'pruning_page.summary.tooltip_showing_stats_for'}
        />
        <HighlightsInfoWrapper
          label="vines_scanned"
          mainHighlightComponent={
            <HighlightInfo value={numberFmtThousandSeparator(totalVines)} label="total" color="info.dark" />
          }
        >
          <HighlightColumnWrapper>
            <HighlightInfo value={numberFmtThousandSeparator(totalYoungVines)} label="young_vines" />
            <HighlightInfo value={numberFmtThousandSeparator(totalMissingVines)} label="missing_vines" color="error.main" />
          </HighlightColumnWrapper>
          <HighlightColumnWrapper divider>
            <HighlightInfo value={totalMissingCordon1} label="1_missing_cordon" color="error.main" />
            <HighlightInfo value={totalMissingCordon2} label="2_missing_cordons" color="error.main" />
          </HighlightColumnWrapper>
        </HighlightsInfoWrapper>
      </Box>
      <SpurPruningVineStatsBoxplotChart data={data} />
    </>
  )
}

export default SpurPrunedSection
