import { createLandVineStatsDataset } from '@utils/utility-fns'
import { Dispatch, SetStateAction, useCallback, useEffect } from 'react'

export type LandDataset = {
  id: string
  dimensions: string[]
  source: ReturnType<typeof createLandVineStatsDataset>
}

export function useSortLandDataset(setState: Dispatch<SetStateAction<LandDataset>>, activeSort: string) {
  const sortDataset = useCallback(
    (source: LandDataset['source']) => {
      if (activeSort === '') return source

      const [key, direction] = activeSort.split('-')
      const isAscending = direction === 'asc'

      return [...source].sort((a, b) => {
        if (key === 'blockName') {
          return isAscending ? b.blockName.localeCompare(a.blockName) : a.blockName.localeCompare(b.blockName)
        }

        const sortKey = key as keyof ReturnType<typeof createLandVineStatsDataset>[0]
        const valueA = (a[sortKey] as number) || 0
        const valueB = (b[sortKey] as number) || 0

        return isAscending ? valueB - valueA : valueA - valueB
      })
    },
    [activeSort]
  )

  useEffect(() => {
    setState((prev) => ({ ...prev, source: sortDataset(prev.source) }))
  }, [setState, activeSort, sortDataset])
}
