import { useTranslate } from '@hooks/useLocales'
import Box, { BoxProps } from '@mui/material/Box'
import useAuthStore from '@stores/auth'
import useVineyardStore from '@stores/vineyard'
import { PruningStyleUnion } from '@typings/dtos/block'
import { SUMMARY_CONTAINER_WIDTH } from '@utils/constants'
import { ReactNode, useEffect } from 'react'
import { useShallow } from 'zustand/react/shallow'

type PruningStyleOptionsWrapperProps = {
  blocksCountByPruningStyle: Record<PruningStyleUnion, number>
  sx?: BoxProps['sx']
  endComponent?: ReactNode
}

const PruningStyleOptionsWrapper = ({ blocksCountByPruningStyle, sx, endComponent }: PruningStyleOptionsWrapperProps) => {
  const { t } = useTranslate()

  const [pruningStyleOptions, activePruningStyle, setActivePruningStyle] = useVineyardStore(
    useShallow((s) => [s.pruningStyleOptions, s.activePruningStyle, s.setActivePruningStyle])
  )

  const [isSidebarOpen] = useAuthStore((s) => [s.isSidebarOpen])

  useEffect(() => {
    if (!activePruningStyle && pruningStyleOptions.length)
      setActivePruningStyle(pruningStyleOptions[0]?.value as PruningStyleUnion)
  }, [setActivePruningStyle])

  return (
    <Box mt={6} sx={sx}>
      <Box
        alignItems="center"
        width="100%"
        py={2}
        pr={2}
        bgcolor="#FFF"
        position="fixed"
        top={48}
        left={0}
        zIndex={1}
        borderBottom="1px solid #E7E5E4"
        boxShadow="0px 2px 4px rgba(0, 0, 0, 0.05)"
      >
        <Box
          maxWidth={SUMMARY_CONTAINER_WIDTH + 30}
          display="flex"
          justifyContent="space-between"
          ml={isSidebarOpen ? 40 : 6}
          pl={4}
          fontWeight={500}
          height={32}
        >
          <div style={{ display: 'flex', gap: '1rem' }}>
            {pruningStyleOptions.map(({ value, label }) => (
              <Box
                key={value}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  pl: 1.2,
                  minWidth: 154,
                  fontSize: 14,
                  borderRadius: 1,
                  userSelect: 'none',
                  overflow: 'hidden',
                  bgcolor: value === activePruningStyle ? 'primary.dark' : '#E7E5E4',
                  color: value === activePruningStyle ? '#FFFFFF' : '#52525B',
                  cursor: value === activePruningStyle ? 'default' : 'pointer',
                  ':hover': { bgcolor: value === activePruningStyle ? '' : '#D6D3D1' },
                }}
                onClick={() => setActivePruningStyle(value)}
              >
                {t(label)}
                <Box
                  lineHeight={2.5}
                  px={1.4}
                  color="#f4f4f5"
                  bgcolor={blocksCountByPruningStyle[value] > 0 ? 'primary.dark' : '#a3a3a3'}
                >
                  {blocksCountByPruningStyle[value]}
                </Box>
              </Box>
            ))}
          </div>
          {endComponent}
        </Box>
      </Box>
    </Box>
  )
}

export default PruningStyleOptionsWrapper
