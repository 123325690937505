import { SUMMARY_FILTERS_WRAPPER } from '@services/theme/styles'
import { ReactNode } from 'react'

type SummaryFiltersWrapperProps = {
  label: string
  legends: ReactNode
  rightSection: ReactNode
}

const SummaryFiltersWrapper = ({ label, legends, rightSection }: SummaryFiltersWrapperProps) => {
  return (
    <div style={SUMMARY_FILTERS_WRAPPER}>
      <div style={{ display: 'grid', gap: 1, color: '#475569' }}>
        <label
          style={{
            fontSize: 13,
            letterSpacing: 0.4,
            fontWeight: 500,
            marginTop: -10,
            marginLeft: 2,
            color: '#71717A',
            textTransform: 'uppercase',
          }}
        >
          {label}
        </label>
        <div style={{ display: 'flex', border: '1px solid #E5E5E5', borderRadius: 4, overflow: 'hidden', color: '#525252' }}>
          {legends}
        </div>
      </div>
      <div style={{ display: 'flex', gap: 14 }}>{rightSection}</div>
    </div>
  )
}

export default SummaryFiltersWrapper
