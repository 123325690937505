import { BlockReportApi } from '@services/api'
import useVineyardStore from '@stores/vineyard'
import { useQuery } from '@tanstack/react-query'
import { ReportLevelsEnum, ReportTypeEnum } from '@typings/dtos/block-report/enums'
import { BlockReport, GroundTruthData } from '@typings/dtos/block-report/models'
import { pctOfRowsScanned } from '@utils/utility-fns'
import { useEffect, useMemo, useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { useHandleAxiosError } from './useHandleAxiosError'

export type YieldReport = {
  blockReportId: string
  startDate: string
  endDate: string
  visibleChange: 'up' | 'down' | 'none'
  correctedChange: 'up' | 'down' | 'none'
  groundTruthData: GroundTruthData
  rowsScannedPct: number
} & BlockReport['blockData']

export type YieldReportsByBlock = {
  blockId: number
  blockName: string
  vineyardName: string
  rowStart: number
  rowEnd: number
  rowIndex: [number, number][]
  varietyId: number
  reports: YieldReport[]
}

export function useGetYieldBlockDataApi() {
  const [data, setData] = useState<YieldReportsByBlock[]>([])

  const [selectedBlocks, startDate, endDate, blocksAsMap, varieties, vineyardsAsMap] = useVineyardStore(
    useShallow((s) => [s.selectedBlocks, s.startDate, s.endDate, s.blocksAsMap, s.varieties, s.vineyardsAsMap])
  )

  const handleAxiosError = useHandleAxiosError()

  const {
    data: res,
    refetch,
    isFetching,
    error,
  } = useQuery({
    queryKey: ['YieldBlockDataReport'],
    initialData: [],
    queryFn: () =>
      BlockReportApi.get({
        reportType: ReportTypeEnum.Yield,
        reportLevels: [ReportLevelsEnum.Block, ReportLevelsEnum.GroundTruth],
        blockIds: selectedBlocks.map((block) => block.id),
        startDate,
        endDate,
      }),
    enabled: false,
  })

  useEffect(() => {
    if (error) handleAxiosError(error)
  }, [error, handleAxiosError])

  useEffect(() => {
    if (!selectedBlocks.length) return
    refetch()
  }, [selectedBlocks, startDate, endDate, refetch])

  useEffect(() => {
    if (res.length) {
      const convertYieldReportDataToTableData = () => {
        const dataMap = new Map<number, YieldReportsByBlock>()

        res.forEach((report) => {
          const block = blocksAsMap.get(report.blockId)
          if (!block) return

          const blockData = report.blockData
          const data = dataMap.get(report.blockId) || {
            blockId: report.blockId,
            blockName: block.name,
            vineyardName: vineyardsAsMap.get(report.vineyardId)?.name || 'Unknown Vineyard',
            rowStart: block.rowStart,
            rowEnd: block.rowEnd,
            varietyId: block.varietyId,
            rowIndex: block.rowIndex,
            reports: [],
          }

          data.reports.push({
            ...blockData,
            blockReportId: report.id,
            startDate: report.dateStart,
            endDate: report.dateEnd,
            groundTruthData: report.groundTruthData,
            visibleChange: 'none',
            correctedChange: 'none',
            rowsScannedPct: pctOfRowsScanned(blockData.rowsScanned, block.rowIndex),
          })
          dataMap.set(report.blockId, data)
        })

        dataMap.forEach((blockData) => {
          blockData.reports.sort((a, b) => a.endDate.localeCompare(b.endDate))
          for (let i = 0; i < blockData.reports.length; i++) {
            const report = blockData.reports[i] as YieldReport
            if (i > 0) {
              const prevReport = blockData.reports[i - 1] as YieldReport
              report.visibleChange = compareValues(report.visBunchesPerVineMean, prevReport.visBunchesPerVineMean)
              report.correctedChange = compareValues(report.correctedBunchesPerVineMean, prevReport.correctedBunchesPerVineMean)
            }
          }
        })

        return Array.from(dataMap.values())
      }

      const compareValues = (current: number, previous: number) => {
        if (current > previous) return 'up'
        if (current < previous) return 'down'
        return 'none'
      }

      setData(convertYieldReportDataToTableData())
    } else {
      setData([])
    }
  }, [res, blocksAsMap, setData])

  // Get unique varieties from reports
  const uniqueVarieties = useMemo(() => Array.from(new Set(data.map((report) => report.varietyId))), [data])

  //Create options using unique varieties and varieties from store
  const varietyOptions = useMemo(
    () =>
      uniqueVarieties
        .map((varietyId) => ({ label: varieties.find((v) => v.id === varietyId)?.name || '', value: varietyId }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [uniqueVarieties, varieties]
  )

  return { data, isFetching, varietyOptions }
}
