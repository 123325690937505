import Graphic from '@arcgis/core/Graphic'
import FeatureLayer from '@arcgis/core/layers/FeatureLayer'
import { VineAndPostLayer } from './layers/pruning-vines.layer'
import { RowsLayer } from './layers/rows.layer'

export const applyEditsToFeatureLayer = (featureLayer: FeatureLayer, edits: __esri.FeatureLayerBaseApplyEditsEdits) => {
  featureLayer
    .applyEdits(edits)
    .then((results) => {
      // if edits were removed
      // if (results.deleteFeatureResults.length > 0) {
      //   console.log(results.deleteFeatureResults.length, 'features have been removed')
      // }
      // if features were added - call queryFeatures to return
      //    newly added graphics
      if (results.addFeatureResults.length > 0) {
        const objectIds: any = []
        results.addFeatureResults.forEach((item: any) => {
          objectIds.push(item.objectId)
        })
        // query the newly added features from the layer
        featureLayer.queryFeatures({
          objectIds: objectIds,
        })
        // .then((results: __esri.FeatureSet) => {
        //   console.log(results.features.length, 'features have been added.')
        // })
      }
    })
    .catch((error) => console.log(error))
}

export const setGraphicsToFeatureLayer = async (featureLayer: FeatureLayer, graphics: Graphic[]) => {
  const results = await featureLayer.queryFeatures()
  const addEdits = {
    deleteFeatures: results.features,
    addFeatures: graphics,
  }
  applyEditsToFeatureLayer(featureLayer, addEdits)
}

export const getMultipleGraphicsByIdFromFeatureLayer = async (featureLayer: FeatureLayer, ids: number[]) => {
  let graphics = [] as Graphic[]
  const results: __esri.FeatureSet = await featureLayer.queryFeatures()
  if (results.features.length) {
    graphics = results.features.filter((p) => ids.includes(p.attributes.id))
  }
  return graphics
}

export const removeGraphicsFromFeatureLayer = async (featureLayer: FeatureLayer[]) => {
  return featureLayer.forEach((layer) => {
    layer.queryFeatures().then((results) => {
      const deleteEdits = { deleteFeatures: results.features }
      applyEditsToFeatureLayer(layer, deleteEdits)
    })
  })
}

export const updateRowAndVineFeatureLayers = async (vineAndPostGraphics: Graphic[], rowGraphics: Graphic[]) => {
  const { features } = await VineAndPostLayer.queryFeatures()
  const { features: rowFeatures } = await RowsLayer.queryFeatures()

  const addVineEdits = { addFeatures: vineAndPostGraphics, deleteFeatures: features } as __esri.FeatureLayerBaseApplyEditsEdits
  const addRowEdits = { addFeatures: rowGraphics, deleteFeatures: rowFeatures } as __esri.FeatureLayerBaseApplyEditsEdits

  applyEditsToFeatureLayer(RowsLayer, addRowEdits)
  applyEditsToFeatureLayer(VineAndPostLayer, addVineEdits)
}
