import { GridColDef, GridColumnGroupingModel } from '@mui/x-data-grid-pro'
import { createFormatter, formatISO_, formatToSingleDecimalIfNecessaryOrDash } from '@utils/utility-fns'
import { t } from 'i18next'

export const yieldCols = (): GridColDef[] => [
  {
    field: 'scanDate',
    headerName: 'Date',
    valueFormatter: (value) => formatISO_(value, 'd MMM yyyy'),
    type: 'date',
    width: 110,
  },
  { field: 'vineyardName', headerName: t('vineyard'), width: 150, valueFormatter: createFormatter() },
  { field: 'blockName', headerName: t('block'), width: 150, valueFormatter: createFormatter() },
  { field: 'rowNum', headerName: t('row'), width: 70 },

  {
    field: 'visBunchCountTotal',
    headerName: t('bunch_count'),
    width: 120,
    valueFormatter: createFormatter((v) => formatToSingleDecimalIfNecessaryOrDash(v)),
  },
  {
    field: 'visBunchesPerVineMean',
    headerName: t('bunch/vine_(mean)'),
    width: 150,
    valueFormatter: createFormatter((v) => formatToSingleDecimalIfNecessaryOrDash(v)),
  },
  {
    field: 'visBunchesPerVineMedian',
    headerName: t('bunch/vine_(median)'),
    width: 160,
    valueFormatter: createFormatter((v) => formatToSingleDecimalIfNecessaryOrDash(v)),
  },
  {
    field: 'correctedBunchCountTotal',
    headerName: t('bunch_count'),
    width: 120,
    valueFormatter: createFormatter((v) => formatToSingleDecimalIfNecessaryOrDash(v)),
  },
  {
    field: 'correctedBunchesPerVineMean',
    headerName: t('bunch/vine_(mean)'),
    width: 150,
    valueFormatter: createFormatter((v) => formatToSingleDecimalIfNecessaryOrDash(v)),
  },
  {
    field: 'correctedBunchesPerVineMedian',
    headerName: t('bunch/vine_(median)'),
    width: 160,
    valueGetter: (value) => formatToSingleDecimalIfNecessaryOrDash(value),
  },
  {
    field: 'extrapolatedBunchCountTotal',
    headerName: t('bunch_count'),
    width: 120,
    valueFormatter: createFormatter((v) => formatToSingleDecimalIfNecessaryOrDash(v)),
  },
  {
    field: 'extrapolatedBunchesPerVineMean',
    headerName: t('bunch/vine_(mean)'),
    width: 150,
    valueFormatter: createFormatter((v) => formatToSingleDecimalIfNecessaryOrDash(v)),
  },
  {
    field: 'extrapolatedBunchesPerVineMedian',
    headerName: t('bunch/vine_(median)'),
    width: 160,
    valueGetter: (value) => formatToSingleDecimalIfNecessaryOrDash(value),
  },
  // {
  //   field: 'rowScannedPct',
  //   headerName: t('row_scanned_%'),
  //   width: 140,
  //   valueFormatter: createFormatter((v) => formatToSingleDecimalIfNecessaryOrDash(v)),
  // },
]

export const yieldColsGrouping = (): GridColumnGroupingModel => [
  {
    groupId: t('corrected_counts'),
    headerAlign: 'center',
    headerClassName: 'datagrid-top-header',
    children: [
      {
        groupId: t('statistics'),
        headerAlign: 'center',
        headerClassName: 'datagrid-mid-header',
        children: [
          { field: 'correctedBunchCountTotal' },
          { field: 'correctedBunchesPerVineMean' },
          { field: 'correctedBunchesPerVineMedian' },
        ],
      },
    ],
  },
  {
    groupId: t('visible_counts'),
    headerAlign: 'center',
    headerClassName: 'datagrid-top-header',
    children: [
      {
        groupId: t('statistics'),
        headerAlign: 'center',
        headerClassName: 'datagrid-mid-header',
        children: [{ field: 'visBunchCountTotal' }, { field: 'visBunchesPerVineMean' }, { field: 'visBunchesPerVineMedian' }],
      },
    ],
  },
  {
    groupId: t('extrapolated_counts'),
    headerAlign: 'center',
    headerClassName: 'datagrid-top-header',
    children: [
      {
        groupId: t('statistics'),
        headerAlign: 'center',
        headerClassName: 'datagrid-mid-header',
        children: [
          { field: 'extrapolatedBunchCountTotal' },
          { field: 'extrapolatedBunchesPerVineMean' },
          { field: 'extrapolatedBunchesPerVineMedian' },
        ],
      },
    ],
  },
  {
    groupId: t('scan_details'),
    headerAlign: 'center',
    headerClassName: 'datagrid-top-header',
    children: [
      {
        groupId: 'x',
        headerClassName: 'datagrid-mid-hide-text',
        children: [{ field: 'rowScannedPct' }],
      },
    ],
  },
]
