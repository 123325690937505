import { GridColDef, GridColumnGroupingModel } from '@mui/x-data-grid-pro'
import { DiseaseFilterOption } from '@typings/component.enums'
import { createFormatter, formatISO_, formatToSingleDecimalIfNecessaryOrZero } from '@utils/utility-fns'
import { t } from 'i18next'

export const healthCols = (option: DiseaseFilterOption): GridColDef[] => {
  const sharedCols: GridColDef[] = [
    {
      field: 'scanDate',
      headerName: 'Date',
      valueFormatter: (value) => formatISO_(value, 'd MMM yyyy'),
      type: 'date',
      width: 110,
    },
    { field: 'vineyardName', headerName: t('vineyard'), width: 150, valueFormatter: createFormatter() },
    { field: 'blockName', headerName: t('block'), width: 150, valueFormatter: createFormatter() },
    { field: 'rowNum', headerName: t('row'), width: 70 },
  ]

  const pmCols: GridColDef[] = [
    {
      field: 'pmBunchAverageInfection',
      headerName: t('infection_avg'),
      width: 140,
      valueFormatter: createFormatter((v) => formatToSingleDecimalIfNecessaryOrZero(v)),
      cellClassName: (p) => (p.value > 0 ? 'datagrid-cell-error' : ''),
    },
    {
      field: 'pmBunchInfectedVines',
      headerName: t('infected_vines'),
      width: 140,
      valueFormatter: createFormatter((v) => formatToSingleDecimalIfNecessaryOrZero(v)),
      cellClassName: (p) => (p.value > 0 ? 'datagrid-cell-error' : ''),
    },
    {
      field: 'pmBunchNumberOfDetections',
      headerName: t('total_detections'),
      width: 140,
      valueFormatter: createFormatter((v) => formatToSingleDecimalIfNecessaryOrZero(v)),
      cellClassName: (p) => (p.value > 0 ? 'datagrid-cell-error' : ''),
    },

    {
      field: 'pmLeafAverageInfection',
      headerName: t('infection_avg'),
      width: 140,
      valueFormatter: createFormatter((v) => formatToSingleDecimalIfNecessaryOrZero(v)),
      cellClassName: (p) => (p.value > 0 ? 'datagrid-cell-error' : ''),
    },
    {
      field: 'pmLeafInfectedVines',
      headerName: t('infected_vines'),
      width: 140,
      valueFormatter: createFormatter((v) => formatToSingleDecimalIfNecessaryOrZero(v)),
      cellClassName: (p) => (p.value > 0 ? 'datagrid-cell-error' : ''),
    },
    {
      field: 'pmLeafNumberOfDetections',
      headerName: t('total_detections'),
      width: 140,
      valueFormatter: createFormatter((v) => formatToSingleDecimalIfNecessaryOrZero(v)),
      cellClassName: (p) => (p.value > 0 ? 'datagrid-cell-error' : ''),
    },
  ]

  const botrytisCols: GridColDef[] = [
    {
      field: 'bbrAverageInfection',
      headerName: t('infection_avg'),
      width: 140,
      valueFormatter: createFormatter((v) => formatToSingleDecimalIfNecessaryOrZero(v)),
      cellClassName: (p) => (p.value > 0 ? 'datagrid-cell-error' : ''),
    },
    {
      field: 'bbrInfectedVines',
      headerName: t('infected_vines'),
      width: 140,
      valueFormatter: createFormatter((v) => formatToSingleDecimalIfNecessaryOrZero(v)),
      cellClassName: (p) => (p.value > 0 ? 'datagrid-cell-error' : ''),
    },
    {
      field: 'bbrNumberOfDetections',
      headerName: t('total_detections'),
      width: 140,
      valueFormatter: createFormatter((v) => formatToSingleDecimalIfNecessaryOrZero(v)),
      cellClassName: (p) => (p.value > 0 ? 'datagrid-cell-error' : ''),
    },
  ]

  const dmgBunchCols: GridColDef[] = [
    {
      field: 'dmgBunchAverageInfection',
      headerName: t('infection_avg'),
      width: 140,
      valueFormatter: createFormatter((v) => formatToSingleDecimalIfNecessaryOrZero(v)),
      cellClassName: (p) => (p.value > 0 ? 'datagrid-cell-error' : ''),
    },
    {
      field: 'dmgBunchInfectedVines',
      headerName: t('infected_vines'),
      width: 140,
      valueFormatter: createFormatter((v) => formatToSingleDecimalIfNecessaryOrZero(v)),
      cellClassName: (p) => (p.value > 0 ? 'datagrid-cell-error' : ''),
    },
    {
      field: 'dmgBunchNumberOfDetections',
      headerName: t('total_detections'),
      width: 140,
      valueFormatter: createFormatter((v) => formatToSingleDecimalIfNecessaryOrZero(v)),
      cellClassName: (p) => (p.value > 0 ? 'datagrid-cell-error' : ''),
    },
  ]

  //Temporarily removing/disabling Flavescence Dorée features. Do not remove the logic below.
  // const frenchCols: GridColDef[] = [
  //   {
  //     field: 'fdLowVines',
  //     headerName: t('low'),
  //     width: 140,
  //     valueFormatter: createFormatter(),
  //   },
  //   {
  //     field: 'fdMediumVines',
  //     headerName: t('medium'),
  //     width: 140,
  //     valueFormatter: createFormatter(),
  //   },
  //   {
  //     field: 'fdHighVines',
  //     headerName: t('high'),
  //     width: 140,
  //     valueFormatter: createFormatter(),
  //   },
  // ]

  const trunkCols: GridColDef[] = [
    {
      field: 'deadVines',
      headerName: t('dead'),
      width: 120,
      valueFormatter: createFormatter(),
    },
    {
      field: 'strongInfectedVines',
      headerName: t('strong'),
      width: 120,
      valueFormatter: createFormatter(),
    },
    {
      field: 'weakInfectedVines',
      headerName: t('weak'),
      width: 120,
      valueFormatter: createFormatter(),
    },
  ]

  const diseaseColsMap = {
    [DiseaseFilterOption.PowderMildew]: pmCols,
    [DiseaseFilterOption.Botrytis]: botrytisCols,
    [DiseaseFilterOption.DamagedBunch]: dmgBunchCols,
    // [DiseaseFilterOption.FlavescenceDoree]: frenchCols,
    [DiseaseFilterOption.TrunkDisease]: trunkCols,
  }

  return sharedCols.concat(diseaseColsMap[option] || [])
}

export const healthColsGrouping = (): GridColumnGroupingModel => [
  {
    groupId: 'bbr',
    headerName: t('botrytis_bunch_rot'),
    headerAlign: 'center',
    headerClassName: 'bg-lightblue-1',
    children: [{ field: 'bbrAverageInfection' }, { field: 'bbrInfectedVines' }, { field: 'bbrNumberOfDetections' }],
  },
  {
    groupId: 'dmgBunch',
    headerName: t('damaged_bunch'),
    headerAlign: 'center',
    headerClassName: 'bg-lightblue-2',
    children: [
      { field: 'dmgBunchAverageInfection' },
      { field: 'dmgBunchInfectedVines' },
      { field: 'dmgBunchNumberOfDetections' },
    ],
  },
  {
    groupId: 'pmBunch',
    headerName: t('mildew_bunches'),
    headerAlign: 'center',
    headerClassName: 'bg-lightblue-1',
    children: [{ field: 'pmBunchAverageInfection' }, { field: 'pmBunchInfectedVines' }, { field: 'pmBunchNumberOfDetections' }],
  },
  {
    groupId: 'pmLeaf',
    headerName: t('mildew_leaves'),
    headerAlign: 'center',
    headerClassName: 'bg-lightblue-2',
    children: [{ field: 'pmLeafAverageInfection' }, { field: 'pmLeafInfectedVines' }, { field: 'pmLeafNumberOfDetections' }],
  },
  {
    groupId: 'fdVines',
    headerName: t('fd_vines'),
    headerAlign: 'center',
    description: 'Flavescence Dorée Infected Vines',
    headerClassName: 'bg-lightblue-1',
    children: [{ field: 'fdLowVines' }, { field: 'fdMediumVines' }, { field: 'fdHighVines' }],
  },
  {
    groupId: 'trunkDisease',
    headerName: t('trunk_disease'),
    headerAlign: 'center',
    description: 'Trunk Disease',
    headerClassName: 'bg-lightblue-2',
    children: [{ field: 'deadVines' }, { field: 'strongInfectedVines' }, { field: 'weakInfectedVines' }],
  },
]
