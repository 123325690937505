import SimpleBarStyled from '@components/containers/SimpleBarStyled'
import Iconify from '@components/data-display/Iconify'
import DrawerHead from '@components/drawer/DrawerHead'
import { useTranslate } from '@hooks/useLocales'
import { IconButton, Typography } from '@mui/material'
import Drawer from '@mui/material/Drawer'
import useVineyardStore from '@stores/vineyard'
import { BlockDto } from '@typings/dtos/block'
import { formatISOWithCustomHours } from '@utils/utility-fns'
import { useShallow } from 'zustand/react/shallow'

type ScanTimelineDrawerProps = {
  open: boolean
  onClose: VoidFunction
}

const ScanTimelineDrawer = ({ open, onClose }: ScanTimelineDrawerProps) => {
  const { t } = useTranslate()
  const [blocksGroupByScanDate, blocksAsMap, setSelectedBlocksLocal, setStartDate, setEndDate] = useVineyardStore(
    useShallow((s) => [s.blocksGroupByScanDate, s.blocksAsMap, s.setSelectedBlocksLocal, s.setStartDateLocal, s.setEndDateLocal])
  )

  /* Find all the blocks with these blockIds and add it to selectedBlocks */
  const handleAddBlocksToFilters = (blocksIds: number[], date: string) => {
    const blocksToAdd = blocksIds.map((blockId) => blocksAsMap.get(blockId) as BlockDto)
    setSelectedBlocksLocal(blocksToAdd)
    setStartDate(formatISOWithCustomHours(new Date(date)))
    setEndDate(formatISOWithCustomHours(new Date(date), { h: 23, m: 59, s: 59, ms: 999 }))
    onClose()
  }

  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="right"
      PaperProps={{ sx: { width: 440, overflow: 'hidden' } }}
      slotProps={{ backdrop: { invisible: true } }}
    >
      <DrawerHead label={t('scan_timeline')} onClose={onClose} icon="mdi:timeline-check" />
      <SimpleBarStyled
        sx={{
          height: 1,
          px: 3,
          pt: 2,
          pb: 10,
          '& .simplebar-content': { display: 'flex', flexDirection: 'column' },
        }}
      >
        {blocksGroupByScanDate.map((scan, index) => (
          <div key={index} style={{ display: 'flex', gap: 16 }}>
            <div
              style={{
                width: 2,
                minHeight: 60,
                position: 'relative',
                display: 'flex',
                background: 'linear-gradient(#64C043, rgba(100, 192, 67, 0)',
              }}
            >
              <div style={{ position: 'absolute', top: 0, left: -11, backgroundColor: 'white', height: 20 }}>
                <Iconify icon="ri:time-fill" color="primary.main" width={24} />
              </div>
            </div>
            <div style={{ display: 'grid', width: '100%' }}>
              <div style={{ display: 'flex', alignItems: 'center', marginTop: -0.3 }}>
                <Typography fontSize={15} fontWeight={500} lineHeight={1.6} noWrap color="primary.dark">
                  {scan.scanDate}
                </Typography>
                <IconButton
                  title="Add to Filters"
                  size="small"
                  sx={{ ml: 'auto', backgroundColor: '#6B7280', ':hover': { backgroundColor: '#4B5563' } }}
                  onClick={() =>
                    handleAddBlocksToFilters(scan.vineyards.map((v) => v.blocks.map((b) => b.id)).flat(), scan.scanDate)
                  }
                >
                  <Iconify icon="mingcute:filter-3-fill" color="white" />
                </IconButton>
              </div>
              <div style={{ display: 'grid', paddingBottom: 32 }}>
                {scan.vineyards.map((v, i) => (
                  <div
                    key={i}
                    style={{
                      display: 'grid',
                      borderBottom: i === scan.vineyards.length - 1 ? 'none' : '1px solid #E7E5E4',
                      padding: '4px 0 10px 0',
                      gap: 2,
                    }}
                  >
                    <span style={{ color: 'rgb(97, 97, 97)', fontWeight: 500, fontSize: 14 }}>{v.name}</span>
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: 4 }}>
                      {v.blocks.map((block, index) => (
                        <div
                          key={index}
                          style={{
                            backgroundColor: 'rgba(0, 0, 0, 0.08)',
                            color: '#6B7280',
                            borderRadius: 4,
                            padding: '0 6px',
                            fontSize: 14,
                            fontWeight: 500,
                          }}
                        >
                          {block.name}
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </SimpleBarStyled>
    </Drawer>
  )
}

export default ScanTimelineDrawer
