import HighlightColumnWrapper from '@components/containers/HighlightColumnWrapper'
import HighlightsInfoWrapper from '@components/containers/HighlightsInfoWrapper'
import SummaryTileWrapper from '@components/containers/SummaryTileWrapper'
import HighlightInfo from '@components/data-display/HighlightInfo'
import SortBlocksMenu from '@components/echarts/SortBlocksMenu'
import PieChart from '@components/echarts/charts/PieChart'
import StackedHorizontalBar from '@components/echarts/charts/StackedHorizontalBar'
import { useBlockDataHighlights } from '@hooks/useBlockDataHighlights'
import { useTranslate } from '@hooks/useLocales'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import { BlockData, BlockReport } from '@typings/dtos/block-report/models'
import { generateBarcodeSVG, labelFormatter_PruningCaneTarget } from '@utils/echarts'
import { blockPotentialSortOptions, canesSortOptions } from '@utils/options'
import {
  colorByAssumedCaneTarget,
  createCanesDataset,
  fmtNumToThousandSepSingleDecOrZero,
  formatISO_,
  numberFmtThousandSeparator,
  rowStatsPruningToEChartsData,
} from '@utils/utility-fns'
import { DatasetOption, TooltipFormatterCallback, TopLevelFormatterParams } from 'echarts/types/dist/shared.js'
import i18next from 'i18next'
import { useCallback, useEffect, useState } from 'react'
import ConditionalStatsTileContent from '../ConditionalStatsTileContent'
import CanePruningBudsStatsBoxplotChart from './charts/CanePruningBudsStatsBoxplotChart'

type CanePrunedSectionProps = {
  data: BlockReport[]
  statsBy: 'cane' | 'vine'
}

const CanePrunedSection = ({ data, statsBy }: CanePrunedSectionProps) => {
  const [activeSort, setActiveSort] = useState('')
  const [canesDataset, setCanesDataset] = useState<ReturnType<typeof createCanesDataset>>({
    id: 'canes-dataset',
    dimensions: ['blockName'],
    source: [],
  })

  const { t } = useTranslate()
  const { palette } = useTheme()

  const { totalDeadVines, totalMissingVines, totalVines } = useBlockDataHighlights({ data })
  const isStatsByVine = statsBy === 'vine'

  const sortDatasetByName = useCallback(() => {
    setCanesDataset({
      ...canesDataset,
      // @ts-expect-error - TS can't access source type definition
      source: canesDataset.source.sort((a, b) => {
        return activeSort === 'blockName-asc' ? b.blockName.localeCompare(a.blockName) : a.blockName.localeCompare(b.blockName)
      }),
    })
  }, [activeSort, canesDataset])

  const sortDatasetByCanesValue = useCallback(() => {
    const sortKey = activeSort.split('-')[0] as keyof typeof createCanesDataset

    if (sortKey) {
      setCanesDataset({
        ...canesDataset,
        // @ts-expect-error - TS can't access source type definition
        source: canesDataset.source.sort((a, b) => {
          const valueA = a[sortKey] || 0
          const valueB = b[sortKey] || 0
          return activeSort.endsWith('-asc') ? valueB - valueA : valueA - valueB
        }),
      })
    }
  }, [activeSort, canesDataset])

  useEffect(() => {
    if (activeSort === '') return
    if (activeSort.startsWith('blockName')) sortDatasetByName()
    else sortDatasetByCanesValue()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSort, canesDataset.source])

  /* Creating data fo the charts, based on data from API (custom hook) */
  useEffect(() => {
    if (!data) return
    setCanesDataset(createCanesDataset(data, statsBy))
  }, [data, statsBy])

  return (
    <>
      <Box display="flex" flexWrap="wrap" gap={2}>
        <Box display="flex" gap={2} flexWrap="wrap">
          <HighlightsInfoWrapper
            label="showing_stats_for"
            mainHighlightComponent={<ConditionalStatsTileContent data={data} />}
            tooltipInfo={'pruning_page.summary.tooltip_showing_stats_for'}
          />
          <HighlightsInfoWrapper
            label="block(s)"
            mainHighlightComponent={
              <HighlightInfo value={numberFmtThousandSeparator(totalVines)} label="vines_scanned" color="info.dark" />
            }
          >
            <HighlightColumnWrapper>
              <HighlightInfo value={numberFmtThousandSeparator(totalDeadVines)} label="dead_vines" color="error.main" />
              <HighlightInfo value={numberFmtThousandSeparator(totalMissingVines)} label="missing_vines" color="error.main" />
            </HighlightColumnWrapper>
          </HighlightsInfoWrapper>
          <SummaryTileWrapper
            label={'pruning_stats'}
            width={308}
            height={150}
            tooltipMsg={'pruning_page.summary.tooltip_pruning_stats'}
          >
            <PieChart data={rowStatsPruningToEChartsData(data, t)} tooltipFormatter={pieChartTooltipFormatter} />
          </SummaryTileWrapper>
        </Box>
      </Box>
      <SummaryTileWrapper
        label={isStatsByVine ? 'cane_target_stats' : 'block_potential_stats'}
        width="100%"
        height="100%"
        tooltipMsg={
          isStatsByVine ? 'pruning_page.summary.tooltip_compare_canes' : 'pruning_page.summary.cane.tooltip_block_potential_stats'
        }
        headerComponent={
          canesDataset.source.length > 1 && (
            <SortBlocksMenu
              id="sort-canes-data"
              menuItems={isStatsByVine ? canesSortOptions : blockPotentialSortOptions}
              value={activeSort}
              onClick={(val) => setActiveSort(val)}
              menuArrowPositionRight={110}
            />
          )
        }
      >
        <Box py={4}>
          <StackedHorizontalBar
            dataset={canesDataset as DatasetOption}
            color={statsBy === 'cane' ? [palette.range.posHigh, palette.range.negHigh] : undefined}
            tooltipFormatter={(p) => tooltipFormatter(p, statsBy)}
            yAxisCategoryLabelFormatter={labelFormatter_PruningCaneTarget}
          />
        </Box>
      </SummaryTileWrapper>
      <CanePruningBudsStatsBoxplotChart data={data} dataBy={statsBy} />
    </>
  )
}

export default CanePrunedSection

const tooltipFormatter = (params: TopLevelFormatterParams, statsBy: 'cane' | 'vine') => {
  const { t } = i18next
  // @ts-expect-error - TS doesn't know that params is an object
  const { name, value, data } = params[0]
  const { rowStart, rowEnd, rowsScanned } = data

  const dateString = formatISO_(name.split('>')[1], 'dd MMM yyyy')
  const nameWithoutLastChar = name.split('>')[0] || name

  let result = `<div style="margin-bottom: 6px; border-bottom: 1px solid grey; margin-bottom: 10px">
                   <div style="text-align: left; font-weight: 600; font-size: 14px;">${nameWithoutLastChar}</div>
                   <div style="text-align: left; font-size: 14px;">${dateString}</div>
                   ${
                     statsBy === 'cane'
                       ? `<div style="margin-bottom: 4px">${t('total_potential_canes')}: <b>${fmtNumToThousandSepSingleDecOrZero(Number(value.caneLaidPct) + Number(value.caneNotLaidPct))}</b></div>`
                       : `<div style="margin-bottom: 4px">${t('assumed_cane_target')}: <b>${value.caneTarget}</b></div>`
                   }
                   <div style="margin-bottom: 12px;">${generateBarcodeSVG(rowStart, rowEnd, rowsScanned)}</div>
                 
                </div>`

  if (statsBy === 'cane') {
    result += `<div>
                  <div style="margin-bottom: 4px; display: flex; justify-content: space-between;">
                     <span>${t('canes_laid')}</span>
                     <span><b>${value.canesLaid}</b>&nbsp;(${value.canesLaidPct}%)</span>
                   </div>
                   <div style="margin-bottom: 4px; display: flex; justify-content: space-between;">
                     <span>${t('canes_not_laid')}</span>
                     <span><b>${value.canesNotLaid}</b>&nbsp;(${value.canesNotLaidPct}%)</span>
                   </div>
                </div>`

    return result
  }

  const caneValues = [
    { key: 'cane4', value: value.cane4, pct: value.cane4Pct, label: '4_cane' },
    { key: 'cane3', value: value.cane3, pct: value.cane3Pct, label: '3_cane' },
    { key: 'cane2', value: value.cane2, pct: value.cane2Pct, label: '2_cane' },
    { key: 'cane1', value: value.cane1, pct: value.cane1Pct, label: '1_cane' },
  ]

  caneValues.forEach((cane) => {
    if (cane.value !== 0) {
      result += `<div style="display: flex; color: #27272A; justify-content: space-between; gap: 2; background-color: ${colorByAssumedCaneTarget(
        value.caneTarget,
        cane.key as keyof BlockData
      )}">
           <b style="width: 120px; padding:6px 0 6px 8px">${t(cane.label)}:</b>
           <span style="padding:6px 8px 6px 8px"><b>${cane.value}</b>&nbsp;(${cane.pct}%)</span></span>
        </div>`
    }
  })
  return result
}

const pieChartTooltipFormatter: TooltipFormatterCallback<TopLevelFormatterParams> = (params) => {
  const { t } = i18next
  // @ts-expect-error - TS doesn't know that params is an object
  const { name, value, vines } = params.data

  return `<div>
            <div style="text-align: left; font-weight: 600; font-size: 14px; margin-bottom:10px">${name}</div>
            <div style="margin-bottom: 4px">${t('vines')}: <b>${vines} (${value}%)</b></div>
          </div>`
}
