import { Image, Link, Page, Text, View } from '@react-pdf/renderer'
import { BlockReport } from '@typings/dtos/block-report/models'
import { formatISO_ } from '@utils/utility-fns'
import { s } from './styles'

type TitlePageProps = {
  data: { [key: string]: BlockReport[] }
  dateRange: string
}

const TitlePage = ({ data, dateRange }: TitlePageProps) => {
  return (
    <Page size="A4" style={s.page}>
      <View style={s.centerH}>
        <Image src="/logos/head-logo.png" style={s.logo} />
        <Text style={{ fontSize: 14, marginTop: 6 }}>PRUNING REPORT</Text>
        <Text>{dateRange}</Text>
      </View>

      {Object.entries(data).map(([blockName, reports]) => (
        <View id="group" key={blockName} style={{ gap: 6, paddingVertical: 12 }} wrap={false}>
          <Text style={{ fontSize: 12 }}>{blockName}</Text>
          <View style={{ display: 'flex', flexDirection: 'row', gap: 8, flexWrap: 'wrap' }}>
            {reports.map((report) => (
              <Link
                key={report.id}
                src={`#${report.id}`}
                style={{ border: '1px solid #3F3F46', textDecoration: 'none', color: '#0284C7', borderRadius: 2 }}
              >
                <Text style={{ padding: '2px 6px 3px 6px' }}>
                  {formatISO_(report.dateStart, 'd MMM yyyy')}
                  {'\u00A0\u2014\u00A0'}
                  {formatISO_(report.dateEnd, 'd MMM yyyy')}
                </Text>
              </Link>
            ))}
          </View>
        </View>
      ))}
    </Page>
  )
}

export default TitlePage
