import CryptoJS from 'crypto-js'
import pako from 'pako'

/**
 * Converts a Base64-encoded gzip string to a JSON object.
 * @param {string} gzipBase64String - The Base64-encoded gzip string.
 * @returns {T} - The JSON object.
 * @throws Will throw an error if the decompression or parsing fails.
 */
export function decompressGzipToJson<T>(gzipBase64String: string): T {
  if (!gzipBase64String) return [] as T
  try {
    // Decode the Base64 string directly to a Uint8Array
    const bytes = Uint8Array.from(atob(gzipBase64String), (char) => char.charCodeAt(0))

    // Decompress the gzip data
    const decompressedData = pako.inflate(bytes, { to: 'string' })

    // Parse the decompressed data as JSON
    return JSON.parse(decompressedData) as T
  } catch (error) {
    throw new Error(`Failed to decompress and parse JSON: ${(error as Error).message}`)
  }
}

export function decryptAES(encryptedText: string | any, iv: string): string {
  if (!encryptedText) return ''

  // Convert the key and IV from base64
  const key = CryptoJS.enc.Utf8.parse(import.meta.env.VITE_DECRYPTION_KEY)
  const ivBytes = CryptoJS.enc.Base64.parse(iv)

  // Decrypt the encrypted text (base64 encoded)
  const decrypted = CryptoJS.AES.decrypt(encryptedText, key, {
    iv: ivBytes,
  })

  // Convert the decrypted data to a UTF-8 string
  return decrypted.toString(CryptoJS.enc.Utf8)
}
