import CustomTableWrapper from '@components/containers/CustomTableWrapper'
import BarcodedRows from '@components/visualisations/BarcodedRows'
import { DiseaseReportsByBlock } from '@hooks/health/useGetDiseaseBlockReports'
import { useTranslate } from '@hooks/useLocales'
import { DiseaseStats } from '@typings/dtos/block-report/models'
import { formatISO_ } from '@utils/utility-fns'
import { useMemo } from 'react'

type HealthTableProps = {
  block: DiseaseReportsByBlock
  detectionsToShow: Array<keyof DiseaseStats>
}

const HealthTable = ({ block, detectionsToShow }: HealthTableProps) => {
  const { t } = useTranslate()
  const isTrunkDiseaseSelected = detectionsToShow.includes('trunkDeadVines')

  const bubble = useMemo(
    () => (detections: number) => {
      if (detectionsToShow.length === 0) return { size: 0, color: 'transparent' }

      if (isTrunkDiseaseSelected) {
        if (detections >= 20.01) return { size: 70, color: '#FCA5A5' }
        else if (detections >= 5.01) return { size: 60, color: '#FDE047' }
        else if (detections >= 0.1) return { size: 50, color: '#86EFAC' }
        else return { size: 0, color: 'transparent' }
      } else {
        if (detections >= 201) return { size: 70, color: '#FCA5A5' }
        else if (detections >= 51) return { size: 60, color: '#FDE047' }
        else if (detections >= 1) return { size: 50, color: '#86EFAC' }
        else return { size: 0, color: 'transparent' }
      }
    },
    [detectionsToShow]
  )

  return (
    <CustomTableWrapper blockName={block.blockName} vineyardName={block.vineyardName}>
      {block.reports.map((report) => {
        const { id, dateStart, dateEnd, blockData: bd } = report
        const { pmTotalInfectedVines, bbrInfectedVines } = bd

        const sumV = detectionsToShow.reduce((acc, key) => {
          if (bd[key]) return acc + bd[key]
          return acc
        }, 0)

        const totalTrunk = (bd.trunkDeadVines || 0) + (bd.trunkStrongVines || 0) + (bd.trunkWeakVines || 0)
        const totalTrunkPct = (bd.trunkDeadVinesPct || 0) + (bd.trunkStrongVinesPct || 0) + (bd.trunkWeakVinesPct || 0)

        return (
          <div
            style={{ cursor: 'pointer', backgroundColor: '#FAFAFA', borderRadius: 4, border: '2px solid #F5F5F5', height: 150 }}
            className="custom-table-col"
            key={id}
          >
            <div className="custom-table-head" style={{ padding: '8px 12px 12px 12px', height: '100%' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end', paddingBottom: 2 }}>
                <div style={{ fontSize: 13, fontWeight: 500, color: '#525252' }}>
                  {formatISO_(dateStart)} &#8212; {formatISO_(dateEnd)}
                </div>
                {report.rowsScannedPct > 0 && <div style={{ fontSize: 12, color: '#737373' }}>{report.rowsScannedPct}%</div>}
              </div>
              <BarcodedRows
                color="#737373"
                rowsStartNumber={block.rowStart}
                rowsEndNumber={block.rowEnd}
                rowIndex={block.rowIndex}
                rowsScanned={bd.rowsScanned.map((row) => row.rowNum)}
                style={{ borderRadius: 3, overflow: 'hidden' }}
              />
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <table style={{ fontSize: 12, height: 'fit-content', marginTop: 4 }}>
                  {isTrunkDiseaseSelected ? (
                    <tbody>
                      {bd.trunkDeadVines && (
                        <tr>
                          <td style={{ color: '#57534E' }}>{t('dead')}</td>
                          <td style={{ fontWeight: 500 }}>{bd.trunkDeadVines + `(${bd.trunkDeadVinesPct}%)`}</td>
                        </tr>
                      )}
                      {bd.trunkStrongVines && (
                        <tr>
                          <td style={{ color: '#57534E' }}>{t('strong')}</td>
                          <td style={{ fontWeight: 500 }}>{bd.trunkStrongVines + `(${bd.trunkStrongVines}%)`}</td>
                        </tr>
                      )}
                      {bd.trunkWeakVines && (
                        <tr>
                          <td style={{ color: '#57534E' }}>{t('weak')}</td>
                          <td>{bd.trunkWeakVines + `(${bd.trunkWeakVinesPct}%)`}</td>
                        </tr>
                      )}
                    </tbody>
                  ) : (
                    <tbody>
                      {pmTotalInfectedVines ? (
                        <tr>
                          <td style={{ color: '#57534E' }}>MW</td>
                          <td style={{ fontWeight: 500 }}>{pmTotalInfectedVines}</td>
                        </tr>
                      ) : null}
                      {bbrInfectedVines > 0 ? (
                        <tr>
                          <td style={{ color: '#57534E' }}>BT</td>
                          <td style={{ fontWeight: 500 }}>{bbrInfectedVines}</td>
                        </tr>
                      ) : null}
                    </tbody>
                  )}
                </table>
                {isTrunkDiseaseSelected
                  ? totalTrunkPct > 0 && (
                      <div
                        style={{
                          height: bubble(totalTrunkPct).size,
                          backgroundColor: bubble(totalTrunkPct).color,
                          width: bubble(totalTrunkPct).size,
                          borderRadius: '50%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          flexDirection: 'column',
                          fontSize: 13,
                          fontWeight: 500,
                          margin: '6px 0px 0px 0px',
                          lineHeight: 1.1,
                          color: '#404040',
                        }}
                      >
                        <span>{totalTrunk}</span>
                        <span style={{ color: '#57534E', fontWeight: 500 }}>{totalTrunkPct}%</span>
                      </div>
                    )
                  : sumV > 0 && (
                      <div
                        style={{
                          height: bubble(sumV).size,
                          backgroundColor: bubble(sumV).color,
                          width: bubble(sumV).size,
                          borderRadius: '50%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          fontSize: 13,
                          fontWeight: 500,
                          margin: '6px 0px 0px 0px',
                        }}
                      >
                        {sumV}
                      </div>
                    )}
              </div>
            </div>
          </div>
        )
      })}
    </CustomTableWrapper>
  )
}

export default HealthTable
