import { forwardRef, ReactNode } from 'react'

type CustomTableWrapperProps = {
  blockName: string
  vineyardName: string
  children: ReactNode
}

const CustomTableWrapper = forwardRef<HTMLDivElement, CustomTableWrapperProps>(({ blockName, vineyardName, children }, ref) => {
  return (
    <div ref={ref} style={{ overflow: 'hidden' }}>
      <div style={{ position: 'relative' }}>
        <label
          style={{
            fontWeight: 500,
            // background: 'linear-gradient(90deg, #737373 0%, #A3A3A3 100%)',
            backgroundColor: '#737373',
            color: 'white',
            padding: '1px 8px 0px 12px',
            borderRadius: '0px 3px 3px 0px',
            display: 'flex',
            alignItems: 'center',
            width: 'fit-content',
          }}
        >
          {blockName}{' '}
          <span
            style={{
              fontSize: 13,
              color: '#E5E5E5',
              margin: '0px 4px 0px 30px',
              fontWeight: 400,
            }}
          >
            &mdash; {vineyardName}
          </span>
        </label>
        <div
          style={{
            position: 'absolute',
            zIndex: 0,
            width: 3,
            height: 240,
            //vertical gradient
            background: 'linear-gradient(180deg, rgba(115, 115, 115, 1) 50%, rgba(115, 115, 115, 0.3) 100%)',
            top: 2,
            boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',
          }}
        ></div>
      </div>
      <div
        style={{
          display: 'flex',
          gap: 20,
          overflowX: 'auto',
          maxWidth: 'fit-content',
          borderRadius: 4,
        }}
      >
        {children}
      </div>
    </div>
  )
})

export default CustomTableWrapper
