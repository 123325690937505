import Iconify from '@components/data-display/Iconify'
import { IconButton } from '@mui/material'
import Box from '@mui/material/Box'
import { blue, grey } from '@mui/material/colors'
import { PostMaterialEnum, VineStateIdEnum } from '@typings/dtos/block-report/enums'
import { copyToClipboard, openGoogleMapsLink } from '@utils/utility-fns'
import { format, parseISO } from 'date-fns'
import { t } from 'i18next'
import { enqueueSnackbar } from 'notistack'

export type VineAndPostInfoPopupProps = {
  id: number
  blockName: string
  rowNum: number
  coordinates: [number, number]
  scanDate: string
  markerType: string
  onClose?: () => void
}

const VineAndPostInfoPopup = ({
  id,
  blockName,
  rowNum,
  coordinates,
  scanDate,
  markerType,
  onClose,
}: VineAndPostInfoPopupProps) => {
  const label = {
    Young: `vine_${VineStateIdEnum.Young}`,
    Missing: `vine_${VineStateIdEnum.Missing}`,
    Dead: `vine_${VineStateIdEnum.Dead}`,
    'Dead / 0 Cane': 'cane_0',
    '1 Cane': 'cane_1',
    '2 Cane': 'cane_2',
    '3 Cane': 'cane_3',
    '1 Cordon': 'cordon_1',
    'Dead / 0 Cordon': 'cordon_0',
    Metal: `post_${PostMaterialEnum.Metal}`,
    Plastic: `post_${PostMaterialEnum.Plastic}`,
    Unknown: `post_${PostMaterialEnum.Unknown}`,
    Wood: `post_${PostMaterialEnum.Wood}`,
  }

  return (
    <div
      id={id.toString()}
      style={{ position: 'absolute', top: 105, left: 6, zIndex: 10, padding: '1rem', backgroundColor: '#FFF' }}
    >
      <div style={{ position: 'relative' }}>
        <Box display="flex" bgcolor="grey.100" px={1} py={0.5} alignItems="center" height={36}>
          <Box width={130} fontSize={13} fontWeight={500}>
            {t('block')}
          </Box>
          <Box fontSize={15}>{blockName}</Box>
        </Box>
        <Box display="flex" bgcolor={blue[50]} px={1} py={0.5} alignItems="center" height={36}>
          <Box width={130} fontSize={13} fontWeight={500}>
            {t('row')}
          </Box>
          <Box fontSize={15}>{rowNum}</Box>
        </Box>

        <div style={{ display: 'flex', alignItems: 'center', backgroundColor: grey[100], padding: '4px 8px', height: 36 }}>
          <div style={{ width: 130, fontSize: 13, fontWeight: 500 }}>
            {markerType.startsWith('post') ? 'Post Material' : 'Vine Status'}
          </div>
          <div style={{ fontSize: 15 }}>{Object.entries(label).find(([_, value]) => value === markerType)?.[0]}</div>
        </div>
        <Box display="flex" bgcolor={blue[50]} px={1} py={0.5} alignItems="center" height={36}>
          <Box width={130} fontSize={13} fontWeight={500}>
            {t('coordinates')}
          </Box>
          <Box
            component="button"
            fontSize={12}
            fontWeight={600}
            display="flex"
            alignItems="center"
            color="grey.700"
            bgcolor="white"
            border="1px solid"
            borderColor={'grey.400'}
            borderRadius={1}
            p={0.5}
            pr={0.8}
            sx={{ cursor: 'pointer', ':active': { backgroundColor: 'primary.light' } }}
            onClick={() =>
              copyToClipboard(coordinates.join(',')).then(() => {
                enqueueSnackbar('Coordinates copied to clipboard', { variant: 'success', autoHideDuration: 2000 })
              })
            }
          >
            <Iconify icon="material-symbols:my-location" color="grey" width={16} mr={0.4} /> Copy
          </Box>
        </Box>
        <Box display="flex" bgcolor={grey[100]} px={1} py={0.5} alignItems="center" height={36}>
          <Box width={130} fontSize={13} fontWeight={500}>
            {t('location')}
          </Box>
          <Box display="flex" gap={1}>
            <Box
              component="button"
              fontSize={12}
              fontWeight={600}
              display="flex"
              alignItems="center"
              bgcolor="white"
              border="1px solid"
              borderColor="grey.400"
              borderRadius={1}
              p={0.5}
              pr={0.7}
              color="grey.700"
              sx={{ cursor: 'pointer', ':active': { backgroundColor: 'primary.light' } }}
              onClick={() => openGoogleMapsLink(coordinates[1], coordinates[0])}
            >
              <Iconify icon="flat-color-icons:google" color="grey" width={16} mr={0.3} /> Maps
            </Box>
            <Box
              component="button"
              fontSize={12}
              fontWeight={600}
              display="flex"
              alignItems="center"
              color="grey.700"
              bgcolor="white"
              border="1px solid"
              borderColor="grey.400"
              borderRadius={1}
              p={0.5}
              pr={0.8}
              sx={{ cursor: 'pointer', ':active': { backgroundColor: 'primary.light' } }}
              onClick={() =>
                copyToClipboard(`https://www.google.com/maps/search/?api=1&query=${coordinates[1]},${coordinates[0]}`).then(
                  () => {
                    enqueueSnackbar('Google maps link copied to clipboard', { variant: 'success', autoHideDuration: 2000 })
                  }
                )
              }
            >
              <Iconify icon="octicon:link-16" color="grey" width={16} mr={0.3} /> Copy
            </Box>
          </Box>
        </Box>
        <Box display="flex" bgcolor={blue[50]} px={1} py={0.5} alignItems="center" height={36}>
          <Box width={130} fontSize={13} fontWeight={500}>
            {t('scan_date')}
          </Box>
          <Box fontSize={13} fontWeight={500} color="grey.700">
            {scanDate && format(parseISO(scanDate), 'd MMM yyyy - h:mma')}
          </Box>
        </Box>
      </div>

      <IconButton
        onClick={onClose}
        title="Close"
        size="small"
        sx={{
          position: 'absolute',
          top: -7,
          right: -7,
          bgcolor: '#fff',
          border: '1px solid',
          borderColor: 'grey.200',
          ':hover': { bgcolor: 'grey.200' },
        }}
      >
        <Iconify icon="material-symbols:close" width={20} />
      </IconButton>
    </div>
  )
}

export default VineAndPostInfoPopup
