import PruningStyleOptionsWrapper from '@components/containers/PruningStyleOptionsWrapper'
import SummaryPageWrapper from '@components/containers/SummaryPageWrapper'
import LandBowSection from '@components/sections/land-summary/LandBowSection'
import LandCaneSection from '@components/sections/land-summary/LandCaneSection'
import LandSpurSection from '@components/sections/land-summary/LandSpurSection'
import { useGetBlockReportsByPruningStyle } from '@hooks/useGetBlockReportsByPruningStyle'
import useVineyardStore from '@stores/vineyard'
import { ReportTypeEnum } from '@typings/dtos/block-report/enums'

const LandSummaryPage = () => {
  const [activePruningStyle] = useVineyardStore((s) => [s.activePruningStyle])

  const condition = {
    cane: activePruningStyle === 'cane',
    spur: activePruningStyle === 'spur',
    bow: activePruningStyle === 'bow',
  }

  const { data, count, isFetching } = useGetBlockReportsByPruningStyle({ reportType: ReportTypeEnum.Land })

  return (
    <>
      <PruningStyleOptionsWrapper blocksCountByPruningStyle={count} sx={{ pb: 2 }} />
      {condition.cane && (
        <SummaryPageWrapper dataLength={data.cane.length} isLoading={isFetching}>
          <LandCaneSection data={data.cane} />
        </SummaryPageWrapper>
      )}
      {condition.spur && (
        <SummaryPageWrapper dataLength={data.spur.length} isLoading={isFetching}>
          <LandSpurSection data={data.spur} />
        </SummaryPageWrapper>
      )}
      {condition.bow && (
        <SummaryPageWrapper dataLength={data.bow.length} isLoading={isFetching}>
          <LandBowSection data={data.bow} />
        </SummaryPageWrapper>
      )}
    </>
  )
}

export default LandSummaryPage
