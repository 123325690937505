import { BlockReportApi } from '@services/api'
import useVineyardStore from '@stores/vineyard'
import { useQuery } from '@tanstack/react-query'
import { PruningStyleUnion } from '@typings/dtos/block'
import { ReportLevelsEnum, ReportTypeEnum } from '@typings/dtos/block-report/enums'

import { BlockReport } from '@typings/dtos/block-report/models'
import { numberOfUniqueBlocks } from '@utils/utility-fns'
import { useEffect, useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { useHandleAxiosError } from './useHandleAxiosError'

export function useGetBlockReportsByPruningStyle({
  reportType,
  reportLevels = [ReportLevelsEnum.Block],
}: {
  reportType: ReportTypeEnum
  reportLevels?: ReportLevelsEnum[]
}) {
  const [data, setData] = useState<Record<PruningStyleUnion, BlockReport[]>>({ cane: [], bow: [], spur: [] })
  const [count, setCount] = useState<Record<PruningStyleUnion, number>>({ cane: 0, bow: 0, spur: 0 })

  const [selectedBlocks, startDate, endDate, bowPrunedBlockIds, canePrunedBlockIds, spurPrunedBlockIds, blocksAsMap] =
    useVineyardStore(
      useShallow((s) => [
        s.selectedBlocks,
        s.startDate,
        s.endDate,
        s.bowPrunedBlockIds,
        s.canePrunedBlockIds,
        s.spurPrunedBlockIds,
        s.blocksAsMap,
      ])
    )

  const handleAxiosError = useHandleAxiosError()

  const queryKey =
    Object.keys(ReportTypeEnum).find((key: string) => ReportTypeEnum[key as keyof typeof ReportTypeEnum] === reportType) +
    'Summary'

  const {
    data: res,
    refetch,
    isFetching,
    dataUpdatedAt,
    error,
  } = useQuery({
    queryKey: [queryKey],
    initialData: [],
    queryFn: () =>
      BlockReportApi.get({
        reportType,
        reportLevels,
        blockIds: selectedBlocks.map((block) => block.id),
        startDate,
        endDate,
      }),
    enabled: false,
    select: (data) => {
      return data.map((report) => {
        const b = blocksAsMap.get(report.blockId)
        if (!b) return report
        return { ...report, blockName: b.name, rowStart: b.rowStart, rowEnd: b.rowEnd }
      })
    },
  })

  useEffect(() => {
    if (error) handleAxiosError(error)
  }, [error, handleAxiosError])

  useEffect(() => {
    if (!selectedBlocks.length) return
    refetch()
  }, [selectedBlocks, startDate, endDate, refetch])

  useEffect(() => {
    const filterDataByPruningStyle = (pruningStyle: PruningStyleUnion) => {
      const blockIds = { bow: bowPrunedBlockIds, cane: canePrunedBlockIds, spur: spurPrunedBlockIds }[pruningStyle]

      return res.filter((row) => blockIds.includes(row.blockId)) || []
    }

    if (res) {
      const caneData = filterDataByPruningStyle('cane')
      const bowData = filterDataByPruningStyle('bow')
      const spurData = filterDataByPruningStyle('spur')

      setData({ cane: caneData, bow: bowData, spur: spurData })
      setCount({ cane: numberOfUniqueBlocks(caneData), bow: numberOfUniqueBlocks(bowData), spur: numberOfUniqueBlocks(spurData) })
    }
  }, [res, bowPrunedBlockIds, canePrunedBlockIds, spurPrunedBlockIds])

  return { data, count, isFetching, dataUpdatedAt }
}
