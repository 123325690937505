import Iconify from '@components/data-display/Iconify'
import NavbarMenu from '@components/navigation/NavbarMenu'
import { useTranslate } from '@hooks/useLocales'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '@mui/material/Tooltip'
import { useTheme } from '@mui/material/styles'
import * as Sentry from '@sentry/react'
import useAuthStore from '@stores/auth'
import { healthNavLinks, landNavLinks, pruningNavLinks, yieldNavLinks } from '@utils/component-utils'
import { useEffect } from 'react'
import AccountMenu from './AccountMenu'

type HeaderProps = {
  onClickSettings: VoidFunction
  onClickTimelineIcon: VoidFunction
}

const Header = ({ onClickSettings, onClickTimelineIcon }: HeaderProps) => {
  const { t } = useTranslate()
  const [user] = useAuthStore((s) => [s.user])
  const { palette } = useTheme()

  const feedback = Sentry.feedbackIntegration({
    colorScheme: 'light',
    autoInject: false,
    showBranding: false,
    showName: false,
    showEmail: false,
    themeLight: { accentBackground: palette.primary.main },
  })

  const onClickReportABug = async () => {
    const element = document.querySelector('#report-a-bug-btn')
    if (!element) return

    Sentry.setUser({ fullName: user?.name, email: user?.email })
    const form = await feedback.createForm()
    form.appendToDom()
    form.open()
  }

  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.addedNodes.length) {
          mutation.addedNodes.forEach((node) => {
            if (node instanceof Element && node.shadowRoot) {
              const host = node.shadowRoot.host as HTMLElement
              host.style.setProperty('--inset', '40px 0 auto auto')
            }
          })
        }
      })
    })

    // Observe changes to the document
    observer.observe(document.body, { childList: true, subtree: true })

    return () => observer.disconnect() // Cleanup observer on component unmount
  }, [])

  return (
    <>
      <AppBar component="nav">
        <Toolbar variant="dense">
          <Box display="flex" gap={3}>
            <Box component="img" alt="Cropsy" src="/logos/cropsy-light.svg" height={28} mr={2} />
            <NavbarMenu
              id="health-nav"
              label={t('health')}
              baseSlug="health"
              links={healthNavLinks}
              menuArrowPositionRight={86}
            />
            <NavbarMenu id="yield-nav" label={t('yield')} baseSlug="yield" links={yieldNavLinks} />
            <NavbarMenu
              id="pruning-nav"
              label={t('pruning')}
              baseSlug="pruning"
              links={pruningNavLinks}
              menuArrowPositionRight={90}
            />
            <NavbarMenu id="land-nav" label={t('land')} baseSlug="land" links={landNavLinks} menuArrowPositionRight={90} />
          </Box>
          <Stack direction="row" flexGrow={1} spacing={3} alignItems="center" justifyContent="flex-end">
            <Tooltip title={t('scan_timeline')} enterDelay={300}>
              <IconButton aria-label="scan timeline" onClick={onClickTimelineIcon}>
                <Iconify icon="mdi:timeline-check" width={29} color="white" />
              </IconButton>
            </Tooltip>

            <Tooltip title={t('customize')} enterDelay={300}>
              <IconButton aria-label="customize" onClick={onClickSettings}>
                <Iconify icon="teenyicons:toggle-solid" width={28} color="white" padding="3px" />
              </IconButton>
            </Tooltip>

            <Tooltip title={t('report_a_bug')} enterDelay={300}>
              <IconButton aria-label="report a bug" onClick={onClickReportABug} id="report-a-bug-btn">
                <Iconify icon="ph:bug-fill" width={28} color="white" />
              </IconButton>
            </Tooltip>

            <AccountMenu />
          </Stack>
        </Toolbar>
      </AppBar>
      {/* The app bar position fixed (default) hides the top content behind the appbar. Adding an empty toolbar fixes it*/}
      <Toolbar variant="dense" />
    </>
  )
}

export default Header
