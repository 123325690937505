import CustomGridToolbar from '@components/datagrid/CustomGridToolbar'
import Paper from '@mui/material/Paper'

import PruningStyleOptionsWrapper from '@components/containers/PruningStyleOptionsWrapper'
import DatagridNoRowsOverlay from '@components/data-display/DatagridNoRowsOverlay'
import useDataGridLocaleText from '@hooks/useDataGridLocaleText'
import { useGetRowBlockReportsApi } from '@hooks/useGetRowBlockReportsApi'
import { DataGridPro } from '@mui/x-data-grid-pro'
import useVineyardStore from '@stores/vineyard'
import { PruningStyleUnion } from '@typings/dtos/block'
import { ReportTypeEnum } from '@typings/dtos/block-report/enums'
import { RowData } from '@typings/dtos/block-report/models'
import { pinnedColumns } from '@utils/constants'
import { pruningCols, pruningColsGrouping } from '@utils/datagrid-cols/pruning-cols'
import { numberOfUniqueBlocks } from '@utils/utility-fns'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'

const PruningReportingPage = () => {
  const [bowData, setBowData] = useState<RowData[]>([])
  const [caneData, setCaneData] = useState<RowData[]>([])
  const [spurData, setSpurData] = useState<RowData[]>([])
  const [count, setCount] = useState<Record<PruningStyleUnion, number>>({ bow: 0, cane: 0, spur: 0 })

  const [bowPrunedBlockIds, canePrunedBlockIds, spurPrunedBlockIds, activePruningStyle] = useVineyardStore((s) => [
    s.bowPrunedBlockIds,
    s.canePrunedBlockIds,
    s.spurPrunedBlockIds,
    s.activePruningStyle,
  ])

  const { data, isFetching } = useGetRowBlockReportsApi(ReportTypeEnum.Pruning)
  const { localeText } = useDataGridLocaleText()

  //TODO: Currently this logic is repeated inside summary and reporting pages,
  // Needs to be refactored to a single place
  useEffect(() => {
    const filterDataByPruningStyle = (pruningStyle: PruningStyleUnion) => {
      const blockIds = {
        bow: bowPrunedBlockIds,
        cane: canePrunedBlockIds,
        spur: spurPrunedBlockIds,
      }[pruningStyle]

      return data?.filter((row) => blockIds.includes(row.blockId)) || []
    }

    if (data) {
      const bowData = filterDataByPruningStyle('bow')
      const caneData = filterDataByPruningStyle('cane')
      const spurData = filterDataByPruningStyle('spur')

      setBowData(bowData)
      setCaneData(caneData)
      setSpurData(spurData)

      setCount({
        bow: numberOfUniqueBlocks(bowData),
        cane: numberOfUniqueBlocks(caneData),
        spur: numberOfUniqueBlocks(spurData),
      })
    }
  }, [data, bowPrunedBlockIds, canePrunedBlockIds, spurPrunedBlockIds])

  const dataByPruningStyle = { bow: bowData, cane: caneData, spur: spurData }

  return (
    <>
      <PruningStyleOptionsWrapper blocksCountByPruningStyle={count} sx={{ pb: 2 }} />
      <Paper sx={{ borderRadius: 1, height: 'calc(100vh - 140px)', overflow: 'hidden' }} variant="outlined">
        <DataGridPro
          rows={dataByPruningStyle[activePruningStyle] || []}
          columns={pruningCols(activePruningStyle)}
          columnGroupingModel={pruningColsGrouping()}
          loading={isFetching}
          initialState={pinnedColumns}
          getRowId={(r) => r.id + r.blockId.toString() + r.rowId.toString()}
          columnHeaderHeight={40}
          disableColumnMenu
          hideFooter
          slots={{
            noRowsOverlay: () => <DatagridNoRowsOverlay data={data} />,
            toolbar: () => (
              <CustomGridToolbar
                fileName={`Pruning Report - ${format(new Date(), 'dd MMM yyyy')}`}
                rowCount={dataByPruningStyle[activePruningStyle].length}
              />
            ),
          }}
          // slotProps={{
          //   toolbar: {
          //     fileName: `Pruning Report - ${format(new Date(), 'dd MMM yyyy')}`,
          //     rowCount: dataByPruningStyle[activePruningStyle].length,
          //     dataGridStats: activePruningStyle === 'Bow' ? undefined : dataGridStats,
          //     onClickDataGridStats: () => setDataGridStats(dataGridStats === 'standard' ? 'expert' : 'standard'),
          //   },
          // }}
          localeText={localeText}
        />
      </Paper>
    </>
  )
}

export default PruningReportingPage
