import Box from '@mui/material/Box'

const Playground = () => {
  return (
    <Box border="2px dashed grey" maxWidth={800} height="90dvh" m="auto" p={2}>
      Playground
      <Box py={6} />
    </Box>
  )
}

export default Playground
