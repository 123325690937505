import { BlockReport } from '@typings/dtos/block-report/models'
import { createLandVineStatsDataset } from '@utils/utility-fns'
import { Dispatch, SetStateAction, useCallback, useEffect } from 'react'
import { LandDataset } from './useSortLandDataset'

export function useSetStateLandVineDataset(setState: Dispatch<SetStateAction<LandDataset>>, data: BlockReport[]) {
  const setStates = useCallback(() => {
    setState((prev) => ({ ...prev, source: createLandVineStatsDataset(data) }))
  }, [data, setState])

  useEffect(() => {
    setStates()
  }, [data, setStates])
}
