import SummaryTileWrapper from '@components/containers/SummaryTileWrapper'
import SortBlocksMenu from '@components/echarts/SortBlocksMenu'
import StackedHorizontalBar from '@components/echarts/charts/StackedHorizontalBar'
import { useSetStateLandVineDataset } from '@hooks/land/useSetStateLandVineDataset'
import { LandDataset, useSortLandDataset } from '@hooks/land/useSortLandDataset'
import { useTranslate } from '@hooks/useLocales'
import Box from '@mui/material/Box'
import { ColorPost } from '@typings/common'
import { BlockReport } from '@typings/dtos/block-report/models'
import { generateBarcodeSVG, labelFormatter_Land } from '@utils/echarts'
import { LandCane_PostsSortOptions } from '@utils/options'
import { formatISO_ } from '@utils/utility-fns'
import { TopLevelFormatterParams } from 'echarts/types/dist/shared.js'
import { TFunction } from 'i18next'
import { useState } from 'react'

type PostsStackedBarsChartProps = {
  data: BlockReport[]
  color: ColorPost
}

const PostsStackedBarsChart = ({ data, color }: PostsStackedBarsChartProps) => {
  const [activeSort, setActiveSort] = useState('')
  const [postsDataset, setPostsDataset] = useState<LandDataset>({
    id: 'land-posts-dataset',
    dimensions: ['blockName', 'wooden', 'metal', 'missing', 'unknown'],
    source: [],
  })

  const { t } = useTranslate()

  useSetStateLandVineDataset(setPostsDataset, data)

  useSortLandDataset(setPostsDataset, activeSort)

  return (
    <Box pb={4}>
      <SummaryTileWrapper
        label="posts"
        width="100%"
        height="100%"
        tooltipMsg="land_page.summary.cane.tooltip_posts_per_block_barchart"
        headerComponent={
          postsDataset.source.length > 1 && (
            <SortBlocksMenu
              id="sort-posts-stats"
              menuItems={LandCane_PostsSortOptions}
              value={activeSort}
              onClick={(val) => setActiveSort(val)}
              menuArrowPositionRight={110}
            />
          )
        }
      >
        <Box py={4}>
          <StackedHorizontalBar
            dataset={postsDataset}
            legend={{ show: true, formatter: (name) => t(name) }}
            color={[color.wood, color.metal, color.missing, color.unknown]}
            hideLabelValueBelow={0.1}
            formatLabelToPct={false}
            tooltipFormatter={(p) => tooltipFormatter(p, color, t)}
            yAxisCategoryLabelFormatter={labelFormatter_Land}
          />
        </Box>
      </SummaryTileWrapper>
    </Box>
  )
}

export default PostsStackedBarsChart

const tooltipFormatter = (params: TopLevelFormatterParams, color: ColorPost, t: TFunction) => {
  // @ts-expect-error - TS doesn't know that params is an object
  const { name, value, data } = params[0]
  const { rowStart, rowEnd, rowsScanned } = data

  const dateString = formatISO_(name.split('>')[1], 'dd MMM yyyy')
  const valueWithoutDateString = name.split('>')[0] || name

  let result = `<div style="margin-bottom: 6px; width: 200px">
                    <div style="text-align: left; font-weight: 600; font-size: 14px; line-height: 1.2">${valueWithoutDateString}</div>
                    <div style="text-align: left; font-size: 14px; line-height: 1.2">${dateString}</div>
                </div>`

  const barcodeMarkup = generateBarcodeSVG(rowStart, rowEnd, rowsScanned)
  result += `<div style="margin-bottom: 12px;">${barcodeMarkup}</div>`

  const posts = [
    { key: 'wooden', value: value.wooden, bgcolor: color.wood, text: 'white' },
    { key: 'metal', value: value.metal, bgcolor: color.metal, text: 'white' },
    { key: 'missing', value: value.missing, bgcolor: color.missing, text: 'white' },
    { key: 'unknown', value: value.unknown, bgcolor: color.unknown, text: 'white' },
  ]

  posts.forEach((post) => {
    if (post.value !== 0) {
      result += `<div style="display: flex; color: ${post.text}; justify-content: space-between; gap: 2; background-color:${post.bgcolor}">
            <b style="width: 60px; padding:6px 0 6px 8px;">${t(post.key)}:</b>
            <b style="padding:6px 8px 6px 8px">${post.value}</b>
          </div>`
    }
  })

  return result
}
