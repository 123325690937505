import CustomMarksRangeSlider, { CustomMarksRangeSliderProps } from '@components/form/CustomMarksRangeSlider'
import Box from '@mui/material/Box'
import { useMemo, useState } from 'react'
import LandReportCounts from './LandReportCounts'

type LandCustomMarksRangeSliderProps = Omit<CustomMarksRangeSliderProps, 'header' | 'activeReportId'>

const LandCustomMarksRangeSlider = ({
  blockName,
  reports,
  targetDate,
  customOnChange,
  sDate,
  eDate,
}: LandCustomMarksRangeSliderProps) => {
  const [activeClusterId, setActiveClusterId] = useState<string>()

  const activeCluster = useMemo(() => {
    return reports.find((scan) => scan.id === activeClusterId)
  }, [reports, activeClusterId])

  if (!reports || reports.length === 0) return null

  return (
    <CustomMarksRangeSlider
      blockName={blockName}
      reports={reports}
      targetDate={targetDate}
      sDate={sDate}
      eDate={eDate}
      header={<Box display="flex">{activeCluster ? <LandReportCounts blockData={activeCluster.blockData} /> : null}</Box>}
      customOnChange={(item) => {
        setActiveClusterId(item.clusterId)
        customOnChange(item)
      }}
      activeReportId={(id) => setActiveClusterId(id)}
    />
  )
}

export default LandCustomMarksRangeSlider
