import { colorBlind, standard } from '@services/theme/palette'
import { NavLinkItem, SettingToggleIconItem } from '@typings/component'

export const healthNavLinks: NavLinkItem[] = [
  {
    name: 'map_&_evidence',
    path: '/map',
    icon: 'material-symbols:map',
  },
  {
    name: 'summary',
    path: '/summary',
    icon: 'dashicons:analytics',
  },
  {
    name: 'reporting',
    path: '/reporting',
    icon: 'material-symbols:assignment-outline',
  },
]

export const yieldNavLinks: NavLinkItem[] = [
  {
    name: 'summary',
    path: '/summary',
    icon: 'dashicons:analytics',
  },
  {
    name: 'reporting',
    path: '/reporting',
    icon: 'material-symbols:assignment-outline',
  },
]

export const pruningNavLinks: NavLinkItem[] = [
  {
    name: 'map_&_evidence',
    path: '/map',
    icon: 'material-symbols:map',
  },
  {
    name: 'summary',
    path: '/summary',
    icon: 'dashicons:analytics',
  },
  {
    name: 'reporting',
    path: '/reporting',
    icon: 'material-symbols:assignment-outline',
  },
]

export const landNavLinks: NavLinkItem[] = [
  {
    name: 'map_&_evidence',
    path: '/map',
    icon: 'material-symbols:map',
  },
  {
    name: 'summary',
    path: '/summary',
    icon: 'dashicons:analytics',
  },
  {
    name: 'reporting',
    path: '/reporting',
    icon: 'material-symbols:assignment-outline',
  },
]

export const diseaseFilterToggles = [
  { symbol: 'MW', label: 'powdery_mildew', value: 'pm' },
  { symbol: 'BT', label: 'botrytis', value: 'bt' },
  { symbol: 'TD', label: 'trunk_disease', value: 'td' },
] as const

export type DiseaseFilterToggleKey = (typeof diseaseFilterToggles)[number]['value']

export const themeOptions: SettingToggleIconItem[] = [
  {
    label: 'Standard',
    value: 'standard',
    colors: standard.range,
  },
  {
    label: 'Color Blind',
    value: 'color-blind',
    colors: colorBlind.range,
  },
]
