export enum BillingStatus {
  Active = 1,
  Settled = 2,
  Overdue = 3,
}

export enum BillableReportType {
  SUMMER = 'Summer',
  WINTER = 'Winter',
}

export enum Currency {
  NZD = 'NZD',
  AUD = 'AUD',
  USD = 'USD',
  EUR = 'EUR',
}

export enum TaxType {
  ZeroRated = 'ZeroRated',
  GST = 'GST',
}
