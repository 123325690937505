import { GridColDef, GridColumnGroupingModel } from '@mui/x-data-grid-pro'
import { PruningStyleUnion } from '@typings/dtos/block'
import { createFormatter, formatISO_, formatToSingleDecimalIfNecessaryOrZero } from '@utils/utility-fns'
import { t } from 'i18next'

export const pruningCols = (pruningStyle: PruningStyleUnion): GridColDef[] => {
  const sharedCols: GridColDef[] = [
    {
      field: 'scanDate',
      headerName: 'Date',
      valueFormatter: (value) => formatISO_(value, 'd MMM yyyy'),
      type: 'date',
      width: 110,
    },
    { field: 'vineyardName', headerName: t('vineyard'), width: 150, valueFormatter: createFormatter() },
    { field: 'blockName', headerName: t('block'), width: 150, valueFormatter: createFormatter() },
    { field: 'rowNum', headerName: t('row'), width: 70 },
  ]

  const bowCols: GridColDef[] = [
    {
      field: 'vNormal',
      headerName: t('normal'),
      width: 110,
      valueFormatter: createFormatter(),
    },
    {
      field: 'vYoung',
      headerName: t('young'),
      width: 110,
      valueFormatter: createFormatter(),
    },
    {
      field: 'vMissing',
      headerName: t('missing'),
      width: 110,
      valueFormatter: createFormatter(),
    },
    {
      field: 'vUnknown',
      headerName: t('unknown'),
      width: 110,
      valueFormatter: createFormatter(),
    },
    {
      field: 'vBadQuality',
      headerName: t('low_quality'),
      width: 110,
      valueFormatter: createFormatter(),
    },
  ]

  const spurCols: GridColDef[] = [
    {
      field: 'missingCordon1',
      headerName: '1',
      width: 100,
      valueFormatter: createFormatter(),
    },
    {
      field: 'missingCordon2',
      headerName: '2',
      width: 100,
      valueFormatter: createFormatter(),
    },
    // {
    //   field: 'budsPerVineLQ',
    //   headerName: t('lower_quartile'),
    //   width: 120,
    //   valueFormatter: createFormatter((v) => formatToSingleDecimalIfNecessary(v)),
    // },
    // {
    //   field: 'budsPerVineUQ',
    //   headerName: t('upper_quartile'),
    //   width: 120,
    //   valueFormatter: createFormatter((v) => formatToSingleDecimalIfNecessary(v)),
    // },
    {
      field: 'budsPerVineMean',
      headerName: t('per_vine_(mean)'),
      width: 130,
      valueFormatter: createFormatter((v) => formatToSingleDecimalIfNecessaryOrZero(v)),
    },
    // {
    //   field: 'budsPerVineMedian',
    //   headerName: t('median'),
    //   width: 80,
    //   valueFormatter: createFormatter((v) => formatToSingleDecimalIfNecessary(v)),
    // },
    // {
    //   field: 'budsPerSpurPerVineLQ',
    //   headerName: t('lower_quartile'),
    //   width: 120,
    //   valueFormatter: createFormatter((v) => formatToSingleDecimalIfNecessary(v)),
    // },
    // {
    //   field: 'budsPerSpurPerVineUQ',
    //   headerName: t('upper_quartile'),
    //   width: 120,
    //   valueFormatter: createFormatter((v) => formatToSingleDecimalIfNecessary(v)),
    // },
    {
      field: 'budsPerSpurPerVineMean',
      headerName: t('per_spur_per_vine_(mean)'),
      width: 190,
      valueFormatter: createFormatter((v) => formatToSingleDecimalIfNecessaryOrZero(v)),
    },
    // {
    //   field: 'budsPerSpurPerVineMedian',
    //   headerName: t('median'),
    //   width: 80,
    //   valueFormatter: createFormatter((v) => formatToSingleDecimalIfNecessary(v)),
    // },
    // {
    //   field: 'spursPerVineLQ',
    //   headerName: t('lower_quartile'),
    //   width: 120,
    //   valueFormatter: createFormatter((v) => formatToSingleDecimalIfNecessary(v)),
    // },
    // {
    //   field: 'spursPerVineUQ',
    //   headerName: t('upper_quartile'),
    //   width: 120,
    //   valueFormatter: createFormatter((v) => formatToSingleDecimalIfNecessary(v)),
    // },
    {
      field: 'spursPerVineMean',
      headerName: t('per_vine_(mean)'),
      width: 140,
      valueFormatter: createFormatter((v) => formatToSingleDecimalIfNecessaryOrZero(v)),
    },
    // {
    //   field: 'spursPerVineMedian',
    //   headerName: t('median'),
    //   width: 80,
    //   valueFormatter: createFormatter((v) => formatToSingleDecimalIfNecessary(v)),
    // },
  ]

  const caneCols: GridColDef[] = [
    {
      field: 'vDead',
      headerName: `0 ${t('canes')}/${t('dead')}`,
      width: 130,
      valueFormatter: createFormatter(),
    },
    {
      field: 'productivePct',
      headerName: t('productive_%'),
      width: 130,
      valueFormatter: createFormatter((v) => formatToSingleDecimalIfNecessaryOrZero(v)),
    },
    {
      field: 'prunedToTargetPct',
      headerName: t('pruned_to_target_%'),
      width: 160,
      valueFormatter: createFormatter((v) => formatToSingleDecimalIfNecessaryOrZero(v)),
    },
    {
      field: 'notPrunedToTargetPct',
      headerName: t('not_pruned_to_target_%'),
      width: 170,
      valueGetter: (_, row) => 100 - row.prunedToTargetPct,
      valueFormatter: createFormatter((v) => formatToSingleDecimalIfNecessaryOrZero(v)),
    },
    {
      field: 'caneTarget',
      headerName: t('assumed_cane_target'),
      width: 220,
      valueFormatter: createFormatter(),
    },
    {
      field: 'cane1',
      headerName: t('1_cane'),
      width: 90,
      valueFormatter: createFormatter(),
    },
    {
      field: 'cane2',
      headerName: t('2_cane'),
      width: 100,
      valueFormatter: createFormatter(),
    },
    {
      field: 'cane3',
      headerName: t('3_cane'),
      width: 100,
      valueFormatter: createFormatter(),
    },
    {
      field: 'cane4',
      headerName: t('4_cane'),
      width: 100,
      valueFormatter: createFormatter(),
    },
    // {
    //   field: 'budsPerVineLQ',
    //   headerName: t('per_vine_(lower_quartile)'),
    //   width: 120,
    //   valueFormatter: createFormatter((v) => formatToSingleDecimalIfNecessary(v)),
    // },
    // {
    //   field: 'budsPerVineUQ',
    //   headerName: t('per_vine_(upper_quartile)'),
    //   width: 120,
    //   valueFormatter: createFormatter((v) => formatToSingleDecimalIfNecessary(v)),
    // },
    {
      field: 'budsPerVineMean',
      headerName: t('per_vine_(mean)'),
      width: 140,
      valueFormatter: createFormatter((v) => formatToSingleDecimalIfNecessaryOrZero(v)),
    },
    // {
    //   field: 'budsPerVineMedian',
    //   headerName: t('per_vine_(median)'),
    //   width: 80,
    //   valueFormatter: createFormatter((v) => formatToSingleDecimalIfNecessary(v)),
    // },
    // {
    //   field: 'budsPerCaneLQ',
    //   headerName: t('per_cane_(lower_quartile)'),
    //   width: 100,
    //   valueFormatter: createFormatter((v) => formatToSingleDecimalIfNecessary(v)),

    //
    // },
    {
      field: 'budsPerCaneMean',
      headerName: t('per_cane_(mean)'),
      width: 140,
      valueFormatter: createFormatter((v) => formatToSingleDecimalIfNecessaryOrZero(v)),
    },
    // {
    //   field: 'budsPerCaneMedian',
    //   headerName: t('per_cane_(median)'),
    //   width: 80,
    //   valueFormatter: createFormatter((v) => formatToSingleDecimalIfNecessary(v)),
    // },
    // {
    //   field: 'budsPerCaneUQ',
    //   headerName: t('per_vine_(upper_quartile)'),
    //   width: 100,
    //   valueFormatter: createFormatter((v) => formatToSingleDecimalIfNecessary(v)),
    // },
  ]

  const colsByPruningStyle: Record<PruningStyleUnion, GridColDef[]> = {
    bow: [
      ...sharedCols,
      ...bowCols,
      {
        field: 'budsPerVine',
        headerName: t('calculated_buds_per_vine'),
        width: 220,
        valueFormatter: createFormatter((v) => formatToSingleDecimalIfNecessaryOrZero(v)),
      },
    ],
    spur: [...sharedCols, ...bowCols, ...spurCols],
    cane: [...sharedCols, ...bowCols, ...caneCols],
  }

  return colsByPruningStyle[pruningStyle]
}

export const pruningColsGrouping = (): GridColumnGroupingModel => [
  {
    groupId: t('vine_status'),
    headerAlign: 'center',
    headerClassName: 'datagrid-top-header',
    children: [
      {
        groupId: t('statistics'),
        headerAlign: 'center',
        headerClassName: 'datagrid-mid-header',
        children: [
          { field: 'vNormal' },
          { field: 'vYoung' },
          { field: 'vMissing' },
          { field: 'vUnknown' },
          { field: 'vBadQuality' },
          { field: 'vDead' },
        ],
      },
    ],
  },
  {
    groupId: t('pruning_status'),
    headerAlign: 'center',

    headerClassName: 'datagrid-top-header',
    children: [
      {
        groupId: t('statistics'),
        headerAlign: 'center',
        headerClassName: 'datagrid-mid-header',
        children: [
          { field: 'productivePct' },
          { field: 'prunedToTargetPct' },
          { field: 'notPrunedToTargetPct' },
          { field: 'caneTarget' },
        ],
      },
      {
        groupId: t('cane_counts'),
        headerAlign: 'center',

        headerClassName: 'datagrid-mid-header',
        children: [{ field: 'cane1' }, { field: 'cane2' }, { field: 'cane3' }, { field: 'cane4' }],
      },
      {
        groupId: t('bud_counts'),
        headerAlign: 'center',

        headerClassName: 'datagrid-mid-header',
        children: [
          // { field: 'budsPerVineLQ' },
          { field: 'budsPerVineMean' },
          // { field: 'budsPerVineMedian' },
          // { field: 'budsPerVineUQ' },
          // { field: 'budsPerCaneLQ' },
          { field: 'budsPerCaneMean' },
          // { field: 'budsPerCaneMedian' },
          // { field: 'budsPerCaneUQ' },
          // { field: 'budsPerSpurPerVineLQ' },
          { field: 'budsPerSpurPerVineMean' },
          // { field: 'budsPerSpurPerVineMedian' },
          // { field: 'budsPerSpurPerVineUQ' },
        ],
      },
      {
        groupId: t('missing_cordons'),
        headerAlign: 'center',

        headerClassName: 'datagrid-mid-header',
        children: [{ field: 'missingCordon1' }, { field: 'missingCordon2' }],
      },
      {
        groupId: t('buds_per_spur'),
        headerAlign: 'center',
        headerClassName: 'datagrid-mid-header',
        children: [],
      },
      {
        groupId: t('spur_counts'),
        headerAlign: 'center',
        headerClassName: 'datagrid-mid-header',
        children: [
          // { field: 'spursPerVineLQ' },
          { field: 'spursPerVineMean' },
          // { field: 'spursPerVineMedian' },
          // { field: 'spursPerVineUQ' },
        ],
      },
    ],
  },
  // {
  //   groupId: 'budsPerVine',
  //   headerName: t('buds_per_vine'),
  //   headerAlign: 'center',
  //   headerClassName: 'bg-lightblue-1',
  //   children: [
  //     { field: 'budsPerVineLQ' },
  //     { field: 'budsPerVineUQ' },
  //     { field: 'budsPerVineMean' },
  //     { field: 'budsPerVineMedian' },
  //   ],
  // },
  // {
  //   groupId: 'budsPerCane',
  //   headerName: t('buds_per_cane'),
  //   headerAlign: 'center',
  //   headerClassName: 'bg-lightblue-2',
  //   children: [
  //     { field: 'budsPerCaneLQ' },
  //     { field: 'budsPerCaneUQ' },
  //     { field: 'budsPerCaneMean' },
  //     { field: 'budsPerCaneMedian' },
  //   ],
  // },
  // {
  //   groupId: 'missingCordon',
  //   headerName: t('missing_cordons'),
  //   headerAlign: 'center',
  //   headerClassName: 'bg-lightblue-1',
  //   children: [{ field: 'missingCordon1' }, { field: 'missingCordon2' }],
  // },
  // {
  //   groupId: 'budsPerSpur',
  //   headerName: t('buds_per_spur'),
  //   headerAlign: 'center',
  //   headerClassName: 'bg-lightblue-2',
  //   children: [
  //     { field: 'budsPerSpurPerVineLQ' },
  //     { field: 'budsPerSpurPerVineUQ' },
  //     { field: 'budsPerSpurPerVineMean' },
  //     { field: 'budsPerSpurPerVineMedian' },
  //   ],
  // },
  // {
  //   groupId: 'SpursPerVine',
  //   headerName: t('spurs_per_vine'),
  //   headerAlign: 'center',
  //   headerClassName: 'bg-lightblue-1',
  //   children: [
  //     { field: 'spursPerVineLQ' },
  //     { field: 'spursPerVineUQ' },
  //     { field: 'spursPerVineMean' },
  //     { field: 'spursPerVineMedian' },
  //   ],
  // },
]
