import { Text, View, ViewProps } from '@react-pdf/renderer'
import { colorByAssumedCaneTarget } from '@utils/utility-fns'

type TableHeadProps = {
  assumedCaneTarget: number
}

const TableHead = ({ assumedCaneTarget }: TableHeadProps) => {
  const THCell = ({ title, style }: { title: string; style: ViewProps['style'] }) => (
    <View
      style={{
        flex: 1,
        height: 26,
        fontSize: 9,
        position: 'relative',
        backgroundColor: '#F5F5F5',
        paddingLeft: 4,
        justifyContent: 'center',
        ...style,
      }}
    >
      <Text style={{ flexWrap: 'wrap' }}>{title}</Text>
    </View>
  )

  return (
    <View style={{ flexDirection: 'row', width: '100%', gap: 1, borderTop: '1px solid lightgrey' }}>
      <THCell title="Row" style={{ backgroundColor: '#F5F5F5' }} />
      <THCell title="Target" style={{ backgroundColor: '#F5F5F5' }} />
      {assumedCaneTarget >= 4 && (
        <THCell title="4" style={{ backgroundColor: colorByAssumedCaneTarget(assumedCaneTarget, 'cane4'), color: '#000' }} />
      )}
      {assumedCaneTarget >= 3 && (
        <THCell title="3" style={{ backgroundColor: colorByAssumedCaneTarget(assumedCaneTarget, 'cane3'), color: '#000' }} />
      )}
      {assumedCaneTarget >= 2 && (
        <THCell title="2" style={{ backgroundColor: colorByAssumedCaneTarget(assumedCaneTarget, 'cane2'), color: '#000' }} />
      )}
      <THCell title="1" style={{ backgroundColor: colorByAssumedCaneTarget(assumedCaneTarget, 'cane1'), color: '#000' }} />
      <THCell title="Buds/Vine (Mean)" style={{ backgroundColor: '#F5F5F5', lineHeight: 1 }} />
      <THCell title="Buds/Cane (Mean)" style={{ backgroundColor: '#F5F5F5', lineHeight: 1 }} />
    </View>
  )
}

export default TableHead
