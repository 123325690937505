import Graphic from '@arcgis/core/Graphic'
import { Geometry } from '@arcgis/core/geometry'
import Polygon from '@arcgis/core/geometry/Polygon'
import * as geometryEngine from '@arcgis/core/geometry/geometryEngine'
import SimpleFillSymbol from '@arcgis/core/symbols/SimpleFillSymbol'
import MapView from '@arcgis/core/views/MapView'
import { geojsonToArcGIS } from '@terraformer/arcgis'
import { BlockDto } from '@typings/dtos/block'
import { differenceInDays, parseISO } from 'date-fns'
import { blockHighlightLayer, blockLayer } from '../layers/block.layer'
import { applyEditsToFeatureLayer, getMultipleGraphicsByIdFromFeatureLayer, setGraphicsToFeatureLayer } from '../utils'

export async function addBlocks(
  blocks: BlockDto[],
  selectedBlocks: BlockDto[]
  // rowsData: { blockId: number; minScanDate: string; maxScanDate: string }[]
) {
  const graphics: Graphic[] = []

  for (const block of blocks) {
    if (block.geometry) {
      const isBlockSelected = selectedBlocks.some((selectedBlock) => selectedBlock.id === block.id) ? 'true' : 'false'

      let value
      let scannedOn

      if (block.scanDate) {
        const scannedDaysAgo = differenceInDays(new Date(), parseISO(block.scanDate))
        scannedOn = parseISO(block.scanDate).toLocaleDateString('en-GB', { day: 'numeric', month: 'short' })
        if (scannedDaysAgo < 16) {
          value = 'LessThan16'
        } else if (scannedDaysAgo > 15) {
          value = 'MoreThan15'
        }
      } else {
        value = 'NeverScanned'
        scannedOn = null
      }

      // const blockInRowsData = rowsData.find((row) => row.blockId === block.id)

      // const minScanDate = blockInRowsData?.minScanDate
      //   ? parseISO(blockInRowsData.minScanDate).toLocaleDateString('en-GB', { day: 'numeric', month: 'short' })
      //   : ''

      // const maxScanDate = blockInRowsData?.maxScanDate
      //   ? parseISO(blockInRowsData.maxScanDate).toLocaleDateString('en-GB', { day: 'numeric', month: 'short' })
      //   : ''

      // if (minScanDate && maxScanDate && minScanDate === maxScanDate) {
      //   scannedOn = `${minScanDate}`
      // } else if (minScanDate && maxScanDate) {
      //   scannedOn = `${minScanDate}-${maxScanDate}`
      // }

      const graphic = new Graphic({
        attributes: {
          id: block.id,
          name: block.name,
          vineyardId: block.vineyardId,
          scannedDaysAgo: value,
          scanDay: scannedOn,
          isSelected: isBlockSelected,
        },
        geometry: new Polygon(geojsonToArcGIS(block.geometry)),
      })

      graphics.push(graphic)
    }
  }
  const { features } = await blockLayer.queryFeatures()

  applyEditsToFeatureLayer(blockLayer, { addFeatures: graphics, deleteFeatures: features })
}

export const highlightMultipleBlocksById = async (ids: number[]) => {
  const graphics = await getMultipleGraphicsByIdFromFeatureLayer(blockLayer, ids)
  if (graphics) {
    const newGraphics = graphics.map((graphic) => {
      const newGraphic = graphic.clone()
      newGraphic.symbol = new SimpleFillSymbol({
        style: 'none',
        outline: {
          color: 'blue',
          width: 4,
        },
      })

      return newGraphic
    })

    await setGraphicsToFeatureLayer(blockHighlightLayer, newGraphics)
  }
}

export const moveViewToSelectedBlocks = async (ids: number[], view: MapView | null) => {
  const graphics = await getMultipleGraphicsByIdFromFeatureLayer(blockLayer, ids)

  if (graphics) {
    // Filter out undefined geometries
    const geometries = graphics.map((graphic) => graphic.geometry).filter((geometry): geometry is Geometry => !!geometry) // Filter out undefined geometries

    if (geometries.length > 0) {
      const unionGeometry = geometryEngine.union(geometries)

      if (view !== null) {
        view.goTo(unionGeometry)
      }
    }
  }
}
