import { useTheme } from '@mui/material/styles'
import {
  CallbackDataParams,
  DatasetOption,
  EChartsOption,
  LegendComponentOption,
  TooltipFormatterCallback,
  TopLevelFormatterParams,
} from 'echarts/types/dist/shared.js'
import EChartsWrapper from '../EChartsWrapper'

type StackedHorizontalBarProps = {
  dataset: DatasetOption
  height?: number | string
  color?: string[]
  legend?: LegendComponentOption | LegendComponentOption[]
  tooltipFormatter?: string | TooltipFormatterCallback<TopLevelFormatterParams>
  yAxisCategoryLabelFormatter?: string | ((value: string) => string)
  seriesLabelFormatter?: (value: CallbackDataParams) => string
  hideLabelValueBelow?: number
  formatLabelToPct?: boolean
}

const StackedHorizontalBar = ({
  dataset,
  height = 400,
  color,
  legend,
  tooltipFormatter,
  yAxisCategoryLabelFormatter = (value: string) => (value.length > 16 ? `${value.slice(0, 16)}..` : value),
  hideLabelValueBelow = 2,
  formatLabelToPct = true,
}: StackedHorizontalBarProps) => {
  if (!dataset || !dataset.dimensions) return null

  const { palette: p } = useTheme()

  if (!color) color = [p.range.posHigh, p.range.posLow, p.range.negLow, p.range.negHigh]

  const option: EChartsOption = {
    color: color,
    tooltip: {
      trigger: 'axis',
      axisPointer: { type: 'shadow' },
      formatter: tooltipFormatter,
    },
    legend: legend,
    dataZoom: [
      {
        type: 'slider',
        yAxisIndex: 0,
        width: 30,
        right: 30,
        start: 20,
        end: 100,
        handleSize: 34,
        showDetail: false,
        moveHandleSize: 12,
        moveHandleStyle: { color: '#000', opacity: 0.7 },
        dataBackground: {
          lineStyle: { color: '#000' },
          areaStyle: { color: '#000' },
        },
      },
      {
        type: 'inside',
        id: 'insideY',
        yAxisIndex: 0,
        start: 50,
        end: 100,
        zoomOnMouseWheel: false,
        moveOnMouseMove: true,
        moveOnMouseWheel: true,
      },
    ],
    grid: {
      top: '6%',
      left: '122px',
      right: '100px',
      bottom: '6%',
    },
    xAxis: {
      type: 'value',
      // max: 100,
      min: 0,
      axisLabel: {
        show: false,
      },
    },
    yAxis: {
      type: 'category',

      // data: yAxisData,
      axisLabel: {
        show: true,
        color: '#1E293B',
        formatter: yAxisCategoryLabelFormatter,
      },
    },
    dataset: dataset,
    animation: false,
    series: dataset.dimensions.slice(0, dataset.dimensions.length - 1).map(() => ({
      type: 'bar',
      stack: 'total',
      labelLayout: { hideOverlap: true },
      label: {
        show: true,
        fontWeight: 600,
        formatter: (params) => {
          // @ts-expect-error - TS doesn't know that params is an object
          const value = params.value[params.dimensionNames[params.encode.x[0]]]
          if (value < hideLabelValueBelow) return ''
          return formatLabelToPct ? `${value}%` : value
        },
      },
    })),
  }

  return <EChartsWrapper option={option} height={height} />
}

export default StackedHorizontalBar
