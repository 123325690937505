import { SxProps, Theme } from '@mui/material/styles'
import { CSSProperties } from 'react'

export const ABSOLUTE_CENTER_SX: SxProps<Theme> = {
  position: 'absolute',
  maxHeight: '100vh',
  overflowY: 'auto',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}

export const SNACKBAR_ICON_SX: SxProps<Theme> = {
  fontSize: '20px',
  marginInlineEnd: '8px',
  userSelect: 'none',
  width: '1em',
  height: '1em',
  display: 'inline-block',
  fill: 'currentcolor',
  flexShrink: 0,
}

export const FLEX_CENTER_SX: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

export const SUMMARY_FILTERS_WRAPPER: CSSProperties = {
  width: '100%',
  backgroundColor: '#F5F5F5',
  border: '1px solid #D4D4D8',
  borderRadius: 4,
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '12px 14px',
}

export const SUMMARY_LABEL_CHIP_STYLES: CSSProperties = {
  textTransform: 'uppercase',
  padding: 2,
  textAlign: 'center',
  letterSpacing: 1,
  fontWeight: 500,
  fontSize: 12,
}
