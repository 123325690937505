import { Path, Svg } from '@react-pdf/renderer'

type PdfPieChartProps = {
  slices: {
    percentage: number // Percentage of the pie (0 to 100)
    color: string // Color of the slice
  }[]
  size?: number // Size of the SVG (width and height)
}

const PdfPieChart = ({ slices, size = 90 }: PdfPieChartProps) => {
  const radius = size / 2

  // Helper to calculate the arc of each slice
  const calculateArc = (percentage: number, startAngle: number) => {
    const x1 = radius + radius * Math.cos((Math.PI * startAngle) / 180)
    const y1 = radius + radius * Math.sin((Math.PI * startAngle) / 180)
    const endAngle = startAngle + (percentage / 100) * 360
    const x2 = radius + radius * Math.cos((Math.PI * endAngle) / 180)
    const y2 = radius + radius * Math.sin((Math.PI * endAngle) / 180)

    const largeArcFlag = percentage > 50 ? 1 : 0

    return `M ${radius} ${radius} L ${x1} ${y1} A ${radius} ${radius} 0 ${largeArcFlag} 1 ${x2} ${y2} Z`
  }

  let startAngle = -90 // Start at the right side of the circle (clockwise from top)

  return (
    <Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
      {slices.map((slice, index) => {
        const pathData = calculateArc(slice.percentage, startAngle)
        startAngle += (slice.percentage / 100) * 360

        return <Path key={index} d={pathData} fill={slice.color} />
      })}
    </Svg>
  )
}

export default PdfPieChart
