import { BlockApi, VineyardApi } from '@services/api'
import useVineyardStore from '@stores/vineyard'
import { useQuery } from '@tanstack/react-query'
import { groupScannedBlocksAndVineyards } from '@utils/utility-fns'
import { closeSnackbar, enqueueSnackbar, OptionsObject } from 'notistack'
import { useEffect } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { useHandleAxiosError } from './useHandleAxiosError'

export function useGetVineyardsBlocksAndScannedApis() {
  const [setVineyards, vineyardsAsMap, blocksAsMap, setBlocks, setBlocksGroupByScanDate, setBlockIdsByPruningStyle] =
    useVineyardStore(
      useShallow((s) => [
        s.setVineyards,
        s.vineyardsAsMap,
        s.blocksAsMap,
        s.setBlocks,
        s.setBlocksGroupByScanDate,
        s.setBlockIdsByPruningStyle,
      ])
    )
  const handleAxiosError = useHandleAxiosError()

  /* GET Vineyards */
  const {
    data: vineyardsData,
    error: vineyardsError,
    isLoading: loadingVineyards,
    isFetching: fetchingVineyards,
  } = useQuery({ queryKey: ['vineyards'], queryFn: VineyardApi.getVineyards, initialData: [] })

  useEffect(() => {
    if (vineyardsError) handleAxiosError(vineyardsError)
  }, [vineyardsError])

  useEffect(() => {
    if (vineyardsData) setVineyards(vineyardsData)
  }, [vineyardsData])

  /* GET Blocks Scanned */
  const {
    data: blockScannedData,
    isLoading: loadingScanned,
    isFetching: fetchingScanned,
  } = useQuery({ queryKey: ['blocks-scanned'], queryFn: BlockApi.getBlocksScanned, initialData: [] })

  /* GET Blocks */
  const {
    data: blocksData,
    error: blocksError,
    isLoading: loadingBlocks,
    isFetching: fetchingBlocks,
  } = useQuery({
    queryKey: ['blocks'],
    queryFn: BlockApi.getBlocks,
    initialData: [],
    select: (data) => data.sort((a, b) => a.name.localeCompare(b.name)),
    // select: (data) => data.map((block) => ({ ...block, name: block.id.toString() })),
  })

  useEffect(() => {
    if (blocksError) handleAxiosError(blocksError)
  }, [blocksError])

  /* Add scanned dates in blocks data + create/set varieties */
  useEffect(() => {
    if (blocksData && blockScannedData) {
      const blocksWithScannedDates = blocksData.map((block) => {
        const scanDate = blockScannedData.find((b) => b.blockId === block.id)?.scanDate || ''
        return { ...block, scanDate }
      })
      setBlocks(blocksWithScannedDates)
      setBlockIdsByPruningStyle(blocksData)
    }
  }, [blockScannedData, blocksData])

  useEffect(() => {
    if (blockScannedData && vineyardsAsMap && blocksAsMap) {
      const blocksGroupByScanDate = groupScannedBlocksAndVineyards(blockScannedData, vineyardsAsMap, blocksAsMap)
      setBlocksGroupByScanDate(blocksGroupByScanDate)
    }
  }, [blockScannedData, vineyardsAsMap, blocksAsMap])

  /* Show Snackbar while fetching data */
  useEffect(() => {
    const snackbarOptions: OptionsObject<'info'> = {
      variant: 'info',
      autoHideDuration: null,
      anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
    }

    if (fetchingVineyards) enqueueSnackbar(`Fetching vineyards...`, { key: 'fetching-vineyards', ...snackbarOptions })
    else closeSnackbar('fetching-vineyards')

    if (fetchingScanned) enqueueSnackbar(`Fetching scan timeline...`, { key: 'fetching-scanned-blocks', ...snackbarOptions })
    else closeSnackbar('fetching-scanned-blocks')

    if (fetchingBlocks) enqueueSnackbar(`Fetching blocks...`, { key: 'fetching-blocks', ...snackbarOptions })
    else closeSnackbar('fetching-blocks')
  }, [fetchingVineyards, fetchingScanned, fetchingBlocks, enqueueSnackbar, closeSnackbar])

  const loading = loadingVineyards || loadingScanned || loadingBlocks || fetchingVineyards || fetchingScanned || fetchingBlocks

  return loading
}
