import Iconify from '@components/data-display/Iconify'
import Box from '@mui/material/Box'
import Slider from '@mui/material/Slider'
import Typography from '@mui/material/Typography'
import { CustomSliderMark } from '@typings/component'
import { BlockReport } from '@typings/dtos/block-report/models'
import { MAP_SLIDER_BOX_WIDTH } from '@utils/constants'
import { findNearestDateMark } from '@utils/utility-fns'
import { format } from 'date-fns'
import { ReactNode, useEffect, useState } from 'react'

export type CustomMarksRangeSliderProps = {
  blockName: string
  reports: BlockReport[]
  targetDate: number
  customOnChange: (item: CustomSliderMark) => void
  sDate: string
  eDate: string
  header: ReactNode
  activeReportId: (id: string) => void
}

const CustomMarksRangeSlider = ({
  blockName,
  reports,
  targetDate,
  customOnChange,
  sDate,
  eDate,
  header,
  activeReportId,
}: CustomMarksRangeSliderProps) => {
  const [marks, setMarks] = useState<CustomSliderMark[]>([])
  const [activeMark, setActiveMark] = useState<CustomSliderMark | null>(null)

  const createMarks = (reports: CustomMarksRangeSliderProps['reports']) => {
    const marks = reports.map(({ id, dateEnd, blockData }) => {
      return {
        value: new Date(dateEnd).getTime(),
        label: format(dateEnd, 'dd MMM'),
        clusterId: id,
        date: dateEnd,
        caneTarget: blockData.caneTarget,
      }
    })
    return marks.sort((a, b) => a.value - b.value)
  }

  const setNextOrPrevAvailableDate = (
    marks: CustomSliderMark[],
    activeMark: CustomSliderMark | null,
    direction: 'next' | 'prev'
  ) => {
    if (!activeMark) return
    const targetDateIndex = marks.findIndex((mark) => mark.date === activeMark.date)
    const nextDateIndex = direction === 'next' ? targetDateIndex + 1 : targetDateIndex - 1
    const nextDate = marks[nextDateIndex]?.value
    if (nextDate) customOnChange(marks[nextDateIndex] as CustomSliderMark)
  }

  useEffect(() => {
    setMarks(createMarks(reports))
  }, [reports])

  useEffect(() => {
    const _activeMark = findNearestDateMark(marks, targetDate)
    if (!_activeMark) return
    if (activeMark?.value === _activeMark.value) return
    setActiveMark(_activeMark)
    activeReportId(_activeMark.clusterId)
    // customOnChange(_activeMark)
  }, [targetDate, marks])

  return (
    <div style={{ display: 'grid', gap: 12 }}>
      <Box display="grid" gap={1} sx={{ userSelect: 'none', width: MAP_SLIDER_BOX_WIDTH.child }}>
        <Typography fontSize={14} fontWeight={500} noWrap sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {blockName}{' '}
          {activeMark?.caneTarget && <span style={{ color: 'grey', fontFamily: 'monospace' }}>[{activeMark?.caneTarget}]</span>}
        </Typography>
        {header}
      </Box>
      <div style={{ display: 'flex', gap: 10 }}>
        <Iconify
          icon="ic:sharp-skip-next"
          width={34}
          onClick={() => setNextOrPrevAvailableDate(marks, activeMark, 'prev')}
          sx={{ rotate: '180deg', cursor: 'pointer', ':active': { color: '#78716C' }, ':hover': { bgcolor: '#E7E5E4' } }}
        />
        <Slider
          aria-label={`Scans for ${blockName}`}
          defaultValue={marks[0]?.value ?? 0}
          track={false}
          step={null}
          min={new Date(sDate).getTime()}
          max={new Date(eDate).getTime()}
          valueLabelDisplay="auto"
          marks={marks}
          valueLabelFormat={(val) => marks.find((mark) => mark.value === val)?.label}
          value={activeMark?.value ?? 0}
          onChange={(_, newValue) => {
            const item = marks.find((mark) => mark.value === newValue)
            if (item) {
              setActiveMark(item)
              customOnChange(item)
              activeReportId(item.clusterId)
            }
          }}
          sx={{
            height: 8,
            borderRadius: 0,
            color: 'primary.main',
            '.MuiSlider-mark': { height: 28, background: '#64748B' },
            '.MuiSlider-thumb': { borderRadius: 0 },
            '.MuiSlider-markLabel': { display: 'none' },
          }}
        />
        <Iconify
          icon="ic:sharp-skip-next"
          width={34}
          onClick={() => setNextOrPrevAvailableDate(marks, activeMark, 'next')}
          sx={{ cursor: 'pointer', ':active': { color: '#78716C' }, ':hover': { bgcolor: '#E7E5E4' } }}
        />
      </div>
    </div>
  )
}

export default CustomMarksRangeSlider
