export enum BlockPruningStyleEnum {
  Unknown = -1,
  VSP = 1, //Cane
  Spur = 2,
  HighBow = 3, //Bow
  LowBow = 4, //Bow
  HighWire = 5,
  Sylvos = 6,
}

export type PruningStyleUnion = 'bow' | 'cane' | 'spur'
