import SummaryFiltersWrapper from '@components/containers/SummaryFiltersWrapper'
import Iconify from '@components/data-display/Iconify'
import { Button } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import OutlinedInput from '@mui/material/OutlinedInput'
import Select from '@mui/material/Select'

import { SUMMARY_LABEL_CHIP_STYLES } from '@services/theme/styles'
import { YieldBlockReport_SortOptions } from '@utils/options'
import { t } from 'i18next'

type YieldFiltersSectionProps = {
  sort: string
  setSort: (sort: string) => void
  onClickDownloadCSV: VoidFunction
}

const YieldFiltersSection = ({ sort, setSort, onClickDownloadCSV }: YieldFiltersSectionProps) => {
  return (
    <SummaryFiltersWrapper
      label={t('bunches')}
      legends={
        <>
          <div style={{ ...SUMMARY_LABEL_CHIP_STYLES, backgroundColor: '#FEF08A', width: '8rem' }}>{t('visible')}</div>
          <div style={{ ...SUMMARY_LABEL_CHIP_STYLES, backgroundColor: '#BFDBFE', width: '8rem' }}>{t('corrected')}</div>
          <div style={{ ...SUMMARY_LABEL_CHIP_STYLES, backgroundColor: '#BBF7D0', width: '8rem' }}>{t('extrapolated')}</div>
        </>
      }
      rightSection={
        <>
          <FormControl>
            <InputLabel id="select-diseases-sort-label" shrink={true} sx={{ fontWeight: 500, textTransform: 'uppercase' }}>
              {t('sort')}
            </InputLabel>
            <Select
              labelId="select-diseases-sort-label"
              id="select-diseases-sort"
              value={sort}
              onChange={(e) => setSort(e.target.value)}
              input={<OutlinedInput label="SORT" sx={{ fontWeight: 500, textTransform: 'uppercase' }} />}
              sx={{ width: 230, height: 40, bgcolor: '#fff', color: '#334155', fontSize: 15 }}
            >
              {YieldBlockReport_SortOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {t(option.label)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            variant="outlined"
            sx={{
              fontSize: 14,
              fontWeight: 500,
              width: 80,
              color: 'info.dark',
              borderColor: 'info.dark',
              ':hover': { backgroundColor: '#E5E5E5', borderColor: 'info.dark' },
              ':active': { borderWidth: 2, borderColor: 'info.dark' },
            }}
            endIcon={<Iconify icon="ic:baseline-download" />}
            onClick={onClickDownloadCSV}
          >
            CSV
          </Button>
        </>
      }
    />
  )
}

export default YieldFiltersSection
