import { useTranslate } from '@hooks/useLocales'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { MAP_SLIDER_BOX_WIDTH } from '@utils/constants'
import { format } from 'date-fns'
import { ReactNode } from 'react'

type TemporalSlidersWidgetProps = {
  oldestDate: string
  newestDate: string
  temporalSlidersHeader: ReactNode
  temporalRangeSliders: ReactNode
  parentRangeSlider: ReactNode
  isFetchingData: boolean
}

const TemporalSlidersWidget = ({
  temporalSlidersHeader,
  oldestDate,
  newestDate,
  temporalRangeSliders,
  parentRangeSlider,
  isFetchingData,
}: TemporalSlidersWidgetProps) => {
  const { t } = useTranslate()

  return (
    <>
      <Box sx={{ display: 'grid', position: 'absolute', right: 6, top: 6, justifyItems: 'end' }}>
        {temporalSlidersHeader}

        <Paper sx={{ width: MAP_SLIDER_BOX_WIDTH.main, display: 'grid', borderRadius: 0, position: 'relative' }}>
          <Box width="100%" color="info.dark" bgcolor="#64748B" fontWeight={900} fontSize={13}>
            <span
              style={{ color: '#FFF', display: 'flex', justifyContent: 'center', padding: '7px 0 6px 0', letterSpacing: '0.5px' }}
            >
              {t('block_controls')}
            </span>
            <Box
              borderTop="2px dashed white"
              borderLeft="2px solid white"
              borderRight="2px solid white"
              height={10}
              width={{ xs: 300, xl: 348 }}
              mx="auto"
            />
            {oldestDate && newestDate && (
              <Box display="flex" color="white">
                <Box width={1 / 4} textAlign="center">
                  <Typography fontSize={12} fontWeight={500}>
                    {format(oldestDate, 'dd MMM')}
                  </Typography>
                </Box>
                <Box width={1} textAlign="center">
                  <Typography fontSize={12} fontWeight={500}>
                    {/* Middle date of startDate and endDate */}
                    {format((new Date(oldestDate).getTime() + new Date(newestDate).getTime()) / 2, 'dd MMM')}
                  </Typography>
                </Box>
                <Box width={1 / 4} textAlign="center">
                  <Typography fontSize={12} fontWeight={500}>
                    {format(newestDate, 'dd MMM')}
                  </Typography>
                </Box>
              </Box>
            )}
            <Divider sx={{ mt: 1 }} />
          </Box>

          {isFetchingData ? <pre style={{ fontSize: 12, padding: '2px 8px' }}>Fetching data...</pre> : temporalRangeSliders}
        </Paper>
      </Box>

      {parentRangeSlider}
    </>
  )
}

export default TemporalSlidersWidget
