import { CountryCode } from '@typings/common'
import { BlockNamesWithSameValue } from '@typings/component'
import { subMonths } from 'date-fns'
import { formatISOWithCustomHours } from './utility-fns'

export const DEFAULT_START_DATE = formatISOWithCustomHours(subMonths(new Date(), 1))

export const DEFAULT_END_DATE = formatISOWithCustomHours(new Date(), { h: 23, m: 59, s: 59, ms: 999 })

export const DEFAULT_BLOCK_NAMES_WITH_SAME_VAL: BlockNamesWithSameValue = { blockNames: [], value: 0 }

export const MAP_SLIDER_BOX_WIDTH = {
  main: { xs: 350, xl: 440 },
  child: { xs: 320, xl: 414 },
}

export const SUMMARY_CONTAINER_WIDTH = 1420

export const pinnedColumns = { pinnedColumns: { left: ['scanDate', 'vineyardName', 'blockName', 'rowNum'] } }

export const MAP_CLR = {
  DEAD: '#000000',
  YOUNG: '#652CB3',
  MISSING: '#A8A29E',
  CANE_0: '#000000',
  CANE_1: '#F24727',
  CANE_2: '#F3C127',
  CANE_3: '#2D9BF0',
  CORDON_0: '#000000',
  CORDON_1: '#0D9488',
  METAL: '#414CB2',
  WOOD: '#8B4513',
  PLASTIC: '#FFD700',
  UNKNOWN: '#A8A29E',
}

export const countriesInNorthHemisphere: CountryCode[] = ['US', 'FR']
export const countriesInSouthHemisphere: CountryCode[] = ['NZ', 'AU']
