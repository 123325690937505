import { useTranslate } from '@hooks/useLocales'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { ReactNode } from 'react'

type MapFilterToggleButtonProps = {
  isActive: boolean
  symbol: ReactNode
  label: string
  onClick: VoidFunction
  width?: string | number
}

const MapFilterToggleButton = ({ isActive, symbol, label, onClick, width = 'max-content' }: MapFilterToggleButtonProps) => {
  const { t } = useTranslate()

  return (
    <Box
      display="grid"
      gap="2px"
      px="8px"
      pt="6px"
      pb="4px"
      width={width}
      role="button"
      onClick={onClick}
      sx={{
        userSelect: 'none',
        cursor: 'pointer',
        position: 'relative',
        bgcolor: isActive ? 'none' : '#F5F5F4',
        borderBottom: isActive ? '4px solid #84CC16' : '4px solid #F5F5F4',
        '&:hover': { borderBottom: isActive ? '4px solid #84CC16' : '4px solid #D6D3D1' },
      }}
    >
      <div style={{ height: 15, fontSize: 14, color: 'grey.800', lineHeight: 1 }}>{symbol}</div>
      <Typography fontSize={12} fontWeight={500} color="grey.700">
        {t(label)}
      </Typography>
    </Box>
  )
}

export default MapFilterToggleButton
