import { VineAndPostLayer } from '@/arcgis/layers/pruning-vines.layer'
import Graphic from '@arcgis/core/Graphic'
import { default as EsriMap } from '@arcgis/core/Map'
import MapView from '@arcgis/core/views/MapView'
import PruningStyleOptionsWrapper from '@components/containers/PruningStyleOptionsWrapper'
import MapBase from '@components/map/MapBase'
import VineAndPostInfoPopup, { VineAndPostInfoPopupProps } from '@components/map/VineAndPostInfoPopup'
import LandSlidersGroup from '@components/map/land/LandSlidersGroup'
import { useGetMapPageData } from '@hooks/pruning/useGetMapPageData'
import { ReportTypeEnum } from '@typings/dtos/block-report/enums'
import { useEffect, useState } from 'react'

type LandMapPageProps = {}

const LandMapPage = ({}: LandMapPageProps) => {
  const [openPopup, setOpenPopup] = useState(false)
  const [isMapLoaded, setIsMapLoaded] = useState(false)
  const [clickedGraphic, setClickedGraphic] = useState<Graphic | null>(null)
  const [markerDetail, setMarkerDetail] = useState<VineAndPostInfoPopupProps>()
  const [mapView, setMapView] = useState<MapView | null>(null)

  const updateLayers = (map: EsriMap) => {
    map.add(VineAndPostLayer)
  }

  useEffect(() => {
    if (!clickedGraphic) return
    if (clickedGraphic.layer.id === 'vines-post-layer') {
      const details = JSON.parse(clickedGraphic.attributes.details)
      setMarkerDetail(details)
      setOpenPopup(true)
    }
  }, [clickedGraphic])

  const { reports, isFetching, count } = useGetMapPageData({ reportType: ReportTypeEnum.Land, queryKey: 'LandMapData' })

  return (
    <>
      <PruningStyleOptionsWrapper blocksCountByPruningStyle={count} />
      <MapBase
        height="calc(100dvh - 8rem)"
        onGraphicClick={(g) => setClickedGraphic(g)}
        base={({ map, view, isLoaded }) => {
          updateLayers(map)
          setMapView(view)
          setIsMapLoaded(isLoaded)
        }}
      >
        {isMapLoaded && <LandSlidersGroup mapView={mapView} data={reports} isFetching={isFetching} />}
        {openPopup && markerDetail && <VineAndPostInfoPopup {...markerDetail} onClose={() => setOpenPopup(false)} />}
      </MapBase>
    </>
  )
}

export default LandMapPage
