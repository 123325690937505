import SummaryTileWrapper from '@components/containers/SummaryTileWrapper'
import SortBlocksMenu from '@components/echarts/SortBlocksMenu'
import BoxplotChart from '@components/echarts/charts/BoxplotChart'
import ToggleSwitch from '@components/form/ToggleSwitch'
import { useTranslate } from '@hooks/useLocales'
import Box from '@mui/material/Box'
import { IOption } from '@typings/common'
import { BlockReport } from '@typings/dtos/block-report/models'
import { boxplotTooltipFormatter, labelFormatter_Land } from '@utils/echarts'
import {
  PruningCane_BudsPerVineSortOptions,
  PruningSpur_BudsPerSpurPerVineSortOptions,
  PruningSpur_SpursPerVineSortOptions,
} from '@utils/options'
import { useEffect, useState } from 'react'

type SpurPruningVineStatsBoxplotChartProps = {
  data: BlockReport[]
}

type StatsView = 'budsPerVine' | 'budsPerSpurPerVine' | 'spursPerVine'

const SpurPruningVineStatsBoxplotChart = ({ data }: SpurPruningVineStatsBoxplotChartProps) => {
  const [activeSort, setActiveSort] = useState('')
  const [spurPruningVineStats, setSpurPruningVineStats] = useState<BlockReport[]>([])
  const [activeBudsStatsView, setActiveBudsStatsView] = useState<StatsView>('budsPerVine')

  const { t } = useTranslate()

  const dataByActiveView: Record<StatsView, number[][]> = {
    budsPerVine: spurPruningVineStats.map(({ blockData: b }) => {
      if (!b) return [0, 0, 0, 0, 0]
      return [b.budsPerVineLQ, b.budsPerVineLQ, b.budsPerVineMean, b.budsPerVineUQ, b.budsPerVineUQ]
    }),
    budsPerSpurPerVine: spurPruningVineStats.map(({ blockData: b }) => {
      if (!b) return [0, 0, 0, 0, 0]
      return [
        b.budsPerSpurPerVineLQ,
        b.budsPerSpurPerVineLQ,
        b.budsPerSpurPerVineMean,
        b.budsPerSpurPerVineUQ,
        b.budsPerSpurPerVineUQ,
      ]
    }),
    spursPerVine: spurPruningVineStats.map(({ blockData: b }) => {
      if (!b) return [0, 0, 0, 0, 0]
      return [b.spursPerVineLQ, b.spursPerVineLQ, b.spursPerVineMean, b.spursPerVineUQ, b.spursPerVineUQ]
    }),
  }

  const sortOptionsByActiveView: Record<StatsView, IOption<string>[]> = {
    budsPerVine: PruningCane_BudsPerVineSortOptions,
    budsPerSpurPerVine: PruningSpur_BudsPerSpurPerVineSortOptions,
    spursPerVine: PruningSpur_SpursPerVineSortOptions,
  }

  useEffect(() => {
    if (activeSort === '') return

    const sortDataByBlockName = () => {
      setSpurPruningVineStats((prev) => [
        ...prev.sort((a, b) =>
          activeSort === 'Block Name-asc' ? b.blockName.localeCompare(a.blockName) : a.blockName.localeCompare(b.blockName)
        ),
      ])
    }
    if (activeSort.startsWith('Block Name')) sortDataByBlockName()

    const sortDataByNumericalProperties = () => {
      setSpurPruningVineStats((prev) => {
        //text before the first hyphen
        const sortKey = activeSort.split('-')[0] as keyof BlockReport['blockData']
        return [
          ...prev.sort((a, b) => {
            const valueA = a.blockData[sortKey] as number
            const valueB = b.blockData[sortKey] as number
            return activeSort.endsWith('-asc') ? valueB - valueA : valueA - valueB
          }),
        ]
      })
    }
    return sortDataByNumericalProperties()
  }, [activeSort])

  useEffect(() => {
    setSpurPruningVineStats(data)
  }, [data])

  return (
    <Box pb={4}>
      <SummaryTileWrapper
        label="vine_stats"
        width="100%"
        height={500}
        tooltipMsg={'pruning_page.summary.spur.tooltip_vine_stats'}
        headerComponent={
          <>
            <ToggleSwitch
              options={[
                { label: 'buds_per_vine', value: 'budsPerVine' },
                { label: 'buds_per_spur_per_vine', value: 'budsPerSpurPerVine' },
                { label: 'spurs_per_vine', value: 'spursPerVine' },
              ]}
              value={activeBudsStatsView}
              onClick={(val) => setActiveBudsStatsView(val as 'budsPerVine' | 'budsPerSpurPerVine' | 'spursPerVine')}
              optionWidth={200}
            />
            <SortBlocksMenu
              id="sort-vine-stats-data"
              menuItems={sortOptionsByActiveView[activeBudsStatsView]}
              value={activeSort}
              onClick={(val) => setActiveSort(val)}
              menuArrowPositionRight={110}
            />
          </>
        }
      >
        <Box py={4}>
          <BoxplotChart
            data={dataByActiveView[activeBudsStatsView]}
            categoryData={spurPruningVineStats.map((stat) => stat.blockName + '>' + stat.dateEnd)}
            tooltipFormatter={(params) => boxplotTooltipFormatter(params, t)}
            yAxisCategoryLabelFormatter={labelFormatter_Land}
          />
        </Box>
      </SummaryTileWrapper>
    </Box>
  )
}

export default SpurPruningVineStatsBoxplotChart
