import { enNZ, enUS, fr } from 'date-fns/locale'

export const defaultLang = {
  label: 'British English',
  value: 'gb',
  adapterLocale: enNZ,
  icon: 'flagpack:gb-ukm',
}

export const allLangs = [
  defaultLang,

  {
    label: 'US English',
    value: 'us',
    adapterLocale: enUS,
    icon: 'flagpack:us',
  },
  {
    label: 'French',
    value: 'fr',
    adapterLocale: fr,
    icon: 'flagpack:fr',
  },
]
