import Iconify from '@components/data-display/Iconify'
import MenuBase from '@components/navigation/MenuBase'
import { useDevToolsStatus } from '@hooks/useDevToolsStatus'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import ListItemIcon from '@mui/material/ListItemIcon'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import useAuthStore from '@stores/auth'
import { t } from 'i18next'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'

const AccountMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const [user, logout] = useAuthStore(useShallow((s) => [s.user, s.logout]))
  const navigate = useNavigate()
  const isDevToolsOpen = useDevToolsStatus()

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Box
          onClick={(e) => setAnchorEl(e.currentTarget)}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          sx={{
            border: '2px solid',
            borderColor: isDevToolsOpen ? 'primary.dark' : 'white',
            fontWeight: 600,
            fontSize: 13,
            pl: 1,
            pr: 1.3,
            py: 0.5,
            borderRadius: 4,
            cursor: 'pointer',
            ':hover': { boxShadow: 1 },
            display: 'flex',
            alignItems: 'center',
            gap: 0.5,
          }}
        >
          <Iconify icon="material-symbols:face" />
          <span>{user?.name}</span>
        </Box>
      </Box>
      <MenuBase id="account-menu" anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}>
        <Box sx={{ px: 2, pb: 1.5, pt: 0.5, display: 'flex', alignItems: 'center', gap: 2 }}>
          <Iconify icon="material-symbols:alternate-email" />
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>
        <Divider sx={{ width: 250 }} />
        <MenuItem
          onClick={() => {
            setAnchorEl(null)
            navigate('/settings')
          }}
          sx={{ py: 1, mt: 1 }}
        >
          <ListItemIcon>
            <Iconify icon="material-symbols:settings" width={22} />
          </ListItemIcon>
          {t('user_settings')}
        </MenuItem>
        {user?.enableBilling ? (
          <MenuItem
            onClick={() => {
              setAnchorEl(null)
              navigate('/billing')
            }}
            sx={{ py: 1 }}
          >
            <ListItemIcon>
              <Iconify icon="mdi:invoice-text" width={22} />
            </ListItemIcon>
            {t('billing_&_usage')}
          </MenuItem>
        ) : null}

        <MenuItem
          onClick={() => {
            setAnchorEl(null)
            logout()
            navigate('/auth')
          }}
          sx={{ color: 'error.main', py: 1 }}
        >
          <ListItemIcon>
            <Iconify icon="material-symbols:logout" sx={{ color: 'error.main' }} width={22} />
          </ListItemIcon>
          {t('logout')}
        </MenuItem>
      </MenuBase>
    </>
  )
}

export default AccountMenu
