import SummaryTileWrapper from '@components/containers/SummaryTileWrapper'
import SortBlocksMenu from '@components/echarts/SortBlocksMenu'
import BoxplotChart from '@components/echarts/charts/BoxplotChart'
import { useTranslate } from '@hooks/useLocales'
import Box from '@mui/material/Box'
import { BlockReport } from '@typings/dtos/block-report/models'
import { boxplotTooltipFormatter, labelFormatter_Land } from '@utils/echarts'
import { PruningCane_BudsPerCaneSortOptions, PruningCane_BudsPerVineSortOptions } from '@utils/options'
import { useEffect, useState } from 'react'

type CanePruningBudsStatsBoxplotChartProps = {
  data: BlockReport[]
  dataBy: 'cane' | 'vine'
}

const CanePruningBudsStatsBoxplotChart = ({ data, dataBy }: CanePruningBudsStatsBoxplotChartProps) => {
  const [activeSort, setActiveSort] = useState('')
  const [canePruningBudsStats, setCanePruningBudsStats] = useState<BlockReport[]>([])
  const [activeBudsStatsView, setActiveBudsStatsView] = useState<'budsPerVine' | 'budsPerCane'>('budsPerVine')

  const { t } = useTranslate()

  const dataByActiveView = {
    budsPerVine: canePruningBudsStats.map(({ blockData: b }) => {
      if (!b) return [0, 0, 0, 0, 0]
      return [b.budsPerVineLQ, b.budsPerVineLQ, b.budsPerVineMean, b.budsPerVineUQ, b.budsPerVineUQ]
    }),
    budsPerCane: canePruningBudsStats.map(({ blockData: b }) => {
      if (!b) return [0, 0, 0, 0, 0]
      return [b.budsPerCaneLQ, b.budsPerCaneLQ, b.budsPerCaneMean, b.budsPerCaneUQ, b.budsPerCaneUQ]
    }),
  }

  const sortOptionsByActiveView = {
    budsPerVine: PruningCane_BudsPerVineSortOptions,
    budsPerCane: PruningCane_BudsPerCaneSortOptions,
  }

  useEffect(() => {
    setActiveBudsStatsView(dataBy === 'cane' ? 'budsPerCane' : 'budsPerVine')
  }, [dataBy])

  useEffect(() => {
    if (activeSort === '') return

    const sortDataByBlockName = () => {
      setCanePruningBudsStats((prev) =>
        prev.sort((a, b) =>
          activeSort === 'Block Name-asc' ? b.blockName.localeCompare(a.blockName) : a.blockName.localeCompare(b.blockName)
        )
      )
    }
    if (activeSort.startsWith('Block Name')) sortDataByBlockName()

    const sortDataByNumericalProperties = () => {
      setCanePruningBudsStats((prev) => {
        //text before the first hyphen
        const sortKey = activeSort.split('-')[0] as keyof BlockReport['blockData']
        return [
          ...prev.sort((a, b) => {
            const valueA = a.blockData[sortKey] as number
            const valueB = b.blockData[sortKey] as number
            return activeSort.endsWith('-asc') ? valueB - valueA : valueA - valueB
          }),
        ]
      })
    }
    return sortDataByNumericalProperties()
  }, [activeSort])

  useEffect(() => {
    setCanePruningBudsStats(data)
  }, [data])

  return (
    <Box pb={4}>
      <SummaryTileWrapper
        label="buds_stats"
        width="100%"
        height={500}
        tooltipMsg="pruning_page.summary.cane.tooltip_buds_stats"
        headerComponent={
          <SortBlocksMenu
            id="sort-canes-data"
            menuItems={sortOptionsByActiveView[activeBudsStatsView]}
            value={activeSort}
            onClick={(val) => setActiveSort(val)}
            menuArrowPositionRight={110}
          />
        }
      >
        <Box py={4}>
          <BoxplotChart
            data={dataByActiveView[activeBudsStatsView]}
            categoryData={canePruningBudsStats.map((stat) => stat.blockName + '>' + stat.dateEnd)}
            tooltipFormatter={(params) => boxplotTooltipFormatter(params, t)}
            yAxisCategoryLabelFormatter={labelFormatter_Land}
          />
        </Box>
      </SummaryTileWrapper>
    </Box>
  )
}

export default CanePruningBudsStatsBoxplotChart
