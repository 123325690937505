import ScrollingInputWrapper from '@components/containers/ScrollingInputWrapper'
import Iconify from '@components/data-display/Iconify'
import { useTranslate } from '@hooks/useLocales'
import Box from '@mui/material/Box'
import CheckBox from '@mui/material/Checkbox'
import Chip from '@mui/material/Chip'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import { BlockDto, Variety } from '@typings/dtos/block'
import { VineyardDto } from '@typings/dtos/vineyard'
import { labelByTrainingSystem } from '@utils/options'
import { useEffect, useRef, useState } from 'react'
import { FixedSizeList, ListChildComponentProps } from 'react-window'

type MultiChipSelectProps<T> = {
  id: string
  label: string
  options: T[]
  selectedOptions: T[]
  onDeleteChip: (option: T) => void
  onSelectAll: () => void
  onClickMenuItem: (option: T) => void
  onClickClearAll: () => void
  searchPlaceholder: string
  placeholder: string
  loading?: boolean
}

const MultiChipSelect = <T extends VineyardDto | BlockDto | Variety>({
  id,
  label,
  options,
  selectedOptions,
  onDeleteChip,
  onSelectAll,
  onClickMenuItem,
  onClickClearAll,
  searchPlaceholder,
  placeholder,
  loading,
}: MultiChipSelectProps<T>) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const inputRef = useRef<HTMLDivElement>(null)
  const [search, setSearch] = useState('')

  const open = Boolean(anchorEl)

  const { t } = useTranslate()

  useEffect(() => {
    if (loading) setAnchorEl(null)
  }, [loading])

  const renderRow = ({ index, style }: ListChildComponentProps) => {
    // Render the "Select All" item when there is no search
    if (search.length === 0) {
      if (index === 0) {
        return (
          <MenuItem
            style={style}
            sx={{ py: 0, pr: 1, pl: 0.2, overflowX: 'hidden' }}
            onKeyDown={(e) => e.stopPropagation()}
            onClick={onSelectAll}
            key="select-all"
          >
            <CheckBox checked={selectedOptions.length === options.length} />
            {t('select_all')}
          </MenuItem>
        )
      }
    }

    // Handle normal items rendering
    const filteredOptions =
      search.length === 0 ? options : options.filter((o) => o.name.toLowerCase().includes(search.toLowerCase()))

    // Adjust index for "Select All" when there is no search
    const adjustedIndex = search.length === 0 ? index - 1 : index
    const item = filteredOptions[adjustedIndex]

    if (!item) return null

    const isChecked = selectedOptions.map((o) => o.id).includes(item.id)

    // @ts-expect-error - TS doesn't know that labelByTrainingSystem has all the keys of BlockPruningStyleEnum
    const pruningStyle = item?.pruningStyleId && labelByTrainingSystem[item.pruningStyleId]

    return (
      <MenuItem
        style={style}
        sx={{ py: 0, pr: 1, pl: 0.2, overflowX: 'hidden' }}
        onKeyDown={(e) => e.stopPropagation()}
        onClick={() => onClickMenuItem(item)}
        key={item.id}
      >
        <CheckBox checked={isChecked} />
        <span style={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.name}</span>

        {pruningStyle && (
          <Box
            component="span"
            sx={{
              ml: 'auto',
              fontSize: 11,
              bgcolor: '#78716C',
              color: 'white',
              px: 1,
              borderRadius: 3,
              fontWeight: 600,
              pt: 0.2,
            }}
          >
            {pruningStyle}
          </Box>
        )}
      </MenuItem>
    )
  }

  return (
    <div>
      <Box position="relative">
        <ScrollingInputWrapper
          id={id}
          borderColor={open ? 'grey.700' : 'grey.400'}
          labelColor={open ? 'primary.dark' : 'grey.700'}
          loading={loading}
          label={
            <>
              {label}{' '}
              {selectedOptions.length > 0 && (
                <Box
                  component="span"
                  bgcolor={open ? 'primary.dark' : 'grey.600'}
                  px={0.5}
                  color="white"
                  borderRadius={0.6}
                  fontSize={11}
                >
                  {selectedOptions.length}
                </Box>
              )}
            </>
          }
          onClick={(event) => {
            if (!loading) {
              setAnchorEl(event.currentTarget)
              setTimeout(() => {
                inputRef.current?.focus()
              }, 100)
            }
          }}
        >
          {selectedOptions.length ? (
            selectedOptions.map((option) => (
              <Chip
                key={option.id}
                label={option.name}
                onDelete={() => onDeleteChip(option)}
                size="small"
                sx={{ m: 0.5, fontWeight: 500 }}
              />
            ))
          ) : (
            <span
              style={{ fontSize: 14, padding: '4px 0 0 4px', color: 'grey', display: 'flex', justifyContent: 'center', gap: 2 }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
                <path
                  fill="#BDBDBD"
                  d="m19.6 21l-6.3-6.3q-.75.6-1.725.95T9.5 16q-2.725 0-4.612-1.888T3 9.5t1.888-4.612T9.5 3t4.613 1.888T16 9.5q0 1.1-.35 2.075T14.7 13.3l6.3 6.3zM9.5 14q1.875 0 3.188-1.312T14 9.5t-1.312-3.187T9.5 5T6.313 6.313T5 9.5t1.313 3.188T9.5 14"
                />
              </svg>
              {t(placeholder)}
            </span>
          )}
        </ScrollingInputWrapper>
        {selectedOptions.length > 0 && (
          <Iconify
            icon="mdi:clear-box"
            sx={{
              position: 'absolute',
              right: 0,
              top: 4,
              zIndex: 99,
              color: 'grey.600',
              cursor: 'pointer',
              width: 18,
              ':hover': { color: 'error.light' },
            }}
            onClick={onClickClearAll}
          />
        )}
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          setSearch('')
          setAnchorEl(null)
        }}
        disableAutoFocusItem
        disableAutoFocus
        disableEnforceFocus
        slotProps={{ paper: { sx: { maxHeight: 260, width: 290, '& .MuiMenu-list': { py: 0, overflowX: 'hidden' } } } }}
      >
        <TextField
          inputRef={inputRef}
          sx={{ position: 'sticky', top: 0, bgcolor: 'white', zIndex: 1 }}
          placeholder={t(searchPlaceholder)}
          fullWidth
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onKeyDown={(e) => e.stopPropagation()}
          InputProps={{
            sx: { height: 40 },
            endAdornment: <Iconify icon="material-symbols:search" width={24} sx={{ color: 'grey.500' }} />,
          }}
        />
        <FixedSizeList
          height={200}
          itemSize={48}
          width={290}
          itemCount={
            search.length === 0
              ? options.length + 1
              : options.filter((o) => o.name.toLowerCase().includes(search.toLowerCase())).length
          }
          overscanCount={10}
          itemData={options.filter((o) => o.name.toLowerCase().includes(search.toLowerCase()))}
        >
          {renderRow}
        </FixedSizeList>
      </Menu>
    </div>
  )
}

export default MultiChipSelect
