import HighlightsInfoWrapper from '@components/containers/HighlightsInfoWrapper'
import SummaryPageWrapper from '@components/containers/SummaryPageWrapper'
import ConditionalStatsTileContent from '@components/sections/ConditionalStatsTileContent'
import HealthFiltersSection from '@components/sections/health-summary/HealthFiltersSection'
import HealthTable from '@components/sections/health-summary/HealthTable'
import { DiseaseReportsByBlock, useGetDiseaseBlockReports } from '@hooks/health/useGetDiseaseBlockReports'
import Box from '@mui/material/Box'
import { DiseaseStats } from '@typings/dtos/block-report/models'
import { HealthBlockReportSortOptions } from '@utils/options'
import { useEffect, useState } from 'react'

const HealthSummaryPage = () => {
  const [data, setData] = useState<DiseaseReportsByBlock[]>([])
  const [showDiseases, setShowDiseases] = useState<Array<keyof DiseaseStats>>(['pmTotalInfectedVines', 'bbrInfectedVines'])
  const [sort, setSort] = useState<HealthBlockReportSortOptions>('high-infected-vines')

  const { diseaseReports, isFetching } = useGetDiseaseBlockReports()

  useEffect(() => {
    if (!diseaseReports) setData([])
    else if (diseaseReports.length === 1) setData(diseaseReports)
    else {
      let _data = [...diseaseReports]
      switch (sort) {
        case 'high-infected-vines':
        case 'low-infected-vines':
          _data = _data.sort((a, b) => {
            const recentReportA = a.reports.at(-1)
            const recentReportB = b.reports.at(-1)
            if (!recentReportA || !recentReportB) return 0

            const sumA = showDiseases.reduce((acc, key) => {
              if (recentReportA.blockData[key]) return acc + recentReportA.blockData[key]
              return acc
            }, 0)

            const sumB = showDiseases.reduce((acc, key) => {
              if (recentReportB.blockData[key]) return acc + recentReportB.blockData[key]
              return acc
            }, 0)

            if (sort.startsWith('low')) return sumA - sumB
            else return sumB - sumA
          })
          break
        case 'desc-vineyard-name':
          _data = _data.sort((a, b) => {
            const vineyardComparison = b.vineyardName.localeCompare(a.vineyardName)
            if (vineyardComparison === 0) {
              return b.blockName.localeCompare(a.blockName) // Secondary sorting for blockName in descending
            }
            return vineyardComparison
          })
          break
        case 'asc-vineyard-name':
          _data = _data.sort((a, b) => {
            const vineyardComparison = a.vineyardName.localeCompare(b.vineyardName)
            if (vineyardComparison === 0) {
              return a.blockName.localeCompare(b.blockName) // Secondary sorting for blockName in ascending
            }
            return vineyardComparison
          })
          break
        case 'high-trunk-infected-vines-percentage':
        case 'low-trunk-infected-vines-percentage':
          _data = _data.sort((a, b) => {
            const recentReportA = a.reports.at(-1)
            const recentReportB = b.reports.at(-1)
            if (!recentReportA || !recentReportB) return 0

            const sumAPct =
              (recentReportA.blockData.trunkDeadVinesPct || 0) +
              (recentReportA.blockData.trunkStrongVinesPct || 0) +
              (recentReportA.blockData.trunkWeakVinesPct || 0)
            const sumBPct =
              (recentReportB.blockData.trunkDeadVinesPct || 0) +
              (recentReportB.blockData.trunkStrongVinesPct || 0) +
              (recentReportB.blockData.trunkWeakVinesPct || 0)

            if (sort.startsWith('low')) return sumAPct - sumBPct
            else return sumBPct - sumAPct
          })
          break
        default:
          break
      }
      setData(_data)
    }
  }, [diseaseReports, sort, showDiseases])

  return (
    <SummaryPageWrapper dataLength={data.length} isLoading={isFetching}>
      <Box display="flex" gap={2} flexWrap="wrap">
        <HighlightsInfoWrapper
          label="showing_stats_for"
          mainHighlightComponent={<ConditionalStatsTileContent data={data} />}
          tooltipInfo="pruning_page.summary.tooltip_showing_stats_for"
        />
      </Box>
      <HealthFiltersSection showDiseases={showDiseases} setShowDiseases={setShowDiseases} sort={sort} setSort={setSort} />

      {data.length ? (
        <Box display="grid" gap={4} pb={4}>
          {data.map((block) => (
            <HealthTable key={block.blockId} block={block} detectionsToShow={showDiseases} />
          ))}
        </Box>
      ) : null}
    </SummaryPageWrapper>
  )
}

export default HealthSummaryPage
