import { moveViewToGeometry } from '@/arcgis/controllers'
import { useAddLandVinePostRowGraphics } from '@/arcgis/hooks/useAddLandVinePostRowGraphics'
import MapView from '@arcgis/core/views/MapView'
import DateRangeSlider from '@components/form/DateRangeSlider'
import useVineyardStore from '@stores/vineyard'
import { BlockReportsByBlock } from '@typings/component'
import { PruningStyleUnion } from '@typings/dtos/block'
import { findOldestAndNewestDates } from '@utils/utility-fns'
import { useEffect, useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import TemporalSlidersWidget from '../TemporalSlidersWidget'
import LandCustomMarksRangeSlider from './LandCustomMarksRangeSlider'
import PostsAndVineToggle from './PostsAndVinesToggle'

type LandSlidersGroupProps = {
  mapView: MapView | null
  data: Record<PruningStyleUnion, BlockReportsByBlock[]>
  isFetching: boolean
}

const LandSlidersGroup = ({ mapView, data, isFetching }: LandSlidersGroupProps) => {
  const [reports, setReports] = useState<BlockReportsByBlock[]>()
  const [activeBlockId, setActiveBlockId] = useState<number | null>(null)
  const [showGraphics, setShowGraphics] = useState<string[]>([])

  const [startDate, endDate, selectedBlocks, activePruningStyle] = useVineyardStore(
    useShallow((s) => [s.startDate, s.endDate, s.selectedBlocks, s.activePruningStyle])
  )
  const [targetDate, setTargetDate] = useState<number>(new Date(endDate).getTime())

  useEffect(() => {
    if (activeBlockId) {
      const block = selectedBlocks.find((block) => block.id === activeBlockId)
      if (block && mapView) {
        const { coordinates } = block.geometry as unknown as { coordinates: __esri.Geometry[] }
        moveViewToGeometry(coordinates, mapView, { duration: 500, easing: 'ease' })
      }
    }
  }, [activeBlockId, mapView, selectedBlocks])

  useEffect(() => {
    setReports(data[activePruningStyle])
  }, [data, activePruningStyle])

  const { oldestDate, newestDate } = findOldestAndNewestDates(reports)

  useAddLandVinePostRowGraphics({ reports, targetDate, showGraphics })

  return (
    <TemporalSlidersWidget
      oldestDate={oldestDate}
      newestDate={newestDate}
      isFetchingData={isFetching}
      temporalSlidersHeader={<PostsAndVineToggle showGraphics={showGraphics} setShowGraphics={setShowGraphics} />}
      temporalRangeSliders={
        <div style={{ display: 'grid', maxHeight: 'calc(100dvh - 28rem)', overflowY: 'auto', overflowX: 'hidden' }}>
          {reports &&
            reports.map((r, i) => {
              return (
                <div
                  key={r.blockId}
                  onClick={() => setActiveBlockId(r.blockId)}
                  style={{
                    backgroundColor: r.blockId === activeBlockId ? '#F5F5F4' : '',
                    borderTop: i === 0 ? '' : '1px solid #E0E0E0',
                    borderBottom: '1px solid #E0E0E0',
                    padding: '16px 8px 8px 8px',
                  }}
                >
                  <LandCustomMarksRangeSlider
                    targetDate={targetDate}
                    blockName={r.blockName}
                    reports={r.reports}
                    customOnChange={(item) => setTargetDate(item.value)}
                    sDate={oldestDate}
                    eDate={newestDate}
                  />
                </div>
              )
            })}
        </div>
      }
      parentRangeSlider={
        <DateRangeSlider
          startDate={startDate}
          endDate={endDate}
          targetDate={targetDate}
          customOnChange={(item) => setTargetDate(Number(item.date))}
        />
      }
    />
  )
}

export default LandSlidersGroup
