import Graphic from '@arcgis/core/Graphic'
import Map from '@arcgis/core/Map'
import MapView from '@arcgis/core/views/MapView'
import { blockHighlightLayer, blockLayer } from './layers'
import { RowsLayer } from './layers/rows.layer'

const defaultCenter = [173.788690644, -41.49193959]

type InitialiseMapParams = {
  container: HTMLDivElement
  center?: number[]
  onGraphicClick: (graphic: Graphic) => void
}

export const initialiseMap = async ({ container, center = defaultCenter, onGraphicClick }: InitialiseMapParams) => {
  const map = new Map({ basemap: 'satellite' })

  map.addMany([blockLayer, blockHighlightLayer, RowsLayer])

  const mapView = new MapView({ map, container, scale: 25000, center: center, ui: { components: [] } })

  mapView.when(() => {
    mapView.on('click', async (event) => {
      mapView.hitTest(event).then((response) => {
        const result = response.results[0]
        if (!result || result.type !== 'graphic') return

        const { graphic } = result
        onGraphicClick(graphic)
      })
    })
  })
  return { map, mapView }
}
