import { BlockReportApi } from '@services/api'
import useVineyardStore from '@stores/vineyard'
import { useQuery } from '@tanstack/react-query'
import { ReportLevelsEnum, ReportTypeEnum } from '@typings/dtos/block-report/enums'
import { useEffect } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { useHandleAxiosError } from './useHandleAxiosError'

export function useGetRowBlockReportsApi(reportType: ReportTypeEnum) {
  const [selectedBlocks, startDate, endDate, blockAsMap, vineyardsAsMap] = useVineyardStore(
    useShallow((s) => [s.selectedBlocks, s.startDate, s.endDate, s.blocksAsMap, s.vineyardsAsMap])
  )

  const handleAxiosError = useHandleAxiosError()

  const queryKey =
    Object.keys(ReportTypeEnum).find((key: string) => ReportTypeEnum[key as keyof typeof ReportTypeEnum] === reportType) +
    'Reporting'

  const { data, refetch, isFetching, dataUpdatedAt, error } = useQuery({
    queryKey: [queryKey],
    initialData: [],
    queryFn: () =>
      BlockReportApi.get({
        reportType,
        reportLevels: [ReportLevelsEnum.Rows],
        blockIds: selectedBlocks.map((block) => block.id),
        startDate,
        endDate,
      }),
    enabled: false,
    select: (data) =>
      data.flatMap(({ rowData, id, blockId, vineyardId }) =>
        rowData
          ? rowData.map((row) => ({
              ...row,
              id,
              blockId,
              blockName: blockAsMap.get(blockId)?.name || 'Unknown Block',
              vineyardName: vineyardsAsMap.get(vineyardId)?.name || 'Unknown Vineyard',
            }))
          : []
      ),
  })

  useEffect(() => {
    if (error) handleAxiosError(error)
  }, [error, handleAxiosError])

  useEffect(() => {
    if (!selectedBlocks.length) return
    refetch()
  }, [selectedBlocks, startDate, endDate, refetch])

  return { data, isFetching, dataUpdatedAt }
}
