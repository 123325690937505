import SpatialReference from '@arcgis/core/geometry/SpatialReference'
import FeatureLayer from '@arcgis/core/layers/FeatureLayer'
import UniqueValueRenderer from '@arcgis/core/renderers/UniqueValueRenderer'
import SimpleMarkerSymbol from '@arcgis/core/symbols/SimpleMarkerSymbol'
import { PostMaterialEnum, VineStateIdEnum } from '@typings/dtos/block-report/enums'
import { MAP_CLR } from '@utils/constants'
import { VISUAL_VARIABLES } from '../constants'

const renderer = new UniqueValueRenderer({
  field: 'markerType',
  uniqueValueInfos: [
    {
      value: `vine_${VineStateIdEnum.Young}`,
      symbol: new SimpleMarkerSymbol({ color: MAP_CLR.YOUNG }),
    },
    {
      value: `vine_${VineStateIdEnum.Missing}`,
      symbol: new SimpleMarkerSymbol({ color: MAP_CLR.MISSING }),
    },
    {
      value: `vine_${VineStateIdEnum.Dead}`,
      symbol: new SimpleMarkerSymbol({ color: MAP_CLR.DEAD }),
    },
    {
      value: 'cane_0',
      symbol: new SimpleMarkerSymbol({ color: MAP_CLR.CANE_0 }),
    },
    {
      value: 'cane_1',
      symbol: new SimpleMarkerSymbol({ color: MAP_CLR.CANE_1 }),
    },
    {
      value: 'cane_2',
      symbol: new SimpleMarkerSymbol({ color: MAP_CLR.CANE_2 }),
    },
    {
      value: 'cane_3',
      symbol: new SimpleMarkerSymbol({ color: MAP_CLR.CANE_3 }),
    },
    {
      value: 'cordon_1',
      symbol: new SimpleMarkerSymbol({ color: MAP_CLR.CORDON_1 }),
    },
    {
      value: `post_${PostMaterialEnum.Wood}`,
      symbol: new SimpleMarkerSymbol({ color: MAP_CLR.WOOD }),
    },
    {
      value: `post_${PostMaterialEnum.Metal}`,
      symbol: new SimpleMarkerSymbol({ color: MAP_CLR.METAL }),
    },
    {
      value: `post_${PostMaterialEnum.Plastic}`,
      symbol: new SimpleMarkerSymbol({ color: MAP_CLR.PLASTIC }),
    },
    {
      value: 'post_missing',
      symbol: new SimpleMarkerSymbol({ color: MAP_CLR.MISSING }),
    },
  ],
  visualVariables: VISUAL_VARIABLES,
})

export const VineAndPostLayer = new FeatureLayer({
  id: 'vines-post-layer',
  title: 'Vines and Posts Layer',
  source: [],
  fields: [
    { name: 'id', type: 'integer' },
    { name: 'markerType', type: 'string' },
    { name: 'details', type: 'string' },
  ],
  objectIdField: 'objectId',
  geometryType: 'point',
  outFields: ['id', 'markerType', 'details'],
  spatialReference: SpatialReference.WGS84,
  renderer: renderer,
  popupEnabled: true,
  popupTemplate: {},
})
