import '@/global.css'
import SnackbarProvider from '@components/SnackbarProvider'
import { LicenseInfo } from '@mui/x-license-pro'
import '@services/locales/i18n'
import Router from '@services/router'
import ThemeProvider from '@services/theme'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
})

export default function App() {
  LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_X_LICENSE_KEY)

  // TODO: Remove this redirect once the new domain is fully propagated
  if (window.location.hostname === 'new.osiris.cropsy.tech') {
    window.location.replace('https://osiris.cropsy.tech' + window.location.pathname)
  }

  return (
    <ThemeProvider>
      <SnackbarProvider>
        <QueryClientProvider client={queryClient}>
          <Router />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </SnackbarProvider>
    </ThemeProvider>
  )
}
