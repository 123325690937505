import Iconify from '@components/data-display/Iconify'
import Box, { BoxProps } from '@mui/material/Box'
import { t } from 'i18next'

type DownloadReportButtonProps = {
  label: string
  onClick: VoidFunction
  disabled: boolean
} & BoxProps

const DownloadReportButton = ({ label, onClick, disabled, ...props }: DownloadReportButtonProps) => {
  return (
    <Box
      component="button"
      sx={{
        all: 'unset',
        height: 22,
        borderRadius: 1,
        backgroundColor: '#E7E5E4',
        color: '#52525B',
        fontWeight: 500,
        fontSize: 14,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        userSelect: 'none',
        cursor: disabled ? 'not-allowed' : 'pointer',
        ':hover': { backgroundColor: '#D6D3D1' },
        ':active': { backgroundColor: '#E7E5E4' },
        ':disabled': { backgroundColor: '#D6D3D1' },
      }}
      disabled={disabled}
      onClick={onClick}
      {...props}
    >
      <span style={{ padding: '0px 0.6rem' }}>{t(label)}</span>
      <Iconify icon="ic:baseline-download" color={disabled ? '#52525B' : 'primary.dark'} width={16} sx={{ mr: '8px' }} />
    </Box>
  )
}

export default DownloadReportButton
