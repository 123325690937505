import PruningStyleOptionsWrapper from '@components/containers/PruningStyleOptionsWrapper'
import DatagridNoRowsOverlay from '@components/data-display/DatagridNoRowsOverlay'
import CustomGridToolbar from '@components/datagrid/CustomGridToolbar'
import useDataGridLocaleText from '@hooks/useDataGridLocaleText'
import { useGetRowBlockReportsApi } from '@hooks/useGetRowBlockReportsApi'
import Paper from '@mui/material/Paper'
import { DataGridPro } from '@mui/x-data-grid-pro'
import useVineyardStore from '@stores/vineyard'
import { PruningStyleUnion } from '@typings/dtos/block'
import { ReportTypeEnum } from '@typings/dtos/block-report/enums'
import { RowData } from '@typings/dtos/block-report/models'
import { pinnedColumns } from '@utils/constants'
import { landCols, landColsGrouping } from '@utils/datagrid-cols/land-cols'
import { numberOfUniqueBlocks } from '@utils/utility-fns'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import { useShallow } from 'zustand/react/shallow'

const LandReportingPage = () => {
  const [bowData, setBowData] = useState<RowData[]>([])
  const [caneData, setCaneData] = useState<RowData[]>([])
  const [spurData, setSpurData] = useState<RowData[]>([])

  const [count, setCount] = useState<Record<PruningStyleUnion, number>>({ bow: 0, cane: 0, spur: 0 })

  const [canePrunedBlockIds, spurPrunedBlockIds, bowPrunedBlockIds, activePruningStyle] = useVineyardStore(
    useShallow((s) => [s.canePrunedBlockIds, s.spurPrunedBlockIds, s.bowPrunedBlockIds, s.activePruningStyle])
  )

  const { data, isFetching, dataUpdatedAt } = useGetRowBlockReportsApi(ReportTypeEnum.Land)
  const { localeText } = useDataGridLocaleText()

  useEffect(() => {
    const filterDataByPruningStyle = (pruningStyle: PruningStyleUnion) => {
      const blockIds = { cane: canePrunedBlockIds, spur: spurPrunedBlockIds, bow: bowPrunedBlockIds }[pruningStyle]

      return data?.filter((row) => blockIds.includes(row.blockId)) || []
    }

    if (data) {
      const bowData = filterDataByPruningStyle('bow')
      const caneData = filterDataByPruningStyle('cane')
      const spurData = filterDataByPruningStyle('spur')

      setBowData(bowData)
      setCaneData(caneData)
      setSpurData(spurData)

      setCount({
        cane: numberOfUniqueBlocks(caneData),
        spur: numberOfUniqueBlocks(spurData),
        bow: numberOfUniqueBlocks(bowData),
      })
    }
  }, [data, bowPrunedBlockIds, canePrunedBlockIds, spurPrunedBlockIds])

  const dataByPruningStyle = { bow: bowData, cane: caneData, spur: spurData }

  return (
    <>
      <PruningStyleOptionsWrapper blocksCountByPruningStyle={count} sx={{ pb: 2 }} />
      <Paper sx={{ borderRadius: 1, height: 'calc(100vh - 144px)', overflow: 'hidden' }} variant="outlined">
        <DataGridPro
          key={dataUpdatedAt}
          rows={dataByPruningStyle[activePruningStyle] || []}
          columns={landCols(activePruningStyle)}
          columnGroupingModel={landColsGrouping()}
          loading={isFetching}
          initialState={pinnedColumns}
          getRowId={(r) => r.id + r.blockId.toString() + r.rowId.toString()}
          columnHeaderHeight={40}
          disableColumnMenu
          hideFooter
          slots={{
            noRowsOverlay: () => <DatagridNoRowsOverlay data={data} />,
            toolbar: () => (
              <CustomGridToolbar
                fileName={`Land Report - ${format(new Date(), 'dd MMM yyyy')}`}
                rowCount={dataByPruningStyle[activePruningStyle].length}
              />
            ),
          }}
          localeText={localeText}
        />
      </Paper>
    </>
  )
}

export default LandReportingPage
