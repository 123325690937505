import { IOption } from '@typings/common'
import { BlockPruningStyleEnum, PruningStyleUnion, Variety } from '@typings/dtos/block'
import { VineyardDto } from '@typings/dtos/vineyard'
import { VineyardStoreActions, VineyardStoreState } from '@typings/store'
import { DEFAULT_END_DATE, DEFAULT_START_DATE } from '@utils/constants'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

const useVineyardStore = create<VineyardStoreState & VineyardStoreActions>()(
  devtools((set, get) => ({
    vineyards: [],
    setVineyards: (vineyards) => {
      const vineyardsAsMap = new Map()
      for (const vineyard of vineyards) {
        vineyardsAsMap.set(vineyard.id, vineyard)
      }
      set({ vineyards, vineyardsForFilter: vineyards, vineyardsAsMap })
    },

    vineyardsAsMap: new Map(),

    vineyardsForFilter: [],
    setVineyardsForFilter: (vineyards) => set({ vineyardsForFilter: vineyards }),

    selectedVineyards: [],
    setSelectedVineyards: (vineyards) => set({ selectedVineyards: vineyards }),

    selectedVineyardsLocal: [],
    setSelectedVineyardsLocal: (vineyards) => set({ selectedVineyardsLocal: vineyards }),

    //===================================================================================================

    varieties: [],
    setVarieties: (varieties) => set({ varieties, varietiesForFilter: varieties }),

    varietiesForFilter: [],
    setVarietiesForFilter: (varieties) => set({ varietiesForFilter: varieties }),

    selectedVarieties: [],
    setSelectedVarieties: (varieties) => set({ selectedVarieties: varieties }),

    selectedVarietiesLocal: [],
    setSelectedVarietiesLocal: (varieties) => set({ selectedVarietiesLocal: varieties }),

    //====================================================================================================

    blocks: [],
    setBlocks: (blocks) => {
      const blocksAsMap = new Map()
      for (const block of blocks) {
        blocksAsMap.set(block.id, block)
      }

      /* Creating varieties out of list of blocks */
      const varieties = blocks.map((block) => block.variety)
      const uniqueVarietiesMap = new Map<number, Variety>()
      varieties.forEach((variety) => uniqueVarietiesMap.set(variety.id, variety))
      const uniqueVarieties = Array.from(uniqueVarietiesMap.values())

      set({
        blocks,
        blocksForFilter: blocks,
        blocksAsMap,
        varieties: uniqueVarieties,
        varietiesForFilter: uniqueVarieties,
      })
    },

    blocksForFilter: [],
    setBlocksForFilter: (blocks) => set({ blocksForFilter: blocks }),

    selectedBlocks: [],
    setSelectedBlocks: (blocks) => set({ selectedBlocks: blocks }),

    selectedBlocksLocal: [],
    setSelectedBlocksLocal: (blocks) => {
      const newVineyardIds = new Set(blocks.map((block) => block.vineyardId))
      const newVarietyIds = new Set(blocks.map((block) => block.varietyId))
      // Find corresponding VineyardDto and VarietyDto objects
      const { vineyards, varieties, selectedVineyardsLocal, selectedVarietiesLocal } = get()
      const newVineyards = vineyards.filter((vineyard) => newVineyardIds.has(vineyard.id))
      const newVarieties = varieties.filter((variety) => newVarietyIds.has(variety.id))

      // Merge new VineyardDto and VarietyDto objects with the existing selections
      // This will eliminate duplicates based on the 'id' property
      const mergeAndDeduplicate = (existing: VineyardDto[] | Variety[], additional: VineyardDto[] | Variety[]) => {
        const uniqueMap = new Map()
        existing.concat(additional).forEach((item) => uniqueMap.set(item.id, item))
        return Array.from(uniqueMap.values())
      }

      const updatedSelectedVineyards = mergeAndDeduplicate(selectedVineyardsLocal, newVineyards)
      const updatedSelectedVarieties = mergeAndDeduplicate(selectedVarietiesLocal, newVarieties)

      // Update state with the merged selections
      const { setSelectedVineyardsLocal, setSelectedVarietiesLocal } = get()
      setSelectedVineyardsLocal(updatedSelectedVineyards)
      setSelectedVarietiesLocal(updatedSelectedVarieties)

      set({ selectedBlocksLocal: blocks })
    },

    blocksAsMap: new Map(),

    bowPrunedBlockIds: [],
    canePrunedBlockIds: [],
    spurPrunedBlockIds: [],
    pruningStyleOptions: [],

    setBlockIdsByPruningStyle: (blocks) => {
      const bowPrunedBlockIds: number[] = []
      const canePrunedBlockIds: number[] = []
      const spurPrunedBlockIds: number[] = []
      const pruningStyleOptions: IOption<PruningStyleUnion>[] = []

      // Find block ids for each pruning style
      for (const block of blocks) {
        if (block.pruningStyleId === BlockPruningStyleEnum.HighBow || block.pruningStyleId === BlockPruningStyleEnum.LowBow) {
          bowPrunedBlockIds.push(block.id)
        } else if (block.pruningStyleId === BlockPruningStyleEnum.VSP) {
          canePrunedBlockIds.push(block.id)
        } else if (block.pruningStyleId === BlockPruningStyleEnum.Spur) {
          spurPrunedBlockIds.push(block.id)
        }
      }

      // Create pruning style options
      if (canePrunedBlockIds.length) pruningStyleOptions.push({ label: 'cane_pruned', value: 'cane' })
      if (spurPrunedBlockIds.length) pruningStyleOptions.push({ label: 'spur_pruned', value: 'spur' })
      if (bowPrunedBlockIds.length) pruningStyleOptions.push({ label: 'bow_pruned', value: 'bow' })

      set({ bowPrunedBlockIds, canePrunedBlockIds, spurPrunedBlockIds, pruningStyleOptions })
    },

    //====================================================================================================

    startDate: DEFAULT_START_DATE,
    setStartDate: (startDate) => set({ startDate }),

    endDate: DEFAULT_END_DATE,
    setEndDate: (endDate) => set({ endDate }),

    //====================================================================================================

    startDateLocal: DEFAULT_START_DATE,
    setStartDateLocal: (startDate) => set({ startDateLocal: startDate }),

    endDateLocal: DEFAULT_END_DATE,
    setEndDateLocal: (endDate) => set({ endDateLocal: endDate }),

    //====================================================================================================

    rowStatsDisease: [],
    setRowStatsDisease: (rowStatsDisease) => set({ rowStatsDisease }),

    favouriteFilters: localStorage.getItem('favouriteFilters') ? JSON.parse(localStorage.getItem('favouriteFilters')!) : [],
    setFavouriteFilters: (favouriteFilters) => {
      // save to local storage
      localStorage.setItem('favouriteFilters', JSON.stringify(favouriteFilters))
      // update state
      set({ favouriteFilters: favouriteFilters })
    },

    blocksGroupByScanDate: [],
    setBlocksGroupByScanDate: (blocksGroupByScanDate) => set({ blocksGroupByScanDate }),

    activePruningStyle: 'cane',
    setActivePruningStyle: (pruningStyle) => set({ activePruningStyle: pruningStyle }),

    landPostsOrVines: 'posts',
    setLandPostsOrVines: (landPostsOrVines) => set({ landPostsOrVines }),

    //====================================================================================================
    detectionToShow: ['pm'],
    setDetectionToShow: (detectionToShow) => set({ detectionToShow }),
  }))
)

export default useVineyardStore
