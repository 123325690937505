import { Text, View } from '@react-pdf/renderer'
import { BlockReport, RowData } from '@typings/dtos/block-report/models'
import { fmtNumToThousandSepSingleDecOrZero } from '@utils/utility-fns'
import { useEffect, useState } from 'react'
import TableHead from './TableHead'
import { s } from './styles'

type BestWorstPrunedRowsProps = {
  report: BlockReport
}

const BestWorstPrunedRows = ({ report }: BestWorstPrunedRowsProps) => {
  const [bestPrunedRows, setBestPrunedRows] = useState<BlockReport['rowData']>([])
  const [worstPrunedRows, setWorstPrunedRows] = useState<BlockReport['rowData']>([])

  useEffect(() => {
    if (!report) return
    const { rowData } = report

    const tenBestAndWorstPrunedRows = () => {
      if (!rowData.length) return { best: [], worst: [] }

      const sortedCaneData = rowData.slice().sort((a, b) => {
        const aValue = a.prunedToTargetPct ?? Number.MAX_VALUE
        const bValue = b.prunedToTargetPct ?? Number.MAX_VALUE
        return aValue - bValue
      })

      const sliceRange = Math.min(10, Math.floor(sortedCaneData.length / 2))
      const best = sortedCaneData.slice(-sliceRange).reverse()
      const worst = sortedCaneData.slice(0, sliceRange)

      return { best, worst }
    }

    const { best, worst } = tenBestAndWorstPrunedRows()

    setBestPrunedRows(best)
    setWorstPrunedRows(worst)
  }, [report])

  const { caneTarget } = report.blockData || { caneTarget: 1 }

  const Row = ({ row, index }: { row: RowData; index: number }) => {
    return (
      <View key={index} style={{ height: 18, backgroundColor: index % 2 === 0 ? 'white' : '#F1F5F9', flexDirection: 'row' }}>
        <View style={s.row}>
          <Text>{fmtNumToThousandSepSingleDecOrZero(row.rowNum)}</Text>
        </View>
        <View style={s.row}>
          <Text>{fmtNumToThousandSepSingleDecOrZero(row.prunedToTargetPct)}%</Text>
        </View>
        {caneTarget === 4 && (
          <View style={s.row}>
            <Text>{fmtNumToThousandSepSingleDecOrZero(row.cane4)}</Text>
          </View>
        )}
        {caneTarget >= 3 && (
          <View style={s.row}>
            <Text>{fmtNumToThousandSepSingleDecOrZero(row.cane3)}</Text>
          </View>
        )}
        {caneTarget >= 2 && (
          <View style={s.row}>
            <Text>{fmtNumToThousandSepSingleDecOrZero(row.cane2)}</Text>
          </View>
        )}
        <View style={s.row}>
          <Text>{fmtNumToThousandSepSingleDecOrZero(row.cane1)}</Text>
        </View>
        <View style={s.row}>
          <Text>{fmtNumToThousandSepSingleDecOrZero(row.budsPerVineMean)}</Text>
        </View>
        <View style={s.row}>
          <Text>{fmtNumToThousandSepSingleDecOrZero(row.budsPerCaneMean)}</Text>
        </View>
      </View>
    )
  }

  return (
    <View style={{ width: '100%' }}>
      <Text style={{ marginBottom: 2 }}>BEST PRUNED ROWS</Text>
      <TableHead assumedCaneTarget={caneTarget} />
      {bestPrunedRows.map((row, index) => (
        <Row key={index} row={row} index={index} />
      ))}
      <View style={{ height: 30 }} />
      <Text style={{ marginBottom: 2 }}>WORST PRUNED ROWS</Text>

      <TableHead assumedCaneTarget={caneTarget} />
      {worstPrunedRows.map((row, index) => {
        return <Row key={index} row={row} index={index} />
      })}
    </View>
  )
}

export default BestWorstPrunedRows
