import {
  blockLabelClass,
  blockLabelWithDateClass,
  blockLayer,
  blockSimpleRenderer,
  blockUniqueValueRenderer,
} from '@/arcgis/layers'
import { useTranslate } from '@hooks/useLocales'
import Collapse from '@mui/material/Collapse'
import Divider from '@mui/material/Divider'
import FormControlLabel from '@mui/material/FormControlLabel'
import Paper from '@mui/material/Paper'
import Switch from '@mui/material/Switch'
import { useState } from 'react'
import ScanRecencyLegend from './ScanRecencyLegend'

const ScanRecencyToggle = () => {
  const [showRecency, setShowRecency] = useState(true)

  const { t } = useTranslate()

  const toggleBackground = (layer: __esri.FeatureLayer) => {
    const currentRenderer = layer.renderer

    if (currentRenderer === blockUniqueValueRenderer) {
      layer.renderer = blockSimpleRenderer
      layer.labelingInfo = [blockLabelClass]
      setShowRecency(false)
    } else {
      layer.renderer = blockUniqueValueRenderer
      layer.labelingInfo = [blockLabelWithDateClass]
      setShowRecency(true)
    }
  }

  return (
    <Paper sx={{ borderRadius: 0, bgcolor: 'grey.100', position: 'absolute', left: 6, top: 6, maxHeight: 91, width: 220 }}>
      <FormControlLabel
        control={<Switch checked={showRecency} onChange={() => toggleBackground(blockLayer)} />}
        label={t('scan_recency')}
        labelPlacement="start"
        sx={{ '&.MuiFormControlLabel-root': { ml: 1 }, textTransform: 'uppercase' }}
        componentsProps={{ typography: { sx: { color: 'info.dark', fontSize: 13, fontWeight: 500, userSelect: 'none' } } }}
      />
      <Divider />
      <Collapse in={showRecency}>
        <ScanRecencyLegend />
      </Collapse>
    </Paper>
  )
}

export default ScanRecencyToggle
