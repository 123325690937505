import SummaryFiltersWrapper from '@components/containers/SummaryFiltersWrapper'
import { useTranslate } from '@hooks/useLocales'
import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import OutlinedInput from '@mui/material/OutlinedInput'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { SUMMARY_LABEL_CHIP_STYLES } from '@services/theme/styles'
import { DiseaseStats } from '@typings/dtos/block-report/models'
import {
  DiseaseOptions,
  HealthBlockReport_SortOptions,
  HealthBlockReport_Trunk_SortOptions,
  HealthBlockReportSortOptions,
} from '@utils/options'
import { isValidElement, ReactNode } from 'react'

type HealthFiltersSectionProps = {
  showDiseases: Array<keyof DiseaseStats>
  setShowDiseases: (diseases: Array<keyof DiseaseStats>) => void
  sort: string
  setSort: (sort: HealthBlockReportSortOptions) => void
}

const HealthFiltersSection = ({ showDiseases, setShowDiseases, sort, setSort }: HealthFiltersSectionProps) => {
  const { t } = useTranslate()

  const selectedOptions = (selected: (keyof DiseaseStats)[]) =>
    DiseaseOptions.filter((option) => selected.includes(option.value))
      .map((option) => t(option.label))
      .join(', ')

  const onChange = (option: SelectChangeEvent<(keyof DiseaseStats)[]>, child: ReactNode) => {
    if (option.target.value.length === 0) return

    if (isValidElement(child)) {
      if (child.props.value === 'trunkDeadVines') {
        if (option.target.value.includes('trunkDeadVines')) {
          setSort('high-trunk-infected-vines-percentage')
          return setShowDiseases(['trunkDeadVines'])
        }
        return
      } else {
        // remove trunkInfectedVines if it exists and add the rest
        if (option.target.value.includes('trunkDeadVines')) {
          setSort('high-infected-vines')
          return setShowDiseases((option.target.value as (keyof DiseaseStats)[]).filter((value) => value !== 'trunkDeadVines'))
        }
        return setShowDiseases(option.target.value as (keyof DiseaseStats)[])
      }
    }
  }

  // const trunkDiseaseOption = DiseaseOptions.at(-1)!
  const isTrunkDiseaseSelected = showDiseases.includes('trunkDeadVines')

  return (
    <SummaryFiltersWrapper
      label={isTrunkDiseaseSelected ? t('infected_vines') + ' ( % )' : t('infected_vines')}
      legends={
        <>
          <div style={{ ...SUMMARY_LABEL_CHIP_STYLES, backgroundColor: '#86EFAC', width: '6rem' }}>
            {isTrunkDiseaseSelected ? <>0.1&mdash;5</> : <>1&mdash;50</>}
          </div>
          <div style={{ ...SUMMARY_LABEL_CHIP_STYLES, backgroundColor: '#FDE047', width: '6rem' }}>
            {isTrunkDiseaseSelected ? <>5&mdash;20</> : <>51&mdash;200</>}
          </div>
          <div style={{ ...SUMMARY_LABEL_CHIP_STYLES, backgroundColor: '#FCA5A5', width: '6rem' }}>
            {isTrunkDiseaseSelected ? '20+' : '200+'}
          </div>
        </>
      }
      rightSection={
        <>
          <FormControl>
            <InputLabel id="select-diseases-label" shrink={true} sx={{ fontWeight: 500, textTransform: 'uppercase' }}>
              {t('diseases')}
            </InputLabel>
            <Select
              labelId="select-diseases-label"
              id="select-diseases"
              multiple
              value={showDiseases}
              onChange={onChange}
              renderValue={(selected) => selectedOptions(selected as (keyof DiseaseStats)[])}
              input={<OutlinedInput label="DISEASESi" sx={{ fontWeight: 500, textTransform: 'uppercase' }} />}
              sx={{ width: 230, height: 40, bgcolor: '#fff', color: '#334155', fontSize: 15 }}
            >
              {DiseaseOptions.slice(0, -1).map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  <Checkbox checked={showDiseases.indexOf(option.value) > -1} />
                  <ListItemText primary={t(option.label)} />
                </MenuItem>
              ))}
              {/* <div style={{ backgroundColor: '#E5E5E5', padding: 3 }}></div>
              <MenuItem key={trunkDiseaseOption.value} value={trunkDiseaseOption.value}>
                <Checkbox checked={showDiseases.indexOf(trunkDiseaseOption.value) > -1} />
                <ListItemText primary={t(trunkDiseaseOption.label)} />
              </MenuItem> */}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel id="select-diseases-sort-label" shrink={true} sx={{ fontWeight: 500, textTransform: 'uppercase' }}>
              {t('sort')}
            </InputLabel>
            <Select
              labelId="select-diseases-sort-label"
              id="select-diseases-sort"
              value={sort}
              onChange={(e) => setSort(e.target.value as HealthBlockReportSortOptions)}
              input={<OutlinedInput label="SORT" sx={{ fontWeight: 500, textTransform: 'uppercase' }} />}
              sx={{ width: 230, height: 40, bgcolor: '#fff', color: '#334155', fontSize: 15 }}
            >
              {isTrunkDiseaseSelected
                ? HealthBlockReport_Trunk_SortOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {t(option.label)}
                    </MenuItem>
                  ))
                : HealthBlockReport_SortOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {t(option.label)}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
        </>
      }
    />
  )
}

export default HealthFiltersSection
