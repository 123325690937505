import BillingPageWrapper from '@components/containers/BillingPageWrapper'
import HelmetWrapper from '@components/containers/HelmetWrapper'
import Layout from '@components/layout'
import { useHandleJwtTokenExpiry } from '@hooks/useHandleJwtTokenExpiry'
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'
import Auth from '@pages/Auth'
import Playground from '@pages/Playground'
import SettingsPage from '@pages/SettingsPage'
import HealthReportingPage from '@pages/health/HealthReportingPage'
import HealthSummaryPage from '@pages/health/HealthSummaryPage'
import LandMapPage from '@pages/land/LandMapPage'
import LandReportingPage from '@pages/land/LandReportingPage'
import LandSummaryPage from '@pages/land/LandSummaryPage'
import PruningMapPage from '@pages/pruning/PruningMapPage'
import PruningReportingPage from '@pages/pruning/PruningReportingPage'
import PruningSummaryPage from '@pages/pruning/PruningSummaryPage'
import YieldReportingPage from '@pages/yield/YieldReportingPage'
import YieldSummaryPage from '@pages/yield/YieldSummaryPage'
import useAuthStore from '@stores/auth'
import { getLandingPage } from '@utils/utility-fns'
import { Suspense, lazy } from 'react'
import { Navigate, Outlet, useRoutes } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'

const HealthMap = lazy(() => import('@pages/health/HealthMapPage'))
const BillingPage = lazy(() => import('@pages/Billing'))

export default function Router() {
  const [isAuthenticated, user] = useAuthStore(useShallow((s) => [s.isAuthenticated, s.user]))

  const landingPage = '/' + getLandingPage(user?.countryCode)
  const { isPending } = useHandleJwtTokenExpiry()

  return useRoutes([
    { path: '/*', element: <Navigate to="/auth" /> },
    { path: '/auth', element: <Auth /> },
    // { path: '/playground', element: <Playground /> },
    {
      path: '/',
      element: isPending ? (
        <Box display="flex" height="100vh" alignItems="center" justifyContent="center">
          <LinearProgress sx={{ width: 300 }} />
        </Box>
      ) : isAuthenticated() ? (
        <Layout>
          <Outlet />
        </Layout>
      ) : (
        <Navigate to="/auth" />
      ),
      children: [
        {
          path: '/playground',
          element: (
            <HelmetWrapper title="Playground">
              <Playground />
            </HelmetWrapper>
          ),
        },
        { path: '/', element: <Navigate to={landingPage} /> },
        {
          path: 'settings',
          element: <HelmetWrapper title="Settings - Osiris | Cropsy Technologies">{<SettingsPage />}</HelmetWrapper>,
        },
        {
          path: 'billing',
          element: (
            <BillingPageWrapper>
              <Suspense fallback={<pre>Loading...</pre>}>
                <BillingPage />
              </Suspense>
            </BillingPageWrapper>
          ),
        },
        {
          path: 'health',
          element: <HelmetWrapper title="Health - Osiris | Cropsy Technologies">{<Outlet />}</HelmetWrapper>,
          children: [
            { path: '/health', element: <Navigate to="reporting" /> },
            {
              path: 'map',
              element: (
                <Suspense fallback={<pre>Loading Map...</pre>}>
                  <HealthMap />
                </Suspense>
              ),
            },
            { path: 'summary', element: <HealthSummaryPage /> },
            { path: 'reporting', element: <HealthReportingPage /> },
          ],
        },
        {
          path: 'yield',
          element: <HelmetWrapper title="Yield - Osiris | Cropsy Technologies">{<Outlet />}</HelmetWrapper>,
          children: [
            { path: '/yield', element: <Navigate to="reporting" /> },
            { path: 'reporting', element: <YieldReportingPage /> },
            { path: 'summary', element: <YieldSummaryPage /> },
          ],
        },
        {
          path: 'pruning',
          element: <HelmetWrapper title="Pruning - Osiris | Cropsy Technologies">{<Outlet />}</HelmetWrapper>,
          children: [
            { path: '/pruning', element: <Navigate to="reporting" /> },
            {
              path: 'map',
              element: (
                <Suspense fallback={<pre>Loading Map...</pre>}>
                  <PruningMapPage />
                </Suspense>
              ),
            },
            { path: 'reporting', element: <PruningReportingPage /> },
            { path: 'summary', element: <PruningSummaryPage /> },
          ],
        },
        {
          path: 'land',
          element: <HelmetWrapper title="Land - Osiris | Cropsy Technologies">{<Outlet />}</HelmetWrapper>,
          children: [
            { path: '/land', element: <Navigate to="reporting" /> },
            {
              path: 'map',
              element: (
                <Suspense fallback={<pre>Loading Map...</pre>}>
                  <LandMapPage />
                </Suspense>
              ),
            },
            { path: 'reporting', element: <LandReportingPage /> },
            { path: 'summary', element: <LandSummaryPage /> },
          ],
        },
      ],
    },
  ])
}
