import { Image, Text, View } from '@react-pdf/renderer'
import { BlockData } from '@typings/dtos/block-report/models'
import { fmtNumToThousandSepSingleDecOrZero } from '@utils/utility-fns'

type ScannedRowsProps = {
  blockData: BlockData | undefined
}

const ScannedRows = ({ blockData }: ScannedRowsProps) => {
  const { rowsScanned } = blockData || { rowsScanned: [] }
  return (
    <View style={{ marginTop: 'auto', fontSize: 9 }}>
      <Text style={{ color: '#1E293B', fontWeight: 200 }}>
        Rows Scanned ( {fmtNumToThousandSepSingleDecOrZero(rowsScanned.length)} ):
      </Text>
      <Text style={{ color: '#475569' }}>{rowsScanned.map((row) => row.rowNum).join(', ')}</Text>
      <Text style={{ color: '#1E293B', fontWeight: 200, marginTop: 8 }}>
        Vines Excluded ( {fmtNumToThousandSepSingleDecOrZero((blockData?.vUnknown ?? 0) + (blockData?.vBadQuality ?? 0))} ):
      </Text>
      <Text style={{ color: '#475569' }}>
        {`${blockData?.vUnknown + ' (Unknown), '}`} {`${blockData?.vBadQuality + ' (Bad Quality)'}`}
      </Text>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 10, alignItems: 'flex-end' }}>
        <Text render={({ pageNumber }) => `${pageNumber}`} style={{ color: 'lightgrey' }} />
        <Image
          src="/logos/head-logo.png"
          style={{ height: 20, width: 50, objectFit: 'contain', position: 'absolute', bottom: -2, right: 0 }}
        />
      </View>
    </View>
  )
}

export default ScannedRows
