import { useHandleAxiosError } from '@hooks/useHandleAxiosError'
import { BlockReportApi } from '@services/api'
import useVineyardStore from '@stores/vineyard'
import { useQuery } from '@tanstack/react-query'
import { BlockReportsByBlock } from '@typings/component'
import { PruningStyleUnion } from '@typings/dtos/block'
import { ReportLevelsEnum, ReportTypeEnum } from '@typings/dtos/block-report/enums'
import { MapDataDTO } from '@typings/dtos/block-report/map-data'
import { BlockReport } from '@typings/dtos/block-report/models'

import { decompressGzipToJson, decryptAES } from '@utils/data-utils'
import { numberOfUniqueBlocks } from '@utils/utility-fns'
import { useEffect, useState } from 'react'
import { useShallow } from 'zustand/react/shallow'

type UseGetMapPageDataParams = {
  reportType: ReportTypeEnum
  queryKey: string
}

export function useGetMapPageData({ reportType, queryKey }: UseGetMapPageDataParams) {
  const [reports, setReports] = useState<Record<PruningStyleUnion, BlockReportsByBlock[]>>({ cane: [], bow: [], spur: [] })
  const [count, setCount] = useState<Record<PruningStyleUnion, number>>({ cane: 0, bow: 0, spur: 0 })

  const [selectedBlocks, startDate, endDate, bowPrunedBlockIds, canePrunedBlockIds, spurPrunedBlockIds] = useVineyardStore(
    useShallow((s) => [s.selectedBlocks, s.startDate, s.endDate, s.bowPrunedBlockIds, s.canePrunedBlockIds, s.spurPrunedBlockIds])
  )

  const handleAxiosError = useHandleAxiosError()

  const {
    data: res,
    refetch,
    isFetching,
    error,
  } = useQuery({
    queryKey: [queryKey],
    initialData: [],
    queryFn: () =>
      BlockReportApi.getMapData<BlockReport>({
        reportType: reportType,
        reportLevels: [ReportLevelsEnum.Block, ReportLevelsEnum.Vines, ReportLevelsEnum.Rows],
        blockIds: selectedBlocks.map((block) => block.id),
        startDate,
        endDate,
      }),
    enabled: false,
    select: (data) => {
      const result = []
      for (const report of data) {
        try {
          result.push({ ...report, mapData: decompressGzipToJson<MapDataDTO[]>(decryptAES(report.mapData, report.iv)) })
        } catch (error) {
          console.error(`Error decompressing data for report: ${report.id}`, error)
          result.push(report)
        }
      }
      // console.log('Unpacked BR MapData:', result)
      return result
    },
  })

  useEffect(() => {
    if (error) handleAxiosError(error)
  }, [error, handleAxiosError])

  useEffect(() => {
    if (!selectedBlocks.length) return
    refetch()
  }, [selectedBlocks, startDate, endDate, refetch])

  useEffect(() => {
    if (!res) return

    const reportsByBlock = selectedBlocks.map((block) => {
      const _reports = res.filter((cluster) => cluster.blockId === block.id)

      if (!_reports.length) return { blockId: block.id, blockName: block.name, reports: [] }

      const reports = _reports.map((r) => {
        // const blockName = blocksAsMap.get(cluster.blockId)?.name || `ID: ${cluster.blockId}`
        return { ...r, blockName: block.name }
      })

      return { blockId: block.id, blockName: block.name, reports }
    })

    const reportsWithClusters = reportsByBlock.filter((block) => block.reports?.length)

    const filterDataByPruningStyle = (pruningStyle: PruningStyleUnion) => {
      const blockIds = { bow: bowPrunedBlockIds, cane: canePrunedBlockIds, spur: spurPrunedBlockIds }[pruningStyle]

      return reportsWithClusters.filter((row) => blockIds.includes(row.blockId)) || []
    }

    if (res) {
      const caneData = filterDataByPruningStyle('cane')
      const bowData = filterDataByPruningStyle('bow')
      const spurData = filterDataByPruningStyle('spur')

      setReports({ cane: caneData, bow: bowData, spur: spurData })
      setCount({ cane: numberOfUniqueBlocks(caneData), bow: numberOfUniqueBlocks(bowData), spur: numberOfUniqueBlocks(spurData) })
    }
  }, [res, selectedBlocks])

  return { reports, isFetching, count }
}
