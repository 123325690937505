import useVineyardStore from '@stores/vineyard'
import { DEFAULT_END_DATE, DEFAULT_START_DATE } from '@utils/constants'
import { useEffect, useState } from 'react'
import { useShallow } from 'zustand/react/shallow'

export function useIsChangesSaved() {
  const [isPageLoaded, setIsPageLoaded] = useState(false)
  const [isChangesSaved, setIsChangesSaved] = useState(true)

  const [selectedVineyardsLocal, selectedVarietiesLocal, selectedBlocksLocal, startDateLocal, endDateLocal] = useVineyardStore(
    useShallow((s) => [
      s.selectedVineyardsLocal,
      s.selectedVarietiesLocal,
      s.selectedBlocksLocal,
      s.startDateLocal,
      s.endDateLocal,
    ])
  )

  useEffect(() => {
    setIsPageLoaded(true)
  }, [])

  useEffect(() => {
    if (!isPageLoaded) {
      return
    } else if (
      isPageLoaded &&
      selectedVineyardsLocal.length === 0 &&
      selectedVarietiesLocal.length === 0 &&
      selectedBlocksLocal.length === 0 &&
      startDateLocal === DEFAULT_START_DATE &&
      endDateLocal === DEFAULT_END_DATE
    ) {
      return
    } else {
      setIsChangesSaved(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVineyardsLocal, selectedVarietiesLocal, selectedBlocksLocal, startDateLocal, endDateLocal])

  return { isChangesSaved, setIsChangesSaved }
}
