import { GetBlockReportParams, GetRowStatsParams } from '@typings/api-custom'
import { BillDTO, BillingBracketDTO } from '@typings/dtos/billing/models'
import { BlockDto, BlockRowsDTO, BlockScanDateDto } from '@typings/dtos/block'
import { BlockReport } from '@typings/dtos/block-report/models'
import { EvidenceDto } from '@typings/dtos/evidence'
import { ChangePasswordDto, UserSettingsDTO } from '@typings/dtos/user'
import { BlockVinesDto, VineAttributeLookup } from '@typings/dtos/vine'
import { RowStatsDiseaseDto, RowStatsPruningDto, RowStatsYieldDto } from '@typings/dtos/vine-row-stats'
import { RowStatsAttributeSet } from '@typings/dtos/vine-row-stats/row-stats-attribute-set.enum'
import { VineyardDto } from '@typings/dtos/vineyard'
import { cropsy, imgman } from './axios-instances'

export const UserApi = {
  refreshToken: () => cropsy.post('/auth/refresh-token', {}),

  changePassword: (data: ChangePasswordDto) => cropsy.post('/new-password', data),

  getUserSettings: (): Promise<UserSettingsDTO> => cropsy.get('/user-settings'),

  postUserSettings: (data: UserSettingsDTO): Promise<UserSettingsDTO> => cropsy.post('/user-settings', data),
}

export const VineyardApi = {
  getVineyards: (): Promise<VineyardDto[]> => cropsy.get('/vineyards'),
}

export const BlockApi = {
  getBlocks: (): Promise<BlockDto[]> => cropsy.get('/blocks'),

  getBlocksScanned: (): Promise<BlockScanDateDto[]> => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    return cropsy.get(`/blocks/scanned?timezone=${timezone}`)
  },

  getBlocksRows: (blockIds: number[]): Promise<BlockRowsDTO[]> => cropsy.get(`/blocks/rows?blockIds=${blockIds.join(',')}`),
}

export const VineRowStatsApi = {
  getRowStatsDisease: ({ blockIds, dateStart, dateEnd, qualityFilter }: GetRowStatsParams): Promise<RowStatsDiseaseDto[]> =>
    cropsy.get(
      `/rowstats?blockIds=${blockIds}&attributeSet=${RowStatsAttributeSet.Disease}&dateStart=${encodeURIComponent(
        dateStart
      )}&dateEnd=${encodeURIComponent(dateEnd)}&qualityFilter=${qualityFilter}&uniqueRows=true`
    ),

  getRowStatsPruning: ({ blockIds, dateStart, dateEnd, qualityFilter }: GetRowStatsParams): Promise<RowStatsPruningDto[]> =>
    cropsy.get(
      `/rowstats?blockIds=${blockIds}&attributeSet=${RowStatsAttributeSet.Pruning}&dateStart=${encodeURIComponent(
        dateStart
      )}&dateEnd=${encodeURIComponent(dateEnd)}&qualityFilter=${qualityFilter}&uniqueRows=true`
    ),

  getRowStatsYield: ({ blockIds, dateStart, dateEnd, qualityFilter }: GetRowStatsParams): Promise<RowStatsYieldDto[]> =>
    cropsy.get(
      `/rowstats?blockIds=${blockIds}&attributeSet=${RowStatsAttributeSet.Yield}&dateStart=${encodeURIComponent(
        dateStart
      )}&dateEnd=${encodeURIComponent(dateEnd)}&qualityFilter=${qualityFilter}&uniqueRows=true`
    ),
}

export const VineApi = {
  // getVineAttributesByBlockId: (blockId: number): Promise<VineDto[]> =>
  //   cropsy.get(`/vines/block/${blockId}?attr=Powdery Mildew Count&dateStart=2022-06-01`),

  getRowsVinesAttributesByBlockId: (
    blockId: number,
    dateStart: string,
    dateEnd: string,
    attr: VineAttributeLookup[]
  ): Promise<BlockVinesDto> => {
    const attrString = attr.join(',')
    return cropsy.get(
      `/osiris/vines/block/${blockId}?attr=${attrString}&dateStart=${encodeURIComponent(
        dateStart
      )}&dateEnd=${encodeURIComponent(dateEnd)}`
    )
  },
}

export const BlockReportApi = {
  get: ({ reportType, reportLevels, blockIds, startDate, endDate }: GetBlockReportParams): Promise<BlockReport[]> =>
    cropsy.get(
      `/blockreports/${reportType}?reportLevels=${reportLevels.join(',')}&BlockIds=${blockIds.join(',')}&dateStart=${encodeURIComponent(startDate)}&dateEnd=${encodeURIComponent(endDate)}`
    ),

  getMapData: <T>({ reportType, reportLevels, blockIds, startDate, endDate }: GetBlockReportParams): Promise<T[]> =>
    cropsy.get(
      `/blockreports/map/${reportType}?reportLevels=${reportLevels.join(',')}&BlockIds=${blockIds.join(',')}&dateStart=${encodeURIComponent(startDate)}&dateEnd=${encodeURIComponent(endDate)}`
    ),
}

// export const BlockReportApi = {
//   get: ({ reportType, reportLevels, blockIds, startDate, endDate }: GetBlockReportParams): Promise<BlockReport[]> => {
//     console.log('reportType', reportType)
//     return cropsy.get(
//       `/stagedreports?reportLevels=${reportLevels.join(',')}&BlockIds=${blockIds.join(',')}&dateStart=${encodeURIComponent(startDate)}&dateEnd=${encodeURIComponent(endDate)}`
//     )
//   },

//   getMapData: <T>({ reportType, reportLevels, blockIds, startDate, endDate }: GetBlockReportParams): Promise<T[]> => {
//     console.log('reportType', reportType)
//     return cropsy.get(
//       `/stagedreports/map/?reportLevels=${reportLevels.join(',')}&BlockIds=${blockIds.join(',')}&dateStart=${encodeURIComponent(startDate)}&dateEnd=${encodeURIComponent(endDate)}`
//     )
//   },
// }

export const EvidenceApi = {
  getEvidencesByBlockId: (blockId: number, dateStart: string, dateEnd: string): Promise<EvidenceDto[]> =>
    imgman.get(`/evidence/block/${blockId}?dateStart=${encodeURIComponent(dateStart)}&dateEnd=${encodeURIComponent(dateEnd)}`),

  getEvidenceImageStream: (imageId: string): Promise<Blob> => imgman.get(`/evidence/${imageId}`, { responseType: 'blob' }),

  getEvidencesMultipleBlocks: (blockIds: number[], dateStart: string, dateEnd: string): Promise<EvidenceDto[]> =>
    imgman.get(
      `/evidence/blocks?blockIds=${blockIds.join(',')}&dateStart=${encodeURIComponent(dateStart)}&dateEnd=${encodeURIComponent(dateEnd)}`
    ),
}

export const BillingApi = {
  getCurrentBill: (): Promise<BillDTO> => cropsy.get('/bill'),

  getBills: (): Promise<BillDTO[]> => cropsy.get('/bills'),

  getBracket: (): Promise<BillingBracketDTO> => cropsy.get('/billingbracket'),
}
