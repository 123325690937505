import Graphic from '@arcgis/core/Graphic'
import Point from '@arcgis/core/geometry/Point'
import Polyline from '@arcgis/core/geometry/Polyline'
import useReportsClosestToTargetDate from '@hooks/useReportsClosestToTargetDate'
import { geojsonToArcGIS } from '@terraformer/arcgis'
import { BlockReportsByBlock } from '@typings/component'
import { PostStateIdEnum, VineStateIdEnum } from '@typings/dtos/block-report/enums'
import { MapPostDTO, MapVineDTO } from '@typings/dtos/block-report/map-data'
import { useCallback, useEffect } from 'react'
import { updateRowAndVineFeatureLayers } from '../utils'

export type UseAddLandVinePostRowGraphicsProps = {
  targetDate: number
  reports: BlockReportsByBlock[] | undefined
  showGraphics: string[]
}

export function useAddLandVinePostRowGraphics({ targetDate, reports, showGraphics }: UseAddLandVinePostRowGraphicsProps) {
  const reportsClosestToTargetDate = useReportsClosestToTargetDate(reports, targetDate)

  const addGraphics = useCallback(async () => {
    if (!reportsClosestToTargetDate) return

    const vineAndPostGraphics: Graphic[] = []
    const rowGraphics: Graphic[] = []

    reportsClosestToTargetDate.forEach((report) => {
      if (!report) return

      const getVineType = (vine: MapVineDTO) => {
        if (vine.state === VineStateIdEnum.Dead || vine.state === VineStateIdEnum.Young || vine.state === VineStateIdEnum.Missing)
          return `vine_${vine.state}`
        else if (vine.cordonCount || vine.caneCount === 1) return `cordon_${vine.cordonCount}`
        return ''
      }

      const getPostType = (post: MapPostDTO) => {
        if (post.state === PostStateIdEnum.Unknown) return ''
        else if (post.state === PostStateIdEnum.Missing) return 'post_missing'
        else return `post_${post.material}`
      }

      const hasShowGraphics = showGraphics.length > 0

      if (!report.mapData || typeof report.mapData === 'string') return

      const rowsAsMap = new Map(report.rowData.map((row) => [row.rowNum, row]))

      report.mapData.forEach((data) => {
        if (hasShowGraphics) {
          for (const vine of data.vines) {
            if (vine.badQuality) continue

            const vineType = getVineType(vine)
            if (!showGraphics.includes(vineType)) continue

            const details = {
              id: vine.id,
              blockName: report.blockName,
              rowNum: data.rowNum,
              coordinates: vine.c,
              scanDate: rowsAsMap.get(data.rowNum)?.scanDate,
              markerType: vineType,
            }

            const graphic = new Graphic({
              geometry: new Point({ x: vine.c[0], y: vine.c[1], spatialReference: { wkid: 4326 } }),
              attributes: { id: vine.id, markerType: vineType, details: JSON.stringify(details) },
            })

            vineAndPostGraphics.push(graphic)
          }

          for (const post of data.posts) {
            const postType = getPostType(post)
            if (!showGraphics.includes(postType)) continue

            const details = {
              id: post.id,
              blockName: report.blockName,
              rowNum: data.rowNum,
              coordinates: post.c,
              scanDate: report.dateEnd,
              markerType: postType,
            }

            const graphic = new Graphic({
              geometry: new Point({ x: post.c[0], y: post.c[1], spatialReference: { wkid: 4326 } }),
              attributes: { id: post.id, markerType: postType, details: JSON.stringify(details) },
            })

            vineAndPostGraphics.push(graphic)
          }
        }

        rowGraphics.push(
          new Graphic({
            attributes: { id: data.rowId },
            geometry: new Polyline(geojsonToArcGIS(data.rowGeometry)),
          })
        )
      })
    })

    try {
      updateRowAndVineFeatureLayers(vineAndPostGraphics, rowGraphics)
    } catch (error) {
      console.error('Error adding graphics:', error)
    }
  }, [reportsClosestToTargetDate, showGraphics])

  useEffect(() => {
    addGraphics()
  }, [addGraphics])
}
