import Box, { BoxProps } from '@mui/material/Box'
import { t } from 'i18next'

type SingleToggleBtnProps = {
  label: string
  isSelected: boolean
  onClick: () => void
  inverted?: boolean
  width?: number
}

const SingleToggleBtn = ({ label, isSelected, onClick, inverted, width }: SingleToggleBtnProps) => {
  const styles: BoxProps['sx'] = inverted
    ? isSelected
      ? { color: 'info.dark', borderColor: 'info.dark', bgcolor: 'white' }
      : { color: 'white', borderColor: 'white', bgcolor: 'info.dark' }
    : isSelected
      ? { color: 'white', borderColor: 'info.dark', bgcolor: 'info.dark' }
      : { color: 'info.dark', borderColor: 'info.dark', bgcolor: 'white' }

  return (
    <Box
      role="button"
      sx={[
        {
          fontSize: 14,
          fontWeight: 500,
          cursor: 'pointer',
          borderRadius: '3px',
          border: '1px solid',
          userSelect: 'none',
          transition: 'all 0.3s',
          textAlign: 'center',
        },
        styles,
        { width: width || 'fit-content' },
      ]}
      onClick={onClick}
    >
      {t(label)}
    </Box>
  )
}

export default SingleToggleBtn
